import { Box } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth/useAuth';
import UseLogger from '../../hooks/useLogger/useLogger';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { LogoutResponse } from '../../models/AuthModels';
import MenuItemWrapper from '../Dropdown/DropdownStyle';
import ClickableIcon from '../Icons/ClickableIcon';
import UserIcon from '../Icons/UserIcon';
import MenuWrapper from '../Menu/MenuStyle';

function NavbarUserMenu() {
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { userData } = useAuth();
  const snackBar = useSnackBar();
  const { logout } = useAuth();
  /**
   * Handle menu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Performs a logout request
   */
  const requestLogout = () => {
    logout().then(
      (res) => {
        UseLogger().log(`Logging out to: ${(res as AxiosResponse<LogoutResponse>).data.url}`);
        window.location.assign((res as AxiosResponse<LogoutResponse>).data.url);
      },
      () => {
        snackBar.showSnackBar(t('navbar.logoutError'), 'error');
      },
    );
    setAnchorEl(null);
  };

  return (
    <Box>
      {userData ? (
        <>
          <ClickableIcon
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <UserIcon />
          </ClickableIcon>
          <MenuWrapper
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
              '& .MuiPaper-root': {
                marginTop: '0.5rem',
                minWidth: '15rem !important',
              },
            }}
          >
            <MenuItemWrapper key="sub" disabled>
              {`${userData?.firstName} ${userData?.lastName}`}
            </MenuItemWrapper>
            <MenuItemWrapper
              key="changePassword"
              onClick={() => {
                window.open(import.meta.env.VITE_CHANGE_PASSWORD_URL);
              }}
            >
              {t('navbar.changePassword')}
            </MenuItemWrapper>
            <MenuItemWrapper key="logout" onClick={requestLogout}>
              {t('navbar.logout')}
            </MenuItemWrapper>
          </MenuWrapper>
        </>
      ) : null}
    </Box>
  );
}

export default NavbarUserMenu;
