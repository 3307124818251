import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';

/**
 * Retrieves the path for the translation JSON files
 * @param lng
 */
const constructPath = (lng: string) => `/locales/${lng}/translation.json`;

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: 'en',
    fallbackLng: ['en', 'ie', 'de'],
    interpolation: {
      skipOnVariables: false,
    },
    backend: {
      loadPath: constructPath,
    },
  });
