import { Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../components/SearchInput/SearchInput';

interface CompaniesTableFiltersProps {
  onSearch: (filter: CompanyFilter) => void;
  isLoading: boolean;
}

export interface CompanyFilter {
  filter: string;
}

function CompaniesTableFilters({ onSearch, isLoading = false }: CompaniesTableFiltersProps) {
  const [t] = useTranslation();
  const [, setCompanyFilter] = useState<CompanyFilter>({ filter: '' });
  /**
   * Search handler
   * @param filter
   */
  const search = (filter: string) => {
    onSearch({ filter });
    setCompanyFilter({ filter });
  };

  return (
    <Grid container columnSpacing="1.5rem" rowSpacing="1.5rem" sx={{ paddingTop: '1.5rem', paddingBottom: '1rem' }}>
      <Grid item xl={5} md={6} xs={12}>
        <SearchInput
          label={t('pages.companyOnboarding.table.filters.search')}
          onSearch={search}
          placeholder={t('pages.companyOnboarding.table.filters.searchPlaceholder')}
          isButtonDisabled={isLoading}
        />
      </Grid>
    </Grid>
  );
}

export default CompaniesTableFilters;
