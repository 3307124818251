import {
  Box,
  Table as T,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from '../../models/CommonModels';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TableEmptyState from './TableEmptyState';
import { TableProps } from './TableModels';

function Table({ headers, rows, onSort, defaultOrderBy, isLoading, emptyStateMessage }: TableProps) {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [t] = useTranslation();
  const theme = useTheme();

  return (
    <TableContainer>
      <T>
        <TableHead>
          <TableRow>
            {headers.map((headerCell) => (
              <TableCell
                key={headerCell.id}
                sortDirection={orderBy === headerCell.id ? order : false}
                sx={{
                  padding: '0.5rem',
                  borderBottom: `1px solid ${theme.palette.monochrome.monoChrome10}`,
                  ...headerCell.sx,
                }}
              >
                {headerCell.isSortable ? (
                  <TableSortLabel
                    IconComponent={ChevronDownIcon}
                    active={orderBy === headerCell.id}
                    direction={orderBy === headerCell.id ? order : 'asc'}
                    onClick={() => {
                      if (onSort) {
                        const newOrder = order === 'asc' ? 'desc' : 'asc';
                        setOrder(newOrder);
                        setOrderBy(headerCell.id);
                        onSort({ orderBy: headerCell.id, order: newOrder });
                      }
                    }}
                    sx={{
                      fontSize: '1.25rem',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '1.75rem',
                      '& svg': {
                        marginLeft: '0.5rem',
                      },
                    }}
                  >
                    {headerCell.label}
                  </TableSortLabel>
                ) : (
                  <Typography
                    sx={{
                      fontSize: '1.25rem',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '1.75rem',
                      '& svg': {
                        marginLeft: '0.5rem',
                      },
                    }}
                  >
                    {headerCell.label}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!isLoading ? (
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} {...row}>
                {row.cells.map((cell, idx) => (
                  <TableCell
                    key={`${row.id}_${headers[idx].id}`}
                    sx={{
                      fontSize: '1.125rem',
                      fontStyle: 'normal',
                      lineHeight: '1.625rem',
                      height: '2.75rem',
                      padding: '0.5rem',
                      borderBottom: `1px solid ${theme.palette.monochrome.monoChrome10}`,
                    }}
                    {...cell}
                  >
                    {cell.value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        ) : null}
        {!rows.length && !isLoading ? <TableEmptyState message={emptyStateMessage} /> : null}
      </T>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '3rem', paddingBottom: '3rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LoadingSpinner style={{ minHeight: '148px', maxWidth: '148px' }} />
            <Typography sx={{ fontSize: '1.75rem', lineHeight: '2rem', fontFamily: 'Vodafone Lt' }}>
              {t('common.table.loadingResults')}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </TableContainer>
  );
}
export default Table;
