import * as React from 'react';
import { SVGProps, memo } from 'react';

function UsersIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none" {...props}>
      <path
        fill="#428600"
        fillRule="evenodd"
        d="M11.75 16.025A6.25 6.25 0 0 1 18 9.775h6.25v6.25a6.25 6.25 0 1 1-12.5 0Zm14 0v-7.75H18a7.75 7.75 0 0 0-3.549 14.641c-4.153 1.573-7.202 5.938-8.19 11.963a.75.75 0 0 0 1.48.242C8.926 27.887 13.095 23.775 18 23.775c4.903 0 9.07 4.118 10.26 11.357a.75.75 0 0 0 1.48-.244c-.47-2.851-1.399-5.332-2.688-7.321 1.373-2.471 3.513-3.813 5.935-3.817h.022c3.447.005 6.4 2.762 7.25 7.695a.75.75 0 0 0 1.48-.255c-.701-4.062-2.798-7.08-5.683-8.32A5.752 5.752 0 0 0 38.75 18v-5.75H33a5.75 5.75 0 0 0-3.056 10.622c-1.535.66-2.854 1.823-3.853 3.382-1.277-1.542-2.814-2.682-4.543-3.337a7.75 7.75 0 0 0 4.202-6.892Zm3 1.975A4.25 4.25 0 0 1 33 13.75h4.25V18a4.25 4.25 0 0 1-4.24 4.25h-.02A4.25 4.25 0 0 1 28.75 18Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default memo(UsersIcon);
