import { Box, Divider, Grid, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown/Dropdown';
import { FilterTagsList } from '../../components/FilterTag/FilterTagStyle';
import ClickableIcon from '../../components/Icons/ClickableIcon';
import DownloadIcon from '../../components/Icons/DownloadIcon';
import FilterIcon from '../../components/Icons/FilterIcon';
import ResetIcon from '../../components/Icons/ResetIcon';
import ClickableLinkWithIcon from '../../components/Text/ClickableLinkWithIcon';
import { SimStatus } from '../../models/UserDevicesModels';
import { resetFilter, setFilter } from '../../stores/DevicesReportStore';
import { RootState } from '../../stores/Store';
import DevicesReportFiltersDrawer from './components/DevicesReportFiltersDrawer';
import useDevicesReportFilters from './useDevicesReportFilters';

interface DevicesReportFilterProps {
  isLoading: boolean;
  exportCsv: () => void;
  totalItems: number;
}
function DevicesReport({ isLoading, exportCsv, totalItems }: DevicesReportFilterProps) {
  const [t] = useTranslation();
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const filter = useSelector((state: RootState) => state.devicesReportReducer.filter);
  const { devicesStatusOptions, renderFilterTags } = useDevicesReportFilters();

  /**
   * Resets the filters
   */
  const resetFilters = () => {
    dispatch(resetFilter());
  };

  return (
    <>
      <Grid container rowGap="1.5rem" sx={{ paddingTop: '1.5rem' }}>
        <Grid item xl={5} md={6} xs={12}>
          <Dropdown
            id="type"
            name="type"
            label={t('pages.devicesReport.status')}
            value={filter.status}
            onChange={(e) => {
              dispatch(setFilter({ ...filter, status: e.target.value as SimStatus | '' }));
            }}
            items={devicesStatusOptions}
            placeholder={t('common.placeholders.dropdownPlaceholder') + t('pages.devicesReport.status').toLowerCase()}
          />
        </Grid>
        <Grid item xl={7} md={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button
            sx={{ marginLeft: { md: 'auto' } }}
            onClick={exportCsv}
            disabled={isLoading || totalItems === 0}
            label={
              <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <DownloadIcon fill="white" />
                {t('pages.devicesReport.exportCsv')}
              </Box>
            }
          />
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderColor: theme.palette.monochrome.monoChrome3,
          marginTop: '2.5rem',
          marginBottom: '2.5rem',
        }}
      />
      {/* Filters controls */}
      <Grid container rowGap="1.5rem" sx={{ paddingBottom: '1.5rem' }}>
        <Grid item sm={12} sx={{ display: 'flex', justifyContent: { md: 'flex-end' } }}>
          <ClickableLinkWithIcon onClick={resetFilters} leftIcon={<ResetIcon stroke={theme.palette.state.linkHover} />}>
            {t('common.reset')}
          </ClickableLinkWithIcon>
          <ClickableIcon onClick={() => setShowFiltersDrawer(true)} sx={{ paddingLeft: '1rem' }}>
            <FilterIcon />
          </ClickableIcon>
          {showFiltersDrawer ? (
            <DevicesReportFiltersDrawer open={showFiltersDrawer} onClose={() => setShowFiltersDrawer(false)} />
          ) : null}
        </Grid>
      </Grid>
      {/* Filter tags */}
      <FilterTagsList sx={{ paddingBottom: '1rem' }}>{renderFilterTags().map((n) => n)}</FilterTagsList>
    </>
  );
}

export default DevicesReport;
