import * as React from 'react';
import { SVGProps, memo } from 'react';

function CalendarIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
      <g stroke="#333" strokeLinecap="round">
        <path d="M8 3.03v3M16 3.03v3" />
        <path d="M20.5 20.03a2 2 0 0 1-2 2h-13a2 2 0 0 1-2-2v-13a2 2 0 0 1 2-2h15v15Z" clipRule="evenodd" />
        <path d="M20.5 8.065h-17" />
        <g strokeLinejoin="round">
          <path d="M8.809 14.436a2.75 2.75 0 0 1 .514-.047c1.3 0 2.356.904 2.356 2.018 0 1.116-1.054 2.154-2.357 2.154a2.201 2.201 0 0 1-1.947-1.076M7.371 11.83h4.038L8.8 14.437M14.101 12.908l2.154-1.347v7" />
        </g>
      </g>
    </svg>
  );
}
export default memo(CalendarIcon);
