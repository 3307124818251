import { SimplePaletteColorOptions, ThemeOptions } from '@mui/material';

/**
 * If we need to extend the Material theme options
 * declare module '@mui/material/styles/createPalette' {
 *   interface Palette {
 *     red: { main: string; light?: string; hover?: string };
 *   }
 *
 *   interface PaletteOptions {
 *     red?: { hover: string };
 *   }
 * }
 */

declare module '@mui/material/styles' {
  interface ButtonStates extends SimplePaletteColorOptions {
    hover: string;
    pressed: string;
    contrastText?: string;
    disabledText?: string;
  }
  interface Palette {
    primaryButton: ButtonStates;
    secondaryButton: ButtonStates;
    alternative1Button: ButtonStates;
    alternative2Button: ButtonStates;
    primaryRadio: Palette['primary'];
    primaryCheckbox: Palette['primary'];
    disabled: Palette['primary'];
    state: {
      focus: string;
      linkHover: string;
      error: string;
    };
    colors: {
      green: string;
      turquoise: string;
      violet: string;
    };
    monochrome: {
      monoChrome1: string;
      monoChrome2: string;
      monoChrome3: string;
      monoChrome4: string;
      monoChrome5: string;
      monoChrome6: string;
      monoChrome7: string;
      monoChrome9: string;
      monoChrome10: string;
      monoChrome11: string;
      monoChrome12: string;
      monoChrome13: string;
      monoChrome14: string;
    };
  }

  interface PaletteOptions {
    primaryButton: ButtonStates;
    secondaryButton: ButtonStates;
    alternative1Button: ButtonStates;
    alternative2Button: ButtonStates;
    primaryRadio: PaletteOptions['primary'];
    primaryCheckbox: PaletteOptions['primary'];
    disabled: PaletteOptions['primary'];
    state: {
      focus: string;
      linkHover: string;
      error: string;
    };
    colors: {
      green: string;
      turquoise: string;
      violet: string;
    };
    monochrome: {
      monoChrome1: string;
      monoChrome2: string;
      monoChrome3: string;
      monoChrome4: string;
      monoChrome5: string;
      monoChrome6: string;
      monoChrome7: string;
      monoChrome9: string;
      monoChrome10: string;
      monoChrome11: string;
      monoChrome12: string;
      monoChrome13: string;
      monoChrome14: string;
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryButton: true;
    secondaryButton: true;
    alternative1Button: true;
    alternative2Button: true;
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    primaryRadio: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    primaryCheckbox: true;
  }
}

const MaterialTheme: ThemeOptions = {
  typography: {
    fontFamily: 'Vodafone Rg',
    allVariants: {
      color: '#333',
      fontSize: '1.125rem',
      lineHeight: '1.625rem',
      fontStyle: 'normal',
    },
  },
  palette: {
    state: {
      focus: '#0096AD',
      linkHover: '#BD0000',
      error: '#BD0000',
    },
    primaryRadio: {
      main: '#00697C',
    },
    primaryCheckbox: {
      main: '#00697C',
    },
    colors: {
      green: '#428600',
      turquoise: '#007C92',
      violet: '#892988',
    },
    monochrome: {
      monoChrome1: '#FFFFFF',
      monoChrome2: '#F2F2F2',
      monoChrome3: '#BEBEBE',
      monoChrome4: '#7E7E7E',
      monoChrome5: '#F4F4F4',
      monoChrome6: '#0D0D0D',
      monoChrome7: '#25282B',
      monoChrome9: '#222222',
      monoChrome10: '#4A4D4E',
      monoChrome11: '#D9D9D9',
      monoChrome12: '#282828',
      monoChrome13: '#CCCCCC',
      monoChrome14: '#AFAFAF',
    },
    disabled: {
      main: '#EBEBEB',
    },
    primary: {
      main: '#E60000',
      dark: '#C30000',
      light: '#ec3c3c',
    },
    secondary: {
      main: '#0D0D0D',
      dark: '#565656',
      contrastText: '#fff',
    },
    primaryButton: {
      main: '#E60000',
      hover: '#C30000',
      pressed: '#A10000',
      contrastText: '#FFFFFF',
    },
    secondaryButton: {
      main: '#0D0D0D',
      hover: '#313131',
      pressed: '#565656',
      contrastText: '#FFFFFF',
    },
    alternative1Button: {
      main: '#0D0D0D',
      hover: '#313131',
      pressed: '#565656',
      contrastText: '#FFFFFF',
    },
    alternative2Button: {
      main: '#E60000',
      hover: '#C30000',
      pressed: '#A10000',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#ffffff',
      paper: '#f9f9f9',
    },
    text: {
      primary: '#333',
      secondary: '#666',
      disabled: '#999',
    },
    error: {
      main: '#e53935',
    },
    success: {
      main: 'rgb(0, 138, 0)',
    },
    warning: {
      main: '#fff176',
    },
    info: {
      main: '#005EA5',
    },
    divider: '#666666',
  },
};

const Theme = { ...MaterialTheme };

export default Theme;
