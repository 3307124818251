import { Box, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MenuItemWrapper from '../../../../components/Dropdown/DropdownStyle';
import VerticalDotsIcon from '../../../../components/Icons/VerticalDotsIcon';
import MenuWrapper from '../../../../components/Menu/MenuStyle';
import UserDetailsContext from '../../../../contexts/UserDetailsContext/UserDetailsContext';
import useRolePermissions from '../../../../hooks/useRolePermissions/useRolePermissions';
import { FeaturePermissions } from '../../../../models/AuthModels';
import { Device, GetDeviceByIdFn, GetDeviceFn, Sim } from '../../../../models/UserDevicesModels';
import {
  setDevice,
  updateRequest,
  updateSelectedDropdownUser,
  updateTypeOfConnection,
} from '../../../../stores/NewConnectionStore';
import { setDeviceToUpgrade } from '../../../../stores/UpgradeDeviceStore';
import NewConnectionDrawer from '../../components/NewConnectionDrawer/NewConnectionDrawer';
import UpgradeDeviceDrawer from './UpgradeDeviceDrawer/UpgradeDeviceDrawer';

interface UserDetailsDeviceRowOptionsProps {
  sim: Sim;
  resendNotification: (deviceId: string) => void;
  isPerformingAction: boolean;
  getDeviceById: GetDeviceFn | GetDeviceByIdFn;
  onNewConnectionSuccess: () => void;
  onUpgradeSuccess: () => void;
}
function UserDetailsDeviceRowOptions({
  sim,
  resendNotification,
  isPerformingAction,
  getDeviceById,
  onNewConnectionSuccess,
  onUpgradeSuccess,
}: UserDetailsDeviceRowOptionsProps) {
  const [t] = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showUpgradeDevice, setShowUpgradeDevice] = useState(false);
  const [showNewConnection, setShowNewConnection] = useState(false);
  const dispatch = useDispatch();
  const { isUserAllowed } = useRolePermissions();
  const user = useContext(UserDetailsContext);

  /**
   * Handle button click
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    dispatch(setDeviceToUpgrade({ device: getDeviceById(sim.deviceId) as Device, sim }));
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handle menu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Initiates the new connection journey, beginning on the notifications step
   */
  const initNewConnectionJourney = () => {
    if (user) {
      handleClose();
      dispatch(
        updateSelectedDropdownUser({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
          key: user.id,
          secondaryLabel: user.email,
          data: user,
        }),
      );
      dispatch(updateTypeOfConnection('ESIM'));
      dispatch(updateRequest({ userId: user?.id as string, eid: sim.simId }));
      dispatch(setDevice(getDeviceById(sim.deviceId) as Device));
      setShowNewConnection(true);
    }
  };

  /**
   * Render upgrade option for current device
   */
  const renderUpgradeOption = () => {
    if (isUserAllowed(FeaturePermissions.userDevicesActions)) {
      return (
        <MenuItemWrapper
          disabled={sim.status !== 'CONNECTED'}
          onClick={() => {
            handleClose();
            setShowUpgradeDevice(true);
          }}
        >
          {t('pages.userDetails.tabs.devicesTable.options.upgradeDevice')}
        </MenuItemWrapper>
      );
    }
    return null;
  };

  /**
   * Render new connection option
   */
  const renderNewConnectionOption = () => {
    if (user && isUserAllowed(FeaturePermissions.userDevicesActions)) {
      return (
        <MenuItemWrapper disabled={sim.status !== 'FREE' || sim.type === 'PSIM'} onClick={initNewConnectionJourney}>
          {t('pages.userDetails.tabs.devicesTable.options.newConnection')}
        </MenuItemWrapper>
      );
    }
    return null;
  };

  /**
   * Render resend option for current device
   */
  const renderResendOption = () => {
    if (isUserAllowed(FeaturePermissions.userDevicesActions)) {
      return (
        <MenuItemWrapper
          disabled={sim.status !== 'ERROR' || isPerformingAction}
          onClick={() => {
            handleClose();
            resendNotification(sim.id);
          }}
        >
          {t('pages.userDetails.tabs.devicesTable.options.resend')}
        </MenuItemWrapper>
      );
    }
    return null;
  };

  return (
    <>
      {isUserAllowed(FeaturePermissions.userDevicesActions) ? (
        <Box
          id={`box-${sim.id}`}
          sx={{
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0.375rem',
            width: '2.25rem',
            height: '2.25rem',
            flexShrink: 0,
            ':hover': { backgroundColor: theme.palette.monochrome.monoChrome11, cursor: 'pointer' },
          }}
          onClick={handleClick}
        >
          <VerticalDotsIcon />
        </Box>
      ) : null}
      <Box>
        <MenuWrapper
          id={`menu-${sim.id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {renderNewConnectionOption()}
          {renderUpgradeOption()}
          {renderResendOption()}
          {/* <MenuItemWrapper onClick={handleClose}>
            <Typography color={theme.palette.text.disabled}>
              {t('pages.userDetails.tabs.devicesTable.options.cancelEsim')}
            </Typography>
          </MenuItemWrapper> */}
        </MenuWrapper>
      </Box>
      {showUpgradeDevice ? (
        <UpgradeDeviceDrawer
          open={showUpgradeDevice}
          onClose={() => setShowUpgradeDevice(false)}
          onUpgradeSuccess={onUpgradeSuccess}
        />
      ) : null}
      {showNewConnection ? (
        <NewConnectionDrawer
          onClose={() => {
            setShowNewConnection(false);
          }}
          open={showNewConnection}
          onNewConnectionSuccess={onNewConnectionSuccess}
          initialStep={7}
        />
      ) : null}
    </>
  );
}

export default UserDetailsDeviceRowOptions;
