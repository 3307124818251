import { Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageTitle from '../../../components/Text/PageTitle';
import UserDetailsContext from '../../../contexts/UserDetailsContext/UserDetailsContext';
import UserDetailsInfoRows from './components/UserDetailsInfoRows';
import UserDetailsTabs from './components/UserDetailsTabs';
import useUserDetails from './useUserDetails';

function UserDetails() {
  const { id } = useParams();
  const { user } = useUserDetails(id);
  return (
    <UserDetailsContext.Provider value={user}>
      <PageTitle>{user ? `${user?.firstName} ${user?.lastName}` : <Skeleton width="25%" />}</PageTitle>
      <UserDetailsInfoRows />
      <UserDetailsTabs />
    </UserDetailsContext.Provider>
  );
}
export default UserDetails;
