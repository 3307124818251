import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import PlusIcon from '../../components/Icons/PlusIcon';
import PageTitle from '../../components/Text/PageTitle';
import useRolePermissions from '../../hooks/useRolePermissions/useRolePermissions';
import { FeaturePermissions } from '../../models/AuthModels';
import ESimManagerTable from './components/ESimManagerTable';
import NewConnectionDrawer from './components/NewConnectionDrawer/NewConnectionDrawer';

function ESimManager() {
  const [t] = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { isUserAllowed } = useRolePermissions();
  const [key, setKey] = useState(0);
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PageTitle>{t('pages.eSimManager.title')}</PageTitle>
        {isUserAllowed(FeaturePermissions.newConnection) ? (
          <>
            <Button
              sx={{ marginLeft: 'auto' }}
              onClick={() => {
                setOpenDrawer(true);
              }}
              label={
                <>
                  <Box sx={{ marginRight: '0.5rem' }}>
                    <PlusIcon />
                  </Box>
                  {t('pages.eSimManager.newConnectionDrawer.title')}
                </>
              }
            />
            <NewConnectionDrawer
              onClose={() => {
                setOpenDrawer(false);
              }}
              onNewConnectionSuccess={() => setKey((prevState) => prevState + 1)}
              open={openDrawer}
            />
          </>
        ) : null}
      </Box>
      <ESimManagerTable key={key} />
    </>
  );
}
export default ESimManager;
