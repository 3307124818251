import { Box, Divider, Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import TextInput from '../../../../components/TextInput/TextInput';
import { EmailRegex, NumberLettersSpaceRegex } from '../../../../configs/RegexConfig';
import useSnackBar from '../../../../hooks/useSnackBar/useSnackBar';
import useCompanyOnboarding from '../useCompanyOnboarding';
import CompanyOnboardingBansForm from './CompanyOnboardingBansForm';
import CompanyOnboardingBansTable from './CompanyOnboardingBansTable';

interface CompanyOnboardingFormProps {
  editMode: boolean;
}
function CompanyOnboardingForm({ editMode }: CompanyOnboardingFormProps) {
  const [t] = useTranslation();
  const { id } = useParams();
  const { countryCodesOptions, marketTypesOptions, createCompany, updateCompany, isLoading, companyData } =
    useCompanyOnboarding();
  const navigate = useNavigate();
  const theme = useTheme();
  const snackBar = useSnackBar();

  const formik = useFormik({
    initialValues: {
      companyName: companyData.name,
      companyCode: companyData.companyCode,
      marketType: companyData.marketType,
      countryCode: companyData.country,
      distributionListEmail: companyData.distributionListEmail ?? '',
      distributionListEnabled: companyData.distributionListEnabled,
      endUserNotificationEnabled: companyData.endUserNotificationEnabled,
      bans: companyData.bans,
      planOptions: companyData.planOptions,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      companyName: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(NumberLettersSpaceRegex, t('common.inputValidations.onlyNumbersAndLetters')),
      companyCode: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(NumberLettersSpaceRegex, t('common.inputValidations.onlyNumbersAndLetters')),
      marketType: yup.string().required(t('common.inputValidations.requiredField')),
      distributionListEmail: yup
        .string()
        .matches(EmailRegex, t('common.inputValidations.email'))
        .when('distributionListEnabled', ([distributionListEnabled], schema) => {
          if (distributionListEnabled)
            return yup
              .string()
              .matches(EmailRegex, t('common.inputValidations.email'))
              .required(t('common.inputValidations.requiredField'));
          return schema;
        }),
      countryCode: yup.string().when('marketType', ([marketType], schema) => {
        if (marketType !== 'GROUP') return yup.string().required(t('common.inputValidations.requiredField'));
        return schema;
      }),
      bans: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              name: yup.string().required(t('common.inputValidations.requiredField')),
              ban: yup.string().required(t('common.inputValidations.requiredField')),
              ben: yup.string().required(t('common.inputValidations.requiredField')),
            })
            .required(t('common.inputValidations.requiredField')),
        )
        .min(1, t('common.inputValidations.requiredField')),
    }),
    onSubmit: (values) => {
      if (id && editMode) {
        updateCompany({
          name: values.companyName,
          code: companyData.companyCode as string,
          countryCode: companyData.country as string,
          marketCode: companyData.marketType as string,
          bans: values.bans,
          companyId: id,
          planOptions: formik.values.planOptions,
          distributionListEmail: formik.values.distributionListEmail,
          distributionListEnabled: formik.values.distributionListEnabled,
          endUserNotificationEnabled: formik.values.endUserNotificationEnabled,
        });
      } else {
        createCompany({
          name: values.companyName,
          code: values.companyCode,
          marketCode: values.marketType,
          countryCode: formik.values.marketType === 'GROUP' ? 'GB' : values.countryCode,
          bans: formik.values.bans,
          planOptions: formik.values.planOptions,
          distributionListEmail: formik.values.distributionListEmail,
          distributionListEnabled: formik.values.distributionListEnabled,
          endUserNotificationEnabled: formik.values.endUserNotificationEnabled,
        });
      }
    },
  });

  /**
   * Submits the company form
   */
  const submitForm = () => {
    if (formik.isValid) {
      formik.submitForm();
    } else if (!formik.isValid && formik.errors.bans) {
      snackBar.showSnackBar(t('translation:pages.companyOnboarding.banIsRequired'), 'error');
    }
  };

  return (
    <Box sx={{ paddingTop: '1.5rem', paddingBottom: '2rem' }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container columnGap="8.75rem">
          <Grid item xl={4} md={6} xs={12}>
            <TextInput
              autoComplete="off"
              key="companyName"
              id="companyName"
              name="companyName"
              type="text"
              label={t(`pages.companyOnboarding.companyName`)}
              value={formik.values.companyName}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.companyName && Boolean(formik.errors.companyName)}
              feedback={
                formik.touched.companyName && Boolean(formik.errors.companyName) ? formik.errors.companyName : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.companyOnboarding.companyName`)}
              disabled={!editMode}
            />
            <TextInput
              autoComplete="off"
              key="companyCode"
              id="companyCode"
              name="companyCode"
              type="text"
              label={t(`pages.companyOnboarding.companyCode`)}
              value={formik.values.companyCode}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.companyCode && Boolean(formik.errors.companyCode)}
              feedback={
                formik.touched.companyCode && Boolean(formik.errors.companyCode) ? formik.errors.companyCode : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.companyOnboarding.companyCode`)}
              disabled={!!id}
            />
            <Dropdown
              id="marketType"
              name="marketType"
              label={t('pages.companyOnboarding.marketType')}
              value={formik.values.marketType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.marketType && Boolean(formik.errors.marketType)}
              feedback={formik.touched.marketType && Boolean(formik.errors.marketType) ? formik.errors.marketType : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={marketTypesOptions}
              placeholder={t('common.placeholders.dropdownPlaceholder') + t('pages.companyOnboarding.marketType')}
              disabled={!!id}
            />
            {formik.values.marketType === 'COUNTRY' && (
              <Dropdown
                id="countryCode"
                name="countryCode"
                label={t('pages.companyOnboarding.country')}
                value={formik.values.countryCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                feedback={
                  formik.touched.countryCode && Boolean(formik.errors.countryCode) ? formik.errors.countryCode : ''
                }
                formStyle={{ paddingBottom: '1.5rem' }}
                items={countryCodesOptions}
                placeholder={t('common.placeholders.dropdownPlaceholder') + t('pages.companyOnboarding.country')}
                disabled={!!id}
              />
            )}

            <Divider
              sx={{ borderColor: theme.palette.monochrome.monoChrome3, marginTop: '1rem', marginBottom: '2.125rem' }}
            />

            <Box sx={{ paddingBottom: '1rem' }}>
              <Checkbox
                disabled={!editMode}
                checked={formik.values.endUserNotificationEnabled}
                onChange={(e) => {
                  formik.setFieldValue('endUserNotificationEnabled', e.target.checked);
                }}
                label={t('pages.companyOnboarding.endUserNotificationEnabled')}
              />
            </Box>

            <Box sx={{ paddingBottom: '1rem' }}>
              <Checkbox
                disabled={!editMode}
                checked={formik.values.distributionListEnabled}
                onChange={(e) => {
                  formik.setFieldValue('distributionListEnabled', e.target.checked);
                }}
                label={t('pages.companyOnboarding.distributionEmailEnabled')}
              />
            </Box>

            <TextInput
              autoComplete="off"
              key="distributionListEmail"
              id="distributionListEmail"
              name="distributionListEmail"
              type="text"
              label={t(`pages.companyOnboarding.distributionListEmail`)}
              value={formik.values.distributionListEmail}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.distributionListEmail && Boolean(formik.errors.distributionListEmail)}
              feedback={
                formik.touched.distributionListEmail && Boolean(formik.errors.distributionListEmail)
                  ? formik.errors.distributionListEmail
                  : ''
              }
              formStyle={{ paddingBottom: '2rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.companyOnboarding.distributionListEmail`).toLowerCase()
              }
              disabled={!editMode}
            />
          </Grid>
          {companyData.planOptions ? (
            <Grid item xl={4} md={6} xs={12}>
              <Typography sx={{ paddingBottom: '1rem' }}>{t('pages.companyOnboarding.planOptions')}</Typography>
              {!formik.values.planOptions.length ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', marginBottom: '2rem' }}>
                  <Skeleton width="20rem" height="2rem" />
                  <Skeleton width="20rem" height="2rem" />
                  <Skeleton width="20rem" height="2rem" />
                  <Skeleton width="20rem" height="2rem" />
                  <Skeleton width="20rem" height="2rem" />
                  <Skeleton width="20rem" height="2rem" />
                  <Skeleton width="20rem" height="2rem" />
                </Box>
              ) : null}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                {formik.values.planOptions.map((planOption) => (
                  <Checkbox
                    disabled={!editMode}
                    key={planOption.name}
                    checked={planOption.active}
                    onChange={(e) => {
                      const updatedPlanOptions = formik.values.planOptions.map((po) => {
                        if (po.name === planOption.name) {
                          return { ...planOption, active: e.currentTarget.checked };
                        }
                        return po;
                      });
                      formik.setFieldValue('planOptions', [...updatedPlanOptions]);
                    }}
                    label={
                      <Typography>
                        {planOption.label as string}
                        <span style={{ marginLeft: '0.5rem', color: theme.palette.monochrome.monoChrome14 }}>
                          {planOption.name as string}
                        </span>
                      </Typography>
                    }
                  />
                ))}
              </Box>
            </Grid>
          ) : null}
        </Grid>
        <Divider
          sx={{ borderColor: theme.palette.monochrome.monoChrome3, marginTop: '0.5rem', marginBottom: '2rem' }}
        />
      </form>
      <CompanyOnboardingBansForm formikObj={formik} editMode={editMode} />
      <CompanyOnboardingBansTable formikObj={formik} editMode={editMode} />
      {editMode ? (
        <ButtonGroup sx={{ paddingTop: '0.5rem' }}>
          {!id ? (
            <Button
              label={t('pages.companyOnboarding.submit')}
              color="primaryButton"
              type="button"
              onClick={submitForm}
              disabled={isLoading || !formik.isValid}
            />
          ) : (
            <>
              <Button
                label={t('common.cancel')}
                color="alternative2Button"
                type="button"
                onClick={() => navigate('/companies')}
                disabled={isLoading}
              />
              <Button
                label={t('common.saveChanges')}
                color="primaryButton"
                onClick={submitForm}
                disabled={isLoading || !formik.isValid}
              />
            </>
          )}
        </ButtonGroup>
      ) : null}
    </Box>
  );
}

export default CompanyOnboardingForm;
