import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHttpError from '../../../../hooks/useHttpError/useHttpError';
import useSnackBar from '../../../../hooks/useSnackBar/useSnackBar';
import { CreateUserRequest, UserExistsRequest, UserExistsResponse } from '../../../../models/UsersModels';
import UsersService from '../../../../services/Users/UsersService';

function useNewConnectionCreateUserStep() {
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();
  const snackBar = useSnackBar();
  const { getHttpReason } = useHttpError();

  /**
   * Verifies if the user already exists
   */
  const userExists = (requestParams: UserExistsRequest, cb: (data: UserExistsResponse) => void) => {
    setIsLoading(true);
    UsersService.api.userExists(requestParams).then(
      (response) => {
        setIsLoading(false);
        cb(response.data);
      },
      (res) => {
        const error = getHttpReason(res.response.data, t('pages.eSimManager.newConnectionDrawer.userValidatingError'));
        snackBar.showSnackBar(error, 'error');
        setIsLoading(false);
      },
    );
  };

  /**
   * Creates a new user
   */
  const createUser = (requestParams: CreateUserRequest) =>
    new Promise((resolve, reject) => {
      setIsLoading(true);
      UsersService.api.createUser(requestParams).then(
        (response) => {
          setIsLoading(false);
          resolve(response);
        },
        (response) => {
          setIsLoading(false);
          reject(response);
        },
      );
    });

  return { isLoading, userExists, createUser };
}

export default useNewConnectionCreateUserStep;
