import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import CloseIcon from '../Icons/CloseIcon';
import { FilterTagContainer } from './FilterTagStyle';

interface FilterTagProps extends PropsWithChildren {
  onClick?: () => void;
  onRemove?: () => void;
}
function FilterTag({ onClick, onRemove, children }: FilterTagProps) {
  return (
    <FilterTagContainer onClick={onClick}>
      <Box>{children}</Box>
      <CloseIcon stroke="#fff" onClick={onRemove} style={{ cursor: 'pointer', transform: 'scale(0.75)' }} />
    </FilterTagContainer>
  );
}

export default FilterTag;
