import { Box, RadioGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../components/Button/Button';
import ButtonGroup from '../../../../../components/ButtonGroup/ButtonGroup';
import Radio from '../../../../../components/Radio/Radio';
import { resetEid } from '../../../../../stores/NewConnectionStore';

import { RootState } from '../../../../../stores/Store';
import { updateTypeOfConnection } from '../../../../../stores/UpgradeDeviceStore';

interface UpgradeDeviceSelectTypeStepProps {
  nextStep: () => void;
  onClose: () => void;
}
function UpgradeDeviceSelectTypeStep({ nextStep, onClose }: UpgradeDeviceSelectTypeStepProps) {
  const [t] = useTranslation();
  const typeOfConnection = useSelector((state: RootState) => state.upgradeDeviceReducer.typeOfConnection);
  const dispatch = useDispatch();

  /**
   * Handles change of the radio button
   * @param e
   */
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTypeOfConnection(e.target.value as 'ESIM' | 'DEVICE'));
    dispatch(resetEid());
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ paddingBottom: '2rem' }}>
        <RadioGroup
          aria-labelledby="notifications-radio"
          name="connection-radio"
          value={typeOfConnection}
          onChange={handleRadioChange}
        >
          <Radio value="DEVICE" label={t('pages.userDetails.upgradeDeviceDrawer.selectDeviceFromList')} />
          <Radio value="ESIM" label={t('pages.userDetails.upgradeDeviceDrawer.manuallyInput')} />
        </RadioGroup>
      </Box>
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          <Button label={t('common.continue')} color="primaryButton" disabled={!typeOfConnection} onClick={nextStep} />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default UpgradeDeviceSelectTypeStep;
