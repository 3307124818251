import { Box, Grid } from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import { t } from 'i18next';
import React from 'react';
import * as yup from 'yup';
import Button from '../../../../components/Button/Button';
import TextInput from '../../../../components/TextInput/TextInput';
import { NumberLettersRegex } from '../../../../configs/RegexConfig';
import { BillingAccount, PlanOption } from '../../../../models/CompanyOnboardingModels';

interface CompanyOnboardingBansFormProps {
  formikObj: FormikProps<{
    companyName: string;
    companyCode: string;
    marketType: string;
    countryCode: string;
    distributionListEmail: string;
    distributionListEnabled: boolean;
    endUserNotificationEnabled: boolean;
    bans: BillingAccount[];
    planOptions: PlanOption[];
  }>;
  editMode: boolean;
}
function CompanyOnboardingBansForm({ formikObj, editMode }: CompanyOnboardingBansFormProps) {
  /**
   * Given a new ban to be added to the list, verifies which fields are unique
   * @param ban
   */
  const isUniqueBillingAccount = (ban: BillingAccount): { isBanUnique: boolean; isNameUnique: boolean } => {
    const isNameUnique = !formikObj.values.bans.some((b: BillingAccount) => b.name === ban.name);
    const isBanUnique = !formikObj.values.bans.some((b: BillingAccount) => b.ban === ban.ban);
    return { isNameUnique, isBanUnique };
  };

  const banFormik = useFormik({
    initialValues: {
      name: '',
      ban: '',
      ben: '',
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(NumberLettersRegex, t('common.inputValidations.onlyNumbersAndLetters')),
      ban: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(NumberLettersRegex, t('common.inputValidations.onlyNumbersAndLetters')),
      ben: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(NumberLettersRegex, t('common.inputValidations.onlyNumbersAndLetters')),
    }),
    onSubmit: (values) => {
      const { isNameUnique, isBanUnique } = isUniqueBillingAccount({
        ban: values.ban,
        name: values.name,
        ben: values.ben,
      });
      if (isNameUnique && isBanUnique) {
        const { bans } = formikObj.values;
        bans.push(values);
        formikObj.setValues({ ...formikObj.values, bans }).then(() => {
          formikObj.validateForm();
        });
        banFormik.resetForm();
      }
      if (!isNameUnique) {
        banFormik.setFieldError('name', t(`pages.companyOnboarding.banNameAlreadyExists`));
      }
      if (!isBanUnique) {
        banFormik.setFieldError('ban', t(`pages.companyOnboarding.banAlreadyExists`));
      }
    },
  });
  return (
    <Box>
      {editMode ? (
        <Box component="form" onSubmit={banFormik.handleSubmit}>
          <Grid container spacing="1.5rem">
            <Grid item xl={4} md={4} xs={12}>
              <TextInput
                autoComplete="off"
                key="name"
                id="name"
                name="name"
                type="text"
                label={t(`pages.companyOnboarding.banName`)}
                value={banFormik.values.name}
                onInputChange={banFormik.handleChange}
                onBlur={banFormik.handleBlur}
                error={banFormik.touched.name && Boolean(banFormik.errors.name)}
                feedback={banFormik.touched.name && Boolean(banFormik.errors.name) ? banFormik.errors.name : ''}
                formStyle={{ paddingBottom: '1.5rem' }}
                placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.companyOnboarding.banName`)}
              />
            </Grid>
            <Grid item xl={4} md={4} xs={12}>
              <TextInput
                autoComplete="off"
                key="ban"
                id="ban"
                name="ban"
                type="text"
                label={t(`pages.companyOnboarding.ban`)}
                value={banFormik.values.ban}
                onInputChange={banFormik.handleChange}
                onBlur={banFormik.handleBlur}
                error={banFormik.touched.ban && Boolean(banFormik.errors.ban)}
                feedback={banFormik.touched.ban && Boolean(banFormik.errors.ban) ? banFormik.errors.ban : ''}
                formStyle={{ paddingBottom: '1.5rem' }}
                placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.companyOnboarding.ban`)}
              />
            </Grid>
            <Grid item xl={4} md={4} xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
              <TextInput
                autoComplete="off"
                key="ben"
                id="ben"
                name="ben"
                type="text"
                label={t(`pages.companyOnboarding.ben`)}
                value={banFormik.values.ben}
                onInputChange={banFormik.handleChange}
                onBlur={banFormik.handleBlur}
                error={banFormik.touched.ben && Boolean(banFormik.errors.ben)}
                feedback={banFormik.touched.ben && Boolean(banFormik.errors.ben) ? banFormik.errors.ben : ''}
                formStyle={{ paddingBottom: '1.5rem' }}
                placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.companyOnboarding.ben`)}
                sx={{ marginRight: '1.5rem' }}
              />
              <Button
                label={t('pages.companyOnboarding.add')}
                color="primaryButton"
                type="button"
                sx={{ marginTop: '2.25rem', minWidth: '75px' }}
                onClick={banFormik.submitForm}
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
}

export default CompanyOnboardingBansForm;
