import MenuIcon from '@mui/icons-material/Menu';
import {
  alpha,
  AppBar,
  AppBarProps,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, LinkProps, useLocation } from 'react-router-dom';
import useRolePermissions from '../../hooks/useRolePermissions/useRolePermissions';
import { FeaturePermissions } from '../../models/AuthModels';
import { Link } from '../../models/CommonModels';
import DrawerWrapper from '../Drawer/DrawerStyle';
import ClickableIcon from '../Icons/ClickableIcon';
import HelpIcon from '../Icons/HelpIcon';
import SearchIcon from '../Icons/SearchIcon';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import ClickableLink from '../Text/ClickableLink';
import NavbarUserMenu from './NavbarUserMenu';

const AppBarWrapper = styled(AppBar)<AppBarProps>(({ theme }) => ({
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.5)}`,
  boxShadow: 'none',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  height: '72px',
}));

const NavbarButton = styled(RouterLink)<LinkProps>(({ theme }) => ({
  display: 'flex',
  padding: '24px',
  height: '72px',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  fontSize: '1.5rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  borderRadius: 0,
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&.active': {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
}));

const VodafoneIcon = styled('div')`
  min-width: 48px;
  height: 48px;
  background-image: url('/images/vodafone_icon.svg');
  background-size: cover;
`;

interface NavbarProps {
  showNavItems?: boolean;
}
function Navbar({ showNavItems = true }: NavbarProps) {
  const [t] = useTranslation();
  const location = useLocation().pathname;
  const { isUserAllowed } = useRolePermissions();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  /**
   * Handles the navbar toggle when mobile screen is detected
   */
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navItems: Link[] = [
    {
      label: t('navbar.dashboard'),
      path: '/dashboard',
      key: 'dashboard',
      allowedRoles: FeaturePermissions.dashboard,
    },
    {
      label: t('navbar.esimManager'),
      path: '/esimManager',
      key: 'esimManager',
      allowedRoles: FeaturePermissions.eSimManager,
    },
    {
      label: t('navbar.companyOnboarding'),
      path: '/companies',
      key: 'companies',
      allowedRoles: FeaturePermissions.companiesList,
    },
    {
      label: t('navbar.uemOnboarding'),
      path: '/uemOnboarding',
      key: 'uemOnboarding',
      allowedRoles: FeaturePermissions.uemList,
    },
    {
      label: t('navbar.adminOnboarding'),
      path: '/admins',
      key: 'admins',
      allowedRoles: FeaturePermissions.adminsList,
    },
    {
      label: t('navbar.import'),
      path: '/import',
      key: 'import',
      allowedRoles: FeaturePermissions.import,
    },
  ];

  const renderDrawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Box sx={{ display: 'flex', padding: '0 1rem', alignItems: 'center', height: '72px' }}>
        <VodafoneIcon />
        <Typography sx={{ paddingLeft: '1rem', fontSize: '1.5rem' }}>eSIM Business Manager</Typography>
      </Box>
      <Divider />
      <List>
        {navItems.map((item) =>
          isUserAllowed(item.allowedRoles) && showNavItems ? (
            <ListItem key={item.key} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} onClick={handleDrawerToggle}>
                <ClickableLink
                  className={location.toLowerCase().includes(item.path.toLowerCase()) ? 'active' : 'teste'}
                  sx={{ fontSize: '1.25rem', textDecoration: 'none', textAlign: 'left', width: '100%' }}
                  to={item.path}
                >
                  {item.label}
                </ClickableLink>
              </ListItemButton>
            </ListItem>
          ) : null,
        )}
      </List>
    </Box>
  );

  return (
    <AppBarWrapper position="relative">
      <Toolbar sx={{ minHeight: '72px !important' }}>
        <IconButton
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: '2rem', display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <VodafoneIcon />
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, paddingLeft: { sm: '50px' } }}>
          {navItems.map((item) =>
            isUserAllowed(item.allowedRoles) && showNavItems ? (
              <NavbarButton
                key={item.key}
                color="inherit"
                className={location.toLowerCase().includes(item.path.toLowerCase()) ? 'active' : ''}
                onClick={() => {}}
                to={item.path}
                sx={{ fontSize: { md: '1.125rem', lg: '1.5rem' }, padding: { md: '8px', lg: '24px' } }}
              >
                {item.label}
              </NavbarButton>
            ) : null,
          )}
        </Box>
        <Box sx={{ display: 'flex', gap: '1.25rem', marginLeft: 'auto' }}>
          {showNavItems ? (
            <>
              <LanguageSelector />
              <ClickableIcon onClick={() => {}}>
                <RouterLink target="_blank" to={`${import.meta.env.VITE_LOGOUT_RETURN_URL}/help/Default_CSH.htm`}>
                  <HelpIcon />
                </RouterLink>
              </ClickableIcon>
              <ClickableIcon onClick={() => {}}>
                <SearchIcon />
              </ClickableIcon>
              <NavbarUserMenu />
            </>
          ) : null}
        </Box>
      </Toolbar>

      <DrawerWrapper
        disablePortal
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '360px' },
        }}
      >
        {renderDrawer}
      </DrawerWrapper>
    </AppBarWrapper>
  );
}

export default React.memo(Navbar);
