import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import { DevicesCounterResponse, UsersCounterResponse } from '../../models/DashboardModels';

const DashboardService = {
  api: {
    getUsersCounter: (): AxiosPromise<UsersCounterResponse> => Api.get(`/dashboard/users/counter`),
    getDevicesCounter: (): AxiosPromise<DevicesCounterResponse> => Api.get(`/dashboard/devices/counter`),
  },
};

export default DashboardService;
