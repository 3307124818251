import i18next from 'i18next';
import { useMemo } from 'react';
import { LocaleDateFormatConfig, LocaleDateTimeFormatConfig } from '../../configs/DateConfig';

function useDate() {
  const { language } = i18next;
  const localeDateTimeFormat: string = useMemo(() => {
    if (Object.hasOwn(LocaleDateTimeFormatConfig.format, language)) {
      return LocaleDateTimeFormatConfig.format[language];
    }
    return LocaleDateTimeFormatConfig.format.en;
  }, [language]);

  const localeDateFormat: string = useMemo(() => {
    if (Object.hasOwn(LocaleDateFormatConfig.format, language)) {
      return LocaleDateFormatConfig.format[language];
    }
    return LocaleDateFormatConfig.format.en;
  }, [language]);

  return { localeDateTimeFormat, localeDateFormat };
}

export default useDate;
