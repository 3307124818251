import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem } from '../../components/Dropdown/DropdownModels';
import FilterTag from '../../components/FilterTag/FilterTag';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { DevicesReportFilter } from '../../models/DevicesReportModels';
import DevicesReportService from '../../services/DevicesReports/DevicesReportService';
import { removeFilter } from '../../stores/DevicesReportStore';
import { RootState } from '../../stores/Store';

function useDevicesReportFilters() {
  const [t] = useTranslation();
  const [devicesStatusOptions, setDevicesStatusOptions] = useState<DropdownItem[]>([
    {
      key: 'ALL',
      value: '',
      label: t(`common.status.ALL`),
    },
  ]);
  const [devicesStatus, setDevicesStatus] = useState<string[]>([]);
  const { filter, companies } = useSelector((state: RootState) => state.devicesReportReducer);
  const snackBar = useSnackBar();
  const dispatch = useDispatch();

  useEffect(() => {
    DevicesReportService.api.fetchDevicesStatusTypes().then(
      (response) => {
        const types = response.data.status.sort();
        setDevicesStatus(types);
        const deviceStatusDropdownOptions = [
          ...types.map((obj) => ({
            key: obj,
            value: obj,
            label: t(`common.simStatus.${obj}`),
          })),
        ].sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setDevicesStatusOptions((prev) => [...prev, ...deviceStatusDropdownOptions]);
      },
      () => {
        snackBar.showSnackBar(t('pages.devicesReport.getDevicesStatusError'), 'error');
      },
    );
  }, []);

  /**
   * Removes the filter from the store
   * @param filterKey
   */
  const onRemoveFilter = (filterKey: keyof DevicesReportFilter) => {
    dispatch(removeFilter(filterKey));
  };

  /**
   * Applies the proper transformations to a given type of filter tag
   * @param filterKey
   */
  const filterTagAdapter = (filterKey: keyof DevicesReportFilter) => {
    if (filter[filterKey] === '') return null;
    switch (filterKey) {
      case 'companyCode':
        return (
          <FilterTag key={filterKey} onRemove={() => onRemoveFilter(filterKey)}>
            {companies.find((c) => c.companyCode === filter[filterKey])?.name}
          </FilterTag>
        );
      case 'status':
        return null;
      case 'type':
        return (
          <FilterTag key={filterKey} onRemove={() => onRemoveFilter(filterKey)}>
            {t(`common.deviceType.${filter[filterKey]}`)}
          </FilterTag>
        );
      case 'uemSystem':
        return (
          <FilterTag key={filterKey} onRemove={() => onRemoveFilter(filterKey)}>
            {t(`pages.uemOnboarding.uemTypes.${filter[filterKey]}`)}
          </FilterTag>
        );
      default:
        return (
          <FilterTag key={filterKey} onRemove={() => onRemoveFilter(filterKey)}>
            {filter[filterKey]}
          </FilterTag>
        );
    }
  };

  const renderFilterTags = useCallback(() => {
    const existingFilterKeys = Object.keys(filter);
    const filterTags: ReactNode[] = [];
    existingFilterKeys.forEach((fk) => {
      const f = filterTagAdapter(fk as keyof DevicesReportFilter);
      if (f) {
        filterTags.push(f);
      }
    });
    return filterTags;
  }, [filter]);

  return { devicesStatusOptions, devicesStatus, renderFilterTags };
}

export default useDevicesReportFilters;
