import { useEffect, useState } from 'react';
import { DropdownItem } from '../../../../components/Dropdown/DropdownModels';
import { UsersListRequest } from '../../../../models/UsersModels';
import UsersService from '../../../../services/Users/UsersService';

function useNewConnectionUserStep(query: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [userOptions, setUserOptions] = useState<DropdownItem[]>([]);

  /**
   * Gets the list of users and creates the options for the autocomplete
   */
  const getUsers = () => {
    setIsLoading(true);
    const requestParams: UsersListRequest = {
      page: 0,
      pageSize: 25,
      sort: 'firstName',
      sortingType: 'ASC',
      filter: query,
    };
    UsersService.api.fetchUsers(requestParams).then(
      (response) => {
        const options = response.data.users.map((u) => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName}`,
          key: u.id,
          secondaryLabel: u.email,
          data: u,
        }));
        setUserOptions(options);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    getUsers();
  }, [query]);

  return { userOptions, isLoading };
}

export default useNewConnectionUserStep;
