import { ButtonProps as MaterialButtonProps } from '@mui/material/Button/Button';

/**
 * Properties of the Button wrapper component
 * Based on the Material UI Button
 */
export interface ButtonProps extends MaterialButtonProps {
  color?: 'primary' | 'primaryButton' | 'secondaryButton' | 'alternative1Button' | 'alternative2Button';
  label: string | JSX.Element;
}

/**
 * Types of Button variants
 */
export enum ButtonVariant {
  contained = 'contained',
  outlined = 'outlined',
  text = 'text',
}

/**
 * Types of available colors for the button
 * Used to map a color to a given variant, for example
 */
export enum ButtonColor {
  primaryButton = 'primaryButton',
  secondaryButton = 'secondaryButton',
  alternative1Button = 'alternative1Button',
  alternative2Button = 'alternative2Button',
  default = 'default',
}

/**
 * Object literal that maps a color into a button variant
 */
export const ButtonColorVariants = {
  [ButtonColor.primaryButton]: ButtonVariant.contained,
  [ButtonColor.secondaryButton]: ButtonVariant.contained,
  [ButtonColor.alternative1Button]: ButtonVariant.outlined,
  [ButtonColor.alternative2Button]: ButtonVariant.outlined,
  [ButtonColor.default]: ButtonVariant.contained,
};
