import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import {
  DeleteUemResponse,
  GetUemResponse,
  UemCompaniesResponse,
  UemCreateResponse,
  UemListRequest,
  UemListResponse,
  UemOnboardingResponse,
} from '../../models/UemOnboardingModels';

const UemOnboardingService = {
  api: {
    createUem: (params: FormData): AxiosPromise<UemCreateResponse> =>
      Api.post(`/uem/create`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),

    updateUem: (params: FormData): AxiosPromise<UemCreateResponse> =>
      Api.post(`/uem/update`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    fetchUemTypes: (): AxiosPromise<UemOnboardingResponse> => Api.get(`/uem/uem-types`, {}),
    fetchCompanies: (): AxiosPromise<UemCompaniesResponse> => Api.get(`/administrators/companies`, {}),
    fetchUems: (params: UemListRequest): AxiosPromise<UemListResponse> => Api.post(`/uem/list`, params),
    deleteUem: (id: string): AxiosPromise<DeleteUemResponse> => Api.delete(`/uem/${id}`),
    getUem: (id: string): AxiosPromise<GetUemResponse> => Api.get(`/uem/id/${id}`),
  },
};

export default UemOnboardingService;
