import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AutoComplete from '../../../../components/AutoComplete/AutoComplete';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import { resetEid, updateRequest, updateSelectedDropdownUser } from '../../../../stores/NewConnectionStore';
import { RootState } from '../../../../stores/Store';
import useNewConnectionUserStep from './useNewConnectionUserStep';

interface NewConnectionSelectUserStepProps {
  nextStep: () => void;
  onClose: () => void;
}
function NewConnectionSelectUserStep({ nextStep, onClose }: NewConnectionSelectUserStepProps) {
  const [t] = useTranslation();
  const selectedDropdownUser = useSelector((state: RootState) => state.newConnectionReducer.selectedDropdownUser);
  const [query, setQuery] = useState(selectedDropdownUser.label);
  const { userOptions, isLoading } = useNewConnectionUserStep(query as string);
  const dispatch = useDispatch();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ paddingBottom: '2rem' }}>
        <AutoComplete
          onInputChange={(val) => setQuery(val as string)}
          value={selectedDropdownUser}
          label={t('pages.eSimManager.newConnectionDrawer.selectUser')}
          placeholder={t('pages.eSimManager.newConnectionDrawer.selectUserPlaceholder')}
          onChange={(opt) => {
            if (opt) {
              dispatch(updateRequest({ userId: opt.value as string }));
              dispatch(updateSelectedDropdownUser(opt));
              dispatch(resetEid());
            }
          }}
          isLoading={isLoading}
          options={userOptions}
        />
      </Box>
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          <Button
            label={t('common.continue')}
            color="primaryButton"
            disabled={!selectedDropdownUser.key}
            onClick={nextStep}
          />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default NewConnectionSelectUserStep;
