import { FormControlLabel, Radio as MaterialRadio, RadioProps as MaterialRadioProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface RadioProps extends MaterialRadioProps {
  label: string | ReactNode;
  value: string;
}
function Radio({ label, value, ...rest }: RadioProps) {
  const theme = useTheme();
  return (
    <FormControlLabel
      value={value}
      control={<MaterialRadio disableRipple color="primaryRadio" {...rest} />}
      label={label}
      sx={{
        paddingBottom: '0.5rem',
        '& .MuiButtonBase-root': {
          flexShrink: 0,
        },
        '& .MuiFormControlLabel-label': {
          fontSize: '1.125rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '1.6875rem',
          color: theme.palette.monochrome.monoChrome6,
        },
      }}
    />
  );
}

export default Radio;
