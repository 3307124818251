import { SvgIconProps } from '@mui/material';
import * as React from 'react';
import { memo } from 'react';

function ChevronDownIcon(props?: SvgIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        stroke={props?.stroke ? props.stroke : '#666'}
        strokeLinecap="round"
        d="M13.667 5.167 8 10.833 2.333 5.167"
      />
    </svg>
  );
}
export default memo(ChevronDownIcon);
