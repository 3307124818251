import * as React from 'react';
import { SVGProps, memo } from 'react';

function UserIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 20.512c-.81-5.485-4.146-8.028-7.455-8.028S5.311 15.026 4.5 20.51m7.5-8.027a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
      />
    </svg>
  );
}

export default memo(UserIcon);
