/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
// eslint-disable-next-line no-console
console.warn(`eSIM Frontend v${import.meta.env.PACKAGE_VERSION}-${import.meta.env.GIT_SHA}`);
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
