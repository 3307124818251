import * as React from 'react';
import { SVGProps, memo } from 'react';

function ResetIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        stroke={props?.stroke ?? '#333'}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.605 19.469a7.5 7.5 0 0 0 .215-15h-.235a7.5 7.5 0 0 0-7.49 7.5v3.42m0 0L9.68 11.95m-3.585 3.439L2.608 11.95"
      />
    </svg>
  );
}
export default memo(ResetIcon);
