import { LinkProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { PropsWithChildren } from 'react';

const ClickableIconWrapper = styled('div')(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

interface ClickableIconProps extends PropsWithChildren, Omit<LinkProps, 'onClick'> {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

function ClickableIcon({ children, onClick, ...rest }: ClickableIconProps) {
  return (
    <ClickableIconWrapper onClick={onClick} sx={rest.sx}>
      {children}
    </ClickableIconWrapper>
  );
}

export default ClickableIcon;
