import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import {
  AuditCompaniesResponse,
  CompaniesListRequest,
  CompaniesListResponse,
  CompanyCreateResponse,
  CompanyDeleteResponse,
  CompanyNotificationSettingsResponse,
  CompanyOnboardingRequest,
  CompanyResponse,
  CompanyUpdateRequest,
} from '../../models/CompanyOnboardingModels';

const CompanyOnboardingService = {
  api: {
    createCompany: (params: CompanyOnboardingRequest): AxiosPromise<CompanyCreateResponse> =>
      Api.post(`/companies/create`, params),
    fetchCompanies: (params: CompaniesListRequest): AxiosPromise<CompaniesListResponse> =>
      Api.post(`/companies/list`, params),
    fetchCompany: (id: string): AxiosPromise<CompanyResponse> => Api.get(`/companies/${id}`),
    fetchCompanyNotificationSettings: (id: string): AxiosPromise<CompanyNotificationSettingsResponse> =>
      Api.get(`/companies/${id}/notifications-settings`),
    deleteCompany: (id: string): AxiosPromise<CompanyDeleteResponse> => Api.delete(`/companies/${id}`),
    fetchAuditCompanies: (): AxiosPromise<AuditCompaniesResponse> => Api.get(`/audit/companies`),
    updateCompany: (params: CompanyUpdateRequest): AxiosPromise<CompanyResponse> =>
      Api.post('companies/update', params),
  },
};

export default CompanyOnboardingService;
