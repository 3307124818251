import { FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ClickableIcon from '../../../../components/Icons/ClickableIcon';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import { Header, Row } from '../../../../components/Table/TableModels';
import { BillingAccount } from '../../../../models/CompanyOnboardingModels';

function useCompaniesOnboardingBansTable(formik: FormikProps<any>, editMode: boolean) {
  const [t] = useTranslation();
  const { bans } = formik.values;

  /**
   * Removes an item from the list
   * @param ban
   */
  const removeItem = (ban: BillingAccount) => {
    const list = formik.values.bans.filter((b: BillingAccount) => b.ban !== ban.ban);
    formik.setFieldValue('bans', list).then(() => {
      formik.validateForm();
    });
  };

  const headers: Header[] = useMemo(
    () => [
      {
        id: 'name',
        label: t('translation:pages.companyOnboarding.banName'),
        isSortable: true,
      },
      {
        id: 'ban',
        label: t('translation:pages.companyOnboarding.ban'),
        isSortable: true,
      },
      {
        id: 'ben',
        label: t('translation:pages.companyOnboarding.ben'),
        isSortable: true,
      },
      {
        id: 'delete',
        label: '',
      },
    ],
    [t],
  );

  const rows: Row[] = useMemo(
    () =>
      bans?.map((ban: BillingAccount) => ({
        id: ban.ban,
        cells: [
          {
            value: ban.name,
          },
          {
            value: ban.ban,
          },
          {
            value: ban.ben,
          },
          {
            value: editMode ? (
              <ClickableIcon
                sx={{ display: 'flex', justifyContent: 'end' }}
                onClick={() => {
                  removeItem(ban);
                }}
              >
                <CloseIcon />
              </ClickableIcon>
            ) : (
              <div />
            ),
          },
        ],
      })),
    [t, formik],
  );

  return { headers, rows };
}

export default useCompaniesOnboardingBansTable;
