import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { UserRequest, UserResponse } from '../../../models/UsersModels';
import UsersService from '../../../services/Users/UsersService';

function useUserDetails(id: string | undefined) {
  const [user, setUser] = useState<UserResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();
  const [t] = useTranslation();

  React.useEffect(() => {
    if (id) {
      setIsLoading(true);
      const requestParams: UserRequest = {
        id,
      };
      UsersService.api.fetchUser(requestParams).then(
        (response) => {
          setUser(response.data);
          setIsLoading(false);
        },
        () => {
          snackBar.showSnackBar(t('pages.userDetails.fetchUserError'), 'error');
        },
      );
    }
  }, [id]);

  return { user, isLoading };
}

export default useUserDetails;
