import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import { ImportRequest, ImportResponse } from '../../models/ImportModels';

const ImportService = {
  api: {
    uploadFile: (params: ImportRequest): AxiosPromise<ImportResponse> =>
      Api.post(`/import/upload-file`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
  },
};

export default ImportService;
