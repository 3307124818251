import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import CloseIcon from '../../components/Icons/CloseIcon';
import DownloadIcon from '../../components/Icons/DownloadIcon';
import { ErrorExclamationIcon2x } from '../../components/Icons/ErrorExclamationIcon';
import UploadIcon from '../../components/Icons/UploadIcon';
import ClickableLinkWithIcon from '../../components/Text/ClickableLinkWithIcon';
import PageTitle from '../../components/Text/PageTitle';
import useModal from '../../hooks/useModal/useModal';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { ErrorLine } from '../../models/ImportModels';
import ImportService from '../../services/Import/ImportService';

function Import() {
  const [t] = useTranslation();
  const theme = useTheme();
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const modal = useModal();

  /**
   * Simulates the click on the hidden input by clicking on the displayed button
   */
  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  /**
   * Removes the selected file
   */
  const removeFile = () => {
    setFile(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  /**
   * Handles file change
   * @param e
   */
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    if (e.target.files[0].size > 25 * 1000000) {
      snackBar.showSnackBar(t('common.inputValidations.maxSize', { val: '25mb' }), 'error');
      removeFile();
      return;
    }
    setFile(e.target.files[0]);
  };

  /**
   * Renders the errors
   * @param errorLines
   * @param successfulLines
   */
  const getModalErrors = (errorLines: ErrorLine[], successfulLines: number) => (
    <Box>
      <Typography sx={{ textAlign: 'left' }}>
        {t('pages.import.linesSuccessfullyImported', { n: successfulLines })}
      </Typography>
      <Typography sx={{ textAlign: 'left' }}>{t('pages.import.importFailedDescription')}</Typography>
      <br />
      <ul style={{ paddingInlineStart: '1.75rem', maxHeight: '8rem', overflow: 'auto' }}>
        {errorLines.map((errorLine) => (
          <>
            {errorLine.reasons.map((reason) => (
              <li key={`line-${errorLine.line}`}>
                <Typography sx={{ textAlign: 'left' }}>
                  <>
                    {t('pages.import.line')} {errorLine.line}:&nbsp;
                    {t(`common.errors.httpReason.${reason}`)}
                  </>
                </Typography>
              </li>
            ))}
          </>
        ))}
      </ul>
      <br />
      <Typography sx={{ textAlign: 'left' }}>{t('pages.import.pleaseCorrect')}</Typography>
    </Box>
  );

  /**
   * Upload the selected csv file
   */
  const uploadFile = () => {
    setIsLoading(true);
    if (file) {
      ImportService.api
        .uploadFile({ file })
        .then(
          (res) => {
            if (res.data.errorLines && res.data.errorLines.length > 0) {
              modal.showModal({
                modalIcon: (
                  <Box sx={{ paddingBottom: '1.25rem' }}>
                    <ErrorExclamationIcon2x />
                  </Box>
                ),
                modalTitle: t('pages.import.importFailedTitle'),
                modalDescription: getModalErrors(res.data.errorLines, res.data.successfulLines),
                onAccept: (setOpen) => {
                  setOpen(false);
                  removeFile();
                },
                onDiscard: removeFile,
              });
            } else {
              snackBar.showSnackBar(t('pages.import.uploadSuccess'), 'success');
              navigate('/esimmanager');
            }
          },
          () => {
            snackBar.showSnackBar(t('pages.import.uploadError'), 'error');
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Grid container sx={{ paddingBottom: '2rem' }}>
      <Grid item xl={4} md={6} xs={12}>
        <PageTitle>{t('pages.import.title')}</PageTitle>
        <Typography variant="body1" paddingTop="1.5rem" lineHeight="normal">
          {t('pages.import.templateInstructions')}
        </Typography>
        <Box sx={{ padding: '1.5rem 0', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <ClickableLinkWithIcon
            onClick={() => {
              window.open('./misc/users_example.csv');
            }}
            leftIcon={<DownloadIcon fill={theme.palette.state.linkHover} />}
          >
            {t('pages.import.usersTemplate')}
          </ClickableLinkWithIcon>
          <ClickableLinkWithIcon
            onClick={() => {
              window.open('./misc/devices_example.csv');
            }}
            leftIcon={<DownloadIcon fill={theme.palette.state.linkHover} />}
          >
            {t('pages.import.devicesTemplate')}
          </ClickableLinkWithIcon>
        </Box>
        <Typography variant="body1" lineHeight="normal">
          {t('pages.import.uploadInstructions')}
        </Typography>
        <Button
          onClick={handleUploadClick}
          color="secondaryButton"
          label={
            file ? (
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                {file.name}
                <CloseIcon
                  stroke="#fff"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile();
                  }}
                />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                <UploadIcon />
                {t('pages.import.uploadCsvFile')}
              </Box>
            )
          }
          sx={{ margin: '2.5rem 0 1.5rem 0', width: '100%' }}
        />
        <input type="file" ref={inputRef} accept=".csv" onChange={handleFileChange} style={{ display: 'none' }} />
        <Button
          color="primaryButton"
          label={t('pages.import.submit')}
          onClick={uploadFile}
          disabled={!file?.name || isLoading}
        />
      </Grid>
    </Grid>
  );
}

export default Import;
