import { UserRole } from './AuthModels';

/**
 * Model to express a clickable link
 * Used on navigation
 */
export type Link = {
  label: string;
  path: string;
  key: string;
  allowedRoles: UserRole[];
};

/**
 * Type of orders for our sorting mechanism
 * Used on table header sorting
 */
export type Order = 'asc' | 'desc';

/**
 * Interface that represents a sort operation
 * It always needs to have an orderBy (ie 'name') and an order (ie 'asc')
 */
export interface Sort {
  orderBy: string;
  order: Order;
}

/**
 * Used when the interface of a model is dynamic, based on API params etc
 */
export type DynamicObject<T> = {
  [key: string]: T;
};

/**
 * Common request across app
 */
export enum Status {
  'NEW' = 'NEW',
  'ERROR' = 'ERROR',
  'DONE' = 'DONE',
  'PROCESSING' = 'PROCESSING',
}
