import { Box, TableCell, TableFooter, TableRow, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import InfoIcon from '../Icons/InfoIcon';

interface TableEmptyStateProps {
  message?: string;
  icon?: JSX.Element;
  margin?: string;
}

function TableEmptyState({
  message = t('common.table.emptyState'),
  icon = <InfoIcon />,
  margin = '1.5rem 0rem',
}: TableEmptyStateProps) {
  const theme = useTheme();
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={99999} sx={{ padding: '0', border: 'none' }}>
          <Box
            width="100%"
            sx={{
              backgroundColor: theme.palette.monochrome.monoChrome2,
              padding: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              margin,
            }}
          >
            {icon}
            <Typography variant="body1" sx={{ paddingLeft: '0.5rem' }}>
              {message}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export default TableEmptyState;
