import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import PlusIcon from '../../components/Icons/PlusIcon';
import PageTitle from '../../components/Text/PageTitle';
import useRolePermissions from '../../hooks/useRolePermissions/useRolePermissions';
import { FeaturePermissions } from '../../models/AuthModels';
import UemsTable from './UemsTable';

function Uems() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { isUserAllowed } = useRolePermissions();

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PageTitle>{t('pages.uemOnboarding.title')}</PageTitle>
        {isUserAllowed(FeaturePermissions.uemCreate) ? (
          <Button
            sx={{ marginLeft: 'auto' }}
            onClick={() => navigate('/uemOnboarding/create')}
            label={
              <>
                <Box sx={{ marginRight: '0.5rem' }}>
                  <PlusIcon />
                </Box>
                {t('translation:pages.uemOnboarding.newOnboarding')}
              </>
            }
          />
        ) : null}
      </Box>
      <UemsTable />
    </>
  );
}

export default Uems;
