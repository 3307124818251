import { useTranslation } from 'react-i18next';
import { ApiResponse } from '../../models/ServiceModels';

function useHttpError() {
  const [t, i18n] = useTranslation();

  /**
   * Checks if a payload has a reason field and returns its value description
   * @param data
   * @param defaultError
   * @param errorPrefix
   */
  const getHttpReason = <T extends ApiResponse>(data: T, defaultError: string, errorPrefix?: string) => {
    if (data.reason && i18n.exists(`common.errors.httpReason.${data.reason}`)) {
      return `${errorPrefix ?? ''}${t(`common.errors.httpReason.${data.reason}`)}`;
    }
    return defaultError;
  };

  /**
   * Checks if a payload has a reason field and returns
   * its value description if that reason is present in the list of availableReasons
   * @param data
   * @param availableReasons
   * @param defaultError
   * @param errorPrefix
   */
  const getHttpReasonFromList = <T extends ApiResponse>(
    data: T,
    availableReasons: number[],
    defaultError: string,
    errorPrefix?: string,
  ) => {
    if (
      data.reason &&
      availableReasons.includes(data.reason) &&
      i18n.exists(`common.errors.httpReason.${data.reason}`)
    ) {
      return `${errorPrefix ?? ''}${t(`common.errors.httpReason.${data.reason}`)}`;
    }
    return defaultError;
  };

  return { getHttpReason, getHttpReasonFromList };
}

export default useHttpError;
