import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DropdownItem } from '../../../components/Dropdown/DropdownModels';
import { DefaultCompanyCode } from '../../../configs/PortalConfig';
import useHttpError from '../../../hooks/useHttpError/useHttpError';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { Admin, AdminOnboardingRequest, AdminUpdateRequest } from '../../../models/AdminOnboardingModels';
import { UemCompany } from '../../../models/UemOnboardingModels';

import AdminOnboardingService from '../../../services/AdminOnboarding/AdminOnboardingService';
import MarketsService from '../../../services/Markets/MarketsService';
import UemOnboardingService from '../../../services/UemOnboarding/UemOnboardingService';

function useAdminOnboarding(id?: string, selectedMarket?: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();
  const [rolesOptions, setRolesOptions] = useState<DropdownItem[]>([]);
  const [companiesOptions, setCompaniesOptions] = useState<DropdownItem[]>([]);
  const [marketOptions, setMarketOptions] = useState<DropdownItem[]>([]);
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState<Admin | undefined>();
  const [companies, setCompanies] = useState<UemCompany[]>([]);
  const { getHttpReason } = useHttpError();

  /**
   * Selects the correct async call to fetch the admin roles
   * Fetches all roles if it is a creation
   * Fetches only the available admin roles for that admin, if it's an edit
   */
  const fetchRoles = async () => {
    if (id) return AdminOnboardingService.api.fetchAdminRoles(id);
    return AdminOnboardingService.api.fetchAllRoles();
  };

  useEffect(() => {
    /**
     * Fetches list of roles
     */
    const fetchData = async () => {
      setIsLoading(true);
      const rolesResponse = await fetchRoles();
      const rolesDropdownOptions = rolesResponse.data.roles.map((obj) => ({
        key: obj,
        value: obj,
        label: `${t(`pages.adminOnboarding.roles.${obj}`)}`,
      }));
      setRolesOptions(rolesDropdownOptions);

      const companiesResponse = await UemOnboardingService.api.fetchCompanies();
      const filteredCompanies = companiesResponse.data.companies.filter((c) => c.companyCode !== DefaultCompanyCode);
      const companiesDropdownOptions = filteredCompanies.map((obj) => ({
        key: obj.companyCode,
        value: obj.companyCode,
        label: `${obj.name}`,
      }));
      setCompanies(filteredCompanies);
      setCompaniesOptions(companiesDropdownOptions);

      const marketsResponse = await MarketsService.api.fetchMarkets();
      const filteredMarkets = marketsResponse.data.markets.filter((m) => m.marketType === 'COUNTRY');
      const marketsDropdownOptions = filteredMarkets.map((obj) => ({
        key: obj.id,
        value: obj.id,
        label: `${obj.name}`,
      }));
      setMarketOptions(marketsDropdownOptions);
      setIsLoading(false);
    };
    fetchData().catch(() => {
      snackBar.showSnackBar(t('pages.adminOnboarding.getRolesError'), 'error');
      setIsLoading(false);
    });
  }, [t]);

  useEffect(() => {
    if (id) {
      AdminOnboardingService.api.fetchAdmin(id).then(
        (response) => {
          setAdminData(response.data);
        },
        () => {
          snackBar.showSnackBar(t('pages.adminOnboarding.getAdminError'), 'error');
        },
      );
    }
  }, [id]);

  /**
   * Creates an admin remotely
   * @param params
   */
  const createAdmin = (params: AdminOnboardingRequest) => {
    setIsLoading(true);
    AdminOnboardingService.api
      .createAdmin(params)
      .then(
        () => {
          snackBar.showSnackBar(t('pages.adminOnboarding.adminOnboardingSuccess'), 'success');
          navigate('/admins');
        },
        (res) => {
          const error = getHttpReason(res.response.data, t('pages.adminOnboarding.adminOnboardingError'));
          snackBar.showSnackBar(error, 'error');
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Updates an admin remotely
   * @param params
   */
  const updateAdmin = (params: AdminUpdateRequest) => {
    setIsLoading(true);
    AdminOnboardingService.api
      .editAdmin(params)
      .then(
        () => {
          snackBar.showSnackBar(t('pages.adminOnboarding.updateAdminSuccess'), 'success');
          navigate('/admins');
        },
        (res) => {
          const error = getHttpReason(res.response.data, t('pages.adminOnboarding.updateAdminError'));
          snackBar.showSnackBar(error, 'error');
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Check if the company belongs to a given market
   * @param market
   * @param companyCode
   */
  const isCompanyInMarket = (market: string, companyCode: string) => {
    const company = companies.find((c) => c.companyCode === companyCode);
    return company && company.market === market;
  };

  /**
   * Sets the list of company dropdowns based on the selected country
   */
  const createCompanyDropdownOptions = () => {
    if (selectedMarket) {
      const options = companies
        .filter((c) => c.marketId === selectedMarket)
        .map((c) => ({
          key: c.companyCode,
          label: c.name,
          value: c.companyCode,
        }));
      setCompaniesOptions(options);
    } else {
      const options = companies.map((c) => ({
        key: c.companyCode,
        label: c.name,
        value: c.companyCode,
      }));
      setCompaniesOptions(options);
    }
  };

  /**
   * Helper function to return a company object by companyCode
   * @param companyCode
   */
  const getCompanyByCompanyCode = (companyCode: string) => {
    if (companies) {
      return companies.find((c) => c.companyCode === companyCode);
    }
    return null;
  };

  useEffect(() => {
    createCompanyDropdownOptions();
  }, [selectedMarket]);

  return {
    rolesOptions,
    companiesOptions,
    marketOptions,
    isCompanyInMarket,
    createAdmin,
    updateAdmin,
    isLoading,
    adminData,
    getCompanyByCompanyCode,
  };
}

export default useAdminOnboarding;
