import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table/Table';
import TablePagination from '../../components/TablePagination/TablePagination';
import { Sort } from '../../models/CommonModels';
import AdminsTableFilters, { AdminFilter } from './AdminsTableFilters';
import useAdminsTable from './useAdminsTable';

function AdminsTable() {
  const [t] = useTranslation();
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState<AdminFilter>({ companyId: '', filter: '' });
  const [sort, setSort] = useState<Sort>({ order: 'asc', orderBy: 'email' });
  const { headers, rows, totalAdmins, isLoading } = useAdminsTable(activePage, pageSize, filter, sort);

  /**
   * On pagination change
   * Perform a new request
   * @param page
   * @param pSize
   */
  const handlePaginationChange = (page: number, pSize: number) => {
    setActivePage(page);
    setPageSize(pSize);
  };

  /**
   * On search click
   * @param adminFilter
   */
  const handleSearch = (adminFilter: AdminFilter) => {
    setActivePage(1);
    setFilter(adminFilter);
  };

  /**
   * Handle change of the table sort
   * @param s
   */
  const handleOnSort = (s: Sort) => {
    setSort(s);
  };

  return (
    <Box paddingBottom="3rem">
      <AdminsTableFilters onSearch={handleSearch} isLoading={isLoading} />
      <Typography variant="body1" paddingBottom="1rem">
        {t('common.table.totalItems', { count: totalAdmins })}
      </Typography>
      <Table headers={headers} rows={rows} isLoading={isLoading} defaultOrderBy={sort.orderBy} onSort={handleOnSort} />
      <TablePagination
        activePage={activePage}
        pageSize={pageSize}
        totalItems={totalAdmins}
        handlePageChange={handlePaginationChange}
      />
    </Box>
  );
}

export default AdminsTable;
