import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DropdownItem } from '../../../components/Dropdown/DropdownModels';
import useRolePermissions from '../../../hooks/useRolePermissions/useRolePermissions';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { FeaturePermissions } from '../../../models/AuthModels';

import {
  UemCreateRequest,
  UemField,
  UemListEntry,
  UemType,
  UemUpdateRequest,
} from '../../../models/UemOnboardingModels';
import UemOnboardingService from '../../../services/UemOnboarding/UemOnboardingService';

function useUemOnboarding(id?: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();
  const [uemTypeOptions, setUemTypeOptions] = useState<DropdownItem[]>([]);
  const [companyOptions, setCompanyOptions] = useState<DropdownItem[]>([]);
  const [authenticationOptions, setAuthenticationOptions] = useState<DropdownItem[]>([]);
  const [uemTypes, setUemTypes] = useState<UemType[]>([]);
  const [selectedType, setSelectedType] = useState('');
  const [authentication, setAuthentication] = useState('');
  const [fields, setFields] = useState<UemField[]>([]);
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const { isUserAllowed } = useRolePermissions();
  const [uemData, setUemData] = useState<UemListEntry>({
    type: '',
    id: '',
    apiKey: '',
    authentication: '',
    clientId: '',
    companyName: '',
    companyId: '',
    scope: '',
    tenantId: '',
    username: '',
    usernameCertificate: '',
  });
  const [editableFields, setEditableFields] = useState<string[]>([]);

  useEffect(() => {
    const uemType = uemTypes.find((type) => type.type === selectedType);
    if (uemType) {
      if (uemType.authentication.length > 1) {
        if (!id) {
          setFields([]);
          setAuthentication('');
        }
        const authenticationDropdownOptions = uemType.authentication.map((obj) => ({
          key: obj.type,
          value: obj.type,
          label: t(`pages.uemOnboarding.authenticationTypes.${obj.type}`),
        }));
        setAuthenticationOptions(authenticationDropdownOptions);
      } else if (uemType.authentication.length === 1) {
        setAuthentication(uemType.authentication[0].type);
        setAuthenticationOptions([]);
        setFields(uemType.authentication[0].fields as UemField[]);
      }
    }
  }, [selectedType, uemTypes]);

  /**
   * Defines the list of dynamic uem onboarding fields
   * @param uemType
   */
  const defineUemFields = (uemType?: string) => {
    const uemTypeObj = uemTypes.find((type) => type.type === selectedType || uemType);
    if (uemTypeObj) {
      const authenticationFields = uemTypeObj.authentication.find((auth) => auth.type === authentication);
      setFields((authenticationFields?.fields as UemField[]) ?? []);
    }
  };

  useEffect(() => {
    if (authentication) {
      defineUemFields();
    } else {
      setFields([]);
    }
  }, [authentication]);

  useEffect(() => {
    /**
     * Fetches UEM types and the list of companies
     */
    const fetchData = async () => {
      setIsLoading(true);
      const uemTypesResponse = await UemOnboardingService.api.fetchUemTypes();
      const resultUemTypes: UemType[] = [];
      const uemTypesDropdownOptions = uemTypesResponse.data.uemTypeList.map((obj) => {
        resultUemTypes.push(obj);
        return {
          key: obj.type,
          value: obj.type,
          label: t(`pages.uemOnboarding.uemTypes.${obj.type}`),
        };
      });

      setUemTypes(resultUemTypes);
      setUemTypeOptions(uemTypesDropdownOptions);
      if (isUserAllowed(FeaturePermissions.uemCreate)) {
        const companiesResponse = await UemOnboardingService.api.fetchCompanies();
        const companiesDropdownOptions = companiesResponse.data.companies.map((obj) => ({
          key: obj.id,
          value: obj.id,
          label: `${obj.name} ${obj.country}`,
        }));
        setCompanyOptions(companiesDropdownOptions);
      }
      if (id) {
        const uemDataResponse = await UemOnboardingService.api.getUem(id);
        if (uemDataResponse.data.editableFields) {
          setEditableFields(uemDataResponse.data.editableFields);
        }
        setUemData(uemDataResponse.data.uemCredentials);
        setSelectedType(uemDataResponse.data.uemCredentials.type);
        setAuthentication(uemDataResponse.data.uemCredentials.authentication);
        defineUemFields(uemDataResponse.data.uemCredentials.type);
      }
      setIsLoading(false);
    };
    fetchData().catch(() => {
      if (id) {
        snackBar.showSnackBar(t('pages.uemOnboarding.getUemError'), 'error');
        navigate('/uemOnboarding');
      } else {
        snackBar.showSnackBar(t('pages.uemOnboarding.getUemTypesError'), 'error');
        setIsLoading(false);
      }
    });
  }, [t]);

  /**
   * Update UEM remotely
   * @param params
   */
  const updateUem = (params: UemUpdateRequest) => {
    setIsLoading(true);
    const formData = new FormData();
    const blob = new Blob([JSON.stringify(params.uemCredentialsRequest)], { type: 'application/json' });
    if (params.certificate) formData.append('certificate', params.certificate);
    formData.append('uemCredentialsRequest', blob);
    UemOnboardingService.api
      .updateUem(formData)
      .then(
        () => {
          snackBar.showSnackBar(t('pages.uemOnboarding.uemUpdateSuccess'), 'success');
          navigate('/uemOnboarding');
        },
        () => {
          snackBar.showSnackBar(t('pages.uemOnboarding.uemUpdateError'), 'error');
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Creates UEM remotely
   * @param params
   * @param resetForm
   */
  const createUem = (params: UemCreateRequest, resetForm: () => void) => {
    setIsLoading(true);
    const formData = new FormData();
    const blob = new Blob([JSON.stringify(params.uemCredentialsRequest)], { type: 'application/json' });
    if (params.certificate) formData.append('certificate', params.certificate);
    formData.append('uemCredentialsRequest', blob);
    UemOnboardingService.api
      .createUem(formData)
      .then(
        () => {
          snackBar.showSnackBar(t('pages.uemOnboarding.uemValidationSuccess'), 'success');
          navigate('/uemOnboarding');
        },
        () => {
          snackBar.showSnackBar(t('pages.uemOnboarding.uemValidationError'), 'error');
          resetForm();
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    uemTypeOptions,
    companyOptions,
    fields: fields ?? [],
    authentication,
    setAuthentication,
    authenticationOptions,
    selectedType,
    setSelectedType,
    createUem,
    updateUem,
    uemData,
    editableFields,
    isLoading,
  };
}

export default useUemOnboarding;
