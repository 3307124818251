import { Menu, MenuProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const MenuWrapper = styled(Menu)<MenuProps>(() => ({
  '& .MuiMenu-list': {
    padding: 0,
  },
  '& .MuiPaper-root': {
    minWidth: '23.625rem',
    borderRadius: '0.375rem',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
  },
}));

export default MenuWrapper;
