import { Container, Grid, Typography, useTheme } from '@mui/material';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Button from '../../components/Button/Button';
import useGoBack from '../../hooks/useGoBack/useGoBack';
import UseLogger from '../../hooks/useLogger/useLogger';

function ErrorRoute() {
  const error = useRouteError();
  const { goBack } = useGoBack();
  const theme = useTheme();
  UseLogger().log(error);
  const [t] = useTranslation();

  /**
   * Retrieves the error title to be displayed
   */
  const getErrorTitle = () => {
    if (isRouteErrorResponse(error)) {
      return t(`pages.errors.${error.status}.title`);
    }
    if (isAxiosError(error)) {
      return error.status ? error.status : t(`pages.errors.networkError`);
    }
    return t(`pages.errors.default.title`);
  };

  /**
   * Retrieves the error message to be displayed
   */
  const getErrorDescription = () => {
    if (isRouteErrorResponse(error)) {
      return t(`pages.errors.${error.status}.message`);
    }
    if (isAxiosError(error)) {
      return error.message;
    }
    return t(`pages.errors.default.message`);
  };

  return (
    <Container maxWidth="md" sx={{ display: 'flex', paddingTop: '4rem' }}>
      <Grid container>
        <Grid
          item
          xl={6}
          xs={12}
          sx={{
            display: 'flex',
            borderRight: {
              md: `1px solid ${theme.palette.text.disabled}`,
            },
            padding: '2rem',
            flexShrink: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '4rem',
              textTransform: 'none',
              lineHeight: 'normal',
              fontFamily: 'Vodafone RgBd',
              color: theme.palette.primary.main,
            }}
          >
            {getErrorTitle()}
          </Typography>
        </Grid>
        <Grid
          item
          xl={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem', flexShrink: 1 }}
        >
          <Typography sx={{ fontFamily: 'Vodafone RgBd' }}>{t(`pages.errors.errorMessage`)}</Typography>
          <Typography sx={{ fontFamily: 'Vodafone Lt' }}>{getErrorDescription()}</Typography>
          <Button color="primary" label={t('common.goBack')} onClick={() => goBack()} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ErrorRoute;
