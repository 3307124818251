import { styled } from '@mui/material/styles';

export const AutoCompleteOption = styled('li')(({ theme }) => ({
  fontSize: '1.125rem',
  padding: '1rem !important',
  fontStyle: 'normal',
  color: theme.palette.monochrome.monoChrome6,
  fontWeight: 400,
  lineHeight: '1.125rem',
  backgroundColor: `${theme.palette.background.default} !important`,
  borderTop: `1px solid ${theme.palette.monochrome.monoChrome3}`,
  '&:first-of-type': {
    borderTop: 'none',
  },
  '&:hover': {
    backgroundColor: `${theme.palette.monochrome.monoChrome5} !important`,
  },
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.monochrome.monoChrome3} !important`,
  },
  '&.Mui-focused ': {
    backgroundColor: `${theme.palette.monochrome.monoChrome5} !important`,
  },
  '& .secondaryLabel': {
    color: theme.palette.text.disabled,
  },
}));

export default AutoCompleteOption;
