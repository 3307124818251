import React from 'react';
import { ButtonColor, ButtonColorVariants, ButtonProps } from './ButtonModels';
import ButtonWrapper from './ButtonStyle';

function Button({ label, onClick, color = 'primary', variant, ...rest }: ButtonProps) {
  /**
   * Gets the variant of button
   */
  const getButtonVariant = () => {
    if (variant) {
      return variant;
    }
    if (color in ButtonColorVariants) {
      return ButtonColorVariants[color as ButtonColor];
    }
    return ButtonColorVariants[ButtonColor.default];
  };

  return (
    <ButtonWrapper {...rest} variant={getButtonVariant()} color={color} onClick={onClick} disableRipple>
      {label}
    </ButtonWrapper>
  );
}

export default React.memo(Button);
