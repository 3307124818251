import { Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import Button from '../../../../../components/Button/Button';
import ButtonGroup from '../../../../../components/ButtonGroup/ButtonGroup';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import { DropdownItem } from '../../../../../components/Dropdown/DropdownModels';
import TextInput from '../../../../../components/TextInput/TextInput';
import UserDetailsContext from '../../../../../contexts/UserDetailsContext/UserDetailsContext';
import useHttpError from '../../../../../hooks/useHttpError/useHttpError';
import { Device } from '../../../../../models/UserDevicesModels';
import { User } from '../../../../../models/UsersModels';
import UsersService from '../../../../../services/Users/UsersService';
import { RootState } from '../../../../../stores/Store';
import { updateRequest, setNewDevice } from '../../../../../stores/UpgradeDeviceStore';

interface UpgradeDeviceDevicesStepProps {
  availableDevices: Device[];
  nextStep: () => void;
  onClose: () => void;
  isLoading: boolean;
}
function UpgradeDeviceDevicesStep({ availableDevices, nextStep, onClose, isLoading }: UpgradeDeviceDevicesStepProps) {
  const [t] = useTranslation();
  const { eid, userId } = useSelector((state: RootState) => state.upgradeDeviceReducer.upgradeDeviceRequest);
  const typeOfConnection = useSelector((state: RootState) => state.upgradeDeviceReducer.typeOfConnection);
  const user = useContext(UserDetailsContext);
  const dispatch = useDispatch();
  const [isValidating, setIsValidating] = useState(false);
  const [eSimOwner, setESimOwner] = useState<User | null | undefined>(null);
  const [eSimDevice, setESimDevice] = useState<Device | null | undefined>(null);
  const [showNextStep, setShowNextStep] = useState<boolean>(false);
  const { getHttpReasonFromList } = useHttpError();

  /**
   * Dropdown options - devices list
   */
  const getDevicesOptions = useCallback(() => {
    const array: DropdownItem[] = [];
    availableDevices.forEach((d) => {
      if (d.eid !== null) {
        array.push({ value: d.eid, label: `${d.manufacturer} ${d.model}`, key: d.eid });
      }
    });
    return array;
  }, [availableDevices]);

  const formik = useFormik({
    initialValues: { eid },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: yup.object({
      eid: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(/^[0-9]+$/, t('common.inputValidations.onlyNumbers')),
      /* .max(32, t('common.inputValidations.maxLength', { len: 32 }))
       .min(32, t('common.inputValidations.maxLength', { len: 32 })),
       .test('isValidESimID', t('common.inputValidations.invalidESim'), (value) => {
       const decimalValue = parseInt(value, 16);
       const remainder = decimalValue % 97;
       return remainder === 1;
       }), */
    }),
    onSubmit: () => {
      setIsValidating(true);
      UsersService.api.fetchDeviceByEid({ userId, eid, userBan: user?.billingAccountBan, upgrade: true }).then(
        (response) => {
          setIsValidating(false);
          dispatch(setNewDevice(response.data.device));
          if (response.data.sim.status !== 'FREE') {
            formik.setErrors({
              eid: t('pages.userDetails.upgradeDeviceDrawer.eSimAlreadyInUse'),
            });
          } else {
            setShowNextStep(true);
          }
          setESimDevice(response.data.device);
          setESimOwner(response.data.user);
        },
        (res) => {
          formik.setErrors({
            eid: getHttpReasonFromList(
              res.response.data,
              [3104, 3107],
              t('pages.userDetails.upgradeDeviceDrawer.validateEidError'),
            ),
          });
          setIsValidating(false);
        },
      );
    },
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {typeOfConnection === 'DEVICE' ? (
        <Box sx={{ paddingBottom: '2rem' }}>
          {!isLoading && getDevicesOptions().length > 0 ? (
            <Dropdown
              id="device"
              name="device"
              label={t('pages.userDetails.upgradeDeviceDrawer.selectDevice')}
              value={eid}
              onChange={(e) => {
                dispatch(updateRequest({ eid: e.target.value as string }));
                dispatch(setNewDevice(availableDevices.find((d) => d.eid === (e.target.value as string)) as Device));
              }}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={getDevicesOptions()}
              placeholder={t('pages.userDetails.upgradeDeviceDrawer.selectDevicePlaceholder')}
            />
          ) : (
            <Typography>{t('pages.userDetails.upgradeDeviceDrawer.noAvailableDevices')}</Typography>
          )}
          {eid ? (
            <TextInput
              value={eid}
              onInputChange={() => {}}
              disabled
              label={t('pages.userDetails.upgradeDeviceDrawer.eSimId')}
            />
          ) : null}
        </Box>
      ) : (
        <Box sx={{ paddingBottom: '2rem' }}>
          <form onSubmit={formik.handleSubmit}>
            <TextInput
              id="eid"
              name="eid"
              onInputChange={(e) => {
                setShowNextStep(false);
                setESimDevice(null);
                setESimOwner(null);
                dispatch(updateRequest({ eid: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.eid && Boolean(formik.errors.eid)}
              feedback={formik.touched.eid && Boolean(formik.errors.eid) ? formik.errors.eid : ''}
              value={formik.values.eid}
              placeholder={t('pages.userDetails.upgradeDeviceDrawer.eSimIdPlaceholder')}
              label={t('pages.userDetails.upgradeDeviceDrawer.eSimId')}
              formStyle={{ paddingBottom: '1.5rem' }}
            />
            {eSimDevice ? (
              <TextInput
                id="device"
                name="device"
                onInputChange={() => {}}
                value={`${eSimDevice.manufacturer} ${eSimDevice.model}`}
                placeholder={t('pages.userDetails.upgradeDeviceDrawer.device')}
                label={t('pages.userDetails.upgradeDeviceDrawer.device')}
                disabled
                formStyle={{ paddingBottom: '1.5rem' }}
              />
            ) : null}
            {eSimOwner ? (
              <>
                <TextInput
                  id="user-name"
                  name="user-name"
                  onInputChange={() => {}}
                  value={`${eSimOwner.firstName} ${eSimOwner.lastName}`}
                  placeholder={t('pages.userDetails.upgradeDeviceDrawer.userName')}
                  label={t('pages.userDetails.upgradeDeviceDrawer.userName')}
                  disabled
                  formStyle={{ paddingBottom: '1.5rem' }}
                />

                <TextInput
                  id="email-address"
                  name="email-address"
                  onInputChange={() => {}}
                  value={eSimOwner.email}
                  placeholder={t('pages.userDetails.upgradeDeviceDrawer.emailAddress')}
                  label={t('pages.userDetails.upgradeDeviceDrawer.emailAddress')}
                  disabled
                  formStyle={{ paddingBottom: '1.5rem' }}
                />
              </>
            ) : null}
          </form>
        </Box>
      )}
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          {typeOfConnection === 'DEVICE' ? (
            <Button label={t('common.continue')} color="primaryButton" disabled={!eid} onClick={nextStep} />
          ) : (
            <Box>
              {showNextStep ? (
                <Button
                  label={t('common.continue')}
                  color="primaryButton"
                  onClick={nextStep}
                  disabled={!formik.isValid}
                />
              ) : (
                <Button
                  label={t('common.validate')}
                  color="primaryButton"
                  disabled={!formik.isValid || isValidating}
                  onClick={formik.submitForm}
                />
              )}
            </Box>
          )}
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default UpgradeDeviceDevicesStep;
