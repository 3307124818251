import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import {
  DevicesReportColumnsResponse,
  DevicesReportFilter,
  DevicesReportFiltersResponse,
  DevicesReportRequest,
  DevicesReportResponse,
  DevicesStatusResponse,
} from '../../models/DevicesReportModels';

const DevicesReportService = {
  api: {
    fetchDevicesStatusTypes: (): AxiosPromise<DevicesStatusResponse> => Api.get(`/devices/report/status`),
    fetchDevicesReport: (params: DevicesReportRequest): AxiosPromise<DevicesReportResponse> =>
      Api.post(`/devices/report/list`, params),
    fetchTableColumns: (): AxiosPromise<DevicesReportColumnsResponse> => Api.get(`/devices/report/columns`, {}),
    fetchFilters: (): AxiosPromise<DevicesReportFiltersResponse> => Api.get(`/devices/report/filters`, {}),
    exportCsv: (filter: DevicesReportFilter): AxiosPromise<string> =>
      Api.post(`/devices/report/export`, filter, {
        responseType: 'blob',
      }),
  },
};

export default DevicesReportService;
