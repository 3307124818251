import * as React from 'react';
import { SVGProps, memo } from 'react';

function ESimIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={49} height={48} fill="none" {...props}>
      <g fill="#892988" fillRule="evenodd" clipRule="evenodd">
        <path d="M14.885 7.242c-.606.72-.797 1.492-.797 1.713v29.25c0 1.543.564 2.53 1.238 3.14.697.63 1.542.88 2.107.88h13.905c1.005 0 1.813-.531 2.398-1.26.604-.753.872-1.598.872-2.01v-26.32a.75.75 0 0 0-.228-.538l-6.127-5.93a.748.748 0 0 0-.521-.212h-9.894c-1.46 0-2.382.609-2.953 1.287Zm-1.147-.966c.827-.982 2.155-1.821 4.1-1.821h9.894c.585 0 1.145.227 1.564.633l6.127 5.931a2.25 2.25 0 0 1 .685 1.617v26.319c0 .838-.444 2.003-1.201 2.948-.777.968-1.978 1.822-3.569 1.822H17.433c-.934 0-2.137-.387-3.113-1.268-.998-.902-1.732-2.3-1.732-4.252V8.955c0-.634.357-1.737 1.15-2.679Z" />
        <path d="M18.583 22.5a2.25 2.25 0 0 1 2.25-2.25h7a2.25 2.25 0 0 1 2.25 2.25v13a2.25 2.25 0 0 1-2.25 2.25h-7a2.25 2.25 0 0 1-2.25-2.25v-13Zm2.25-.75a.75.75 0 0 0-.75.75v13c0 .414.336.75.75.75h7a.75.75 0 0 0 .75-.75v-13a.75.75 0 0 0-.75-.75h-7Z" />
      </g>
    </svg>
  );
}
export default memo(ESimIcon);
