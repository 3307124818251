import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Row } from '../../components/Table/TableModels';
import ClickableLink from '../../components/Text/ClickableLink';
import useHttpError from '../../hooks/useHttpError/useHttpError';
import useRolePermissions from '../../hooks/useRolePermissions/useRolePermissions';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { Admin, AdminsListRequest } from '../../models/AdminOnboardingModels';
import { Sort } from '../../models/CommonModels';
import AdminOnboardingService from '../../services/AdminOnboarding/AdminOnboardingService';
import { AdminFilter } from './AdminsTableFilters';
import RemoveAdminIcon from './components/RemoveAdminIcon';

function useAdminsTable(activePage: number, pageSize: number, filter: AdminFilter, sort: Sort) {
  const [t] = useTranslation();
  const [totalAdmins, setTotalAdmins] = useState(0);
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();
  const { getHttpReason } = useHttpError();
  const { isUserAllowed } = useRolePermissions();

  /**
   * Fetches the list of admins
   */
  const fetchAdmins = () => {
    const requestParams: AdminsListRequest = {
      page: activePage - 1,
      pageSize,
      sort: sort.orderBy,
      sortingType: sort.order,
      ...filter,
    };
    AdminOnboardingService.api.fetchAdmins(requestParams).then(
      (response) => {
        setAdmins(response.data.administrators);
        setTotalAdmins(response.data.totalElements);
        setIsLoading(false);
      },
      () => {
        snackBar.showSnackBar(t('pages.adminOnboarding.fetchAdminsError'), 'error');
        setIsLoading(false);
      },
    );
  };

  /**
   * Delete admin
   * @param id
   */
  const deleteAdmin = (id: string) => {
    AdminOnboardingService.api.deleteAdmin(id).then(
      () => {
        fetchAdmins();
      },
      (res) => {
        const error = getHttpReason(res, t('pages.adminOnboarding.deleteAdminsError'));
        snackBar.showSnackBar(error, 'error');
        setIsLoading(false);
      },
    );
  };

  React.useEffect(() => {
    setIsLoading(true);
    fetchAdmins();
  }, [activePage, pageSize, filter, sort]);

  const headers: Header[] = useMemo(
    () => [
      {
        id: 'lastName',
        label: t('pages.adminOnboarding.table.headers.lastName'),
        isSortable: true,
      },

      {
        id: 'firstName',
        label: t('pages.adminOnboarding.table.headers.firstName'),
        isSortable: true,
      },
      {
        id: 'email',
        label: t('pages.adminOnboarding.table.headers.emailAddress'),
        isSortable: true,
      },
      {
        id: 'role',
        label: t('pages.adminOnboarding.table.headers.role'),
        isSortable: true,
      },
      {
        id: 'company',
        label: t('pages.adminOnboarding.table.headers.company'),
        isSortable: true,
      },
      {
        id: 'editInfo',
        label: '',
      },
      {
        id: 'deleteAdmin',
        label: '',
      },
    ],
    [t],
  );

  const rows: Row[] = useMemo(
    () =>
      admins?.map((admin) => ({
        id: admin.id,
        cells: [
          {
            value: admin.lastName,
          },
          {
            value: admin.firstName,
          },
          {
            value: admin.email,
          },
          {
            value: t(`translation:pages.adminOnboarding.roles.${admin.role}`),
          },
          {
            value: admin.company.name,
          },
          {
            value: (
              <Box>
                {isUserAllowed([
                  'VDF_SUPER_ADMIN',
                  'ENT_SUPER_ADMIN',
                  'ENT_SUPER_ORG_ADMIN',
                  'VDF_FULL_ACCESS_ADMIN',
                ]) ? (
                  <ClickableLink to={`/admins/edit/${admin.id}`}>{t('common.table.editInfo')}</ClickableLink>
                ) : null}
              </Box>
            ),
          },
          {
            value: (
              <Box sx={{ display: 'flex', minWidth: '25px', justifyContent: 'end' }}>
                {isUserAllowed([
                  'VDF_SUPER_ADMIN',
                  'ENT_SUPER_ADMIN',
                  'ENT_SUPER_ORG_ADMIN',
                  'VDF_FULL_ACCESS_ADMIN',
                ]) ? (
                  <RemoveAdminIcon id={admin.id} deleteAdmin={deleteAdmin} />
                ) : null}
              </Box>
            ),
          },
        ],
      })),
    [t, admins],
  );

  return { headers, rows, totalAdmins, isLoading };
}

export default useAdminsTable;
