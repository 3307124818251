import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalContainer = styled(Box)(() => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  width: '32.25rem',
  padding: '2.5rem 2.5rem 2rem 2.5rem',
  boxShadow: '0px 7.5px 15px 0px rgba(0, 0, 0, 0.15)',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  '&:focus-visible': {
    outline: 'none',
  },
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '1.75rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: theme.palette.text.disabled,
}));

export const ModalDescription = styled(Typography)(() => ({
  paddingTop: '1.5rem',
  paddingBottom: '2.5rem',
  textAlign: 'center',
  fontSize: '1.125rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
}));
