import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Row } from '../../components/Table/TableModels';
import useDate from '../../hooks/useDate/useDate';
import useLogger from '../../hooks/useLogger/useLogger';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { AuditTrail, AuditTrailFilter, AuditTrailRequest } from '../../models/AuditTrailModels';
import { Sort } from '../../models/CommonModels';
import { BillingAccount } from '../../models/CompanyOnboardingModels';
import AuditTrailService from '../../services/AuditTrail/AuditTrailService';

function useAuditTrailTable(activePage: number, pageSize: number, filter: AuditTrailFilter, sort: Sort) {
  const [t] = useTranslation();
  const { localeDateTimeFormat } = useDate();
  const [totalItems, setTotalUems] = useState(0);
  const [auditTrails, setAuditTrails] = useState<AuditTrail[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const logger = useLogger();

  const snackBar = useSnackBar();
  const [tableHeaders, setTableHeaders] = useState([
    'creationDate',
    'eventType',
    'eventSubType',
    'status',
    'adminFirstName',
    'adminLastName',
    'adminEmail',
    'companyName',
    'country',
    'ban',
    'deviceSource',
    'endUserFirstName',
    'endUserLastName',
    'endUserEmail',
    'endAdminEmail',
    'endAdminRole',
    'tariffPlan',
    'deviceType',
    'deviceManufacturer',
    'deviceModel',
    'deviceSerialNumber',
    'eid',
    'uemDeviceId',
    'uemSystem',
    'operationType',
  ]);

  /**
   * Fetch audit trails
   */
  const fetchAuditTrail = () => {
    setIsLoading(true);
    const requestParams: AuditTrailRequest = {
      page: activePage - 1,
      pageSize,
      sort: sort.orderBy,
      sortingType: sort.order,
      ...filter,
    };
    if (!requestParams.eventType) {
      delete requestParams.eventType;
    }
    AuditTrailService.api.fetchAuditTrail(requestParams).then(
      (response) => {
        setAuditTrails(response.data.auditTrailList);
        setTotalUems(response.data.totalElements);
        setIsLoading(false);
      },
      () => {
        snackBar.showSnackBar(t('pages.auditTrail.fetchAuditTrailError'), 'error');
        setIsLoading(false);
      },
    );
  };

  /**
   * Exports audit trails
   */
  const exportCsv = () => {
    setIsLoading(true);
    const requestParams: AuditTrailRequest = {
      page: activePage - 1,
      pageSize,
      sort: sort.orderBy,
      sortingType: sort.order,
      ...filter,
    };
    if (!filter.eventType) {
      delete requestParams.eventType;
    }
    AuditTrailService.api.exportCsv(requestParams).then(
      (res) => {
        setIsLoading(false);
        fileDownload(
          res.data,
          `audit_trail_${DateTime.now().toFormat(localeDateTimeFormat)}_${DateTime.now().toFormat('ZZZZ')}${
            requestParams.eventType ? `_${requestParams.eventType.toLowerCase()}` : ''
          }.csv`,
        );
      },
      () => {
        setIsLoading(false);
        snackBar.showSnackBar(t('pages.auditTrail.exportCsvError'), 'error');
      },
    );
  };

  React.useEffect(() => {
    AuditTrailService.api.fetchTableColumns().then(
      (response) => {
        setTableHeaders(response.data.columns);
      },
      () => {
        logger.logError('AuditTrail: Failed to get the list of columns');
      },
    );
  }, []);

  React.useEffect(() => {
    fetchAuditTrail();
  }, [activePage, pageSize, filter, sort]);

  /**
   * Render the value of each cell based on type of field
   * @param auditTrail
   * @param item
   */
  const renderCellValue = (auditTrail: AuditTrail, item: keyof AuditTrail) => {
    if (auditTrail[item]) {
      switch (item) {
        case 'creationDate':
          return DateTime.fromISO(auditTrail[item]).toFormat(`${localeDateTimeFormat} (z)`);
        case 'eventType':
          return t(`pages.auditTrail.eventTypes.${auditTrail[item]}`);
        case 'status':
          return t(`common.status.${auditTrail[item]}`);
        case 'eventSubType':
          return t(`pages.auditTrail.eventSubTypes.${auditTrail[item]}`);
        case 'uemSystem':
          return t(`pages.uemOnboarding.uemTypes.${auditTrail[item]}`);
        case 'operationType':
          return t(`common.operationTypes.${auditTrail[item]}`);
        case 'bans':
          return auditTrail[item]?.length
            ? auditTrail[item]?.map((ban: BillingAccount) => ban.name).join(', ')
            : t('common.na');
        default:
          return auditTrail[item];
      }
    }
    return t('common.na');
  };

  const headers: Header[] = useMemo(
    () =>
      tableHeaders.map((item) => ({
        id: item,
        label: t(`pages.auditTrail.table.headers.${item}`),
        isSortable: true,
        sx: { minWidth: '12rem' },
      })),
    [t, tableHeaders],
  );

  const rows: Row[] = useMemo(
    () =>
      auditTrails?.map((auditTrail) => ({
        id: auditTrail.id,
        cells: tableHeaders.map((item) => ({
          value: `${renderCellValue(auditTrail, item as keyof AuditTrail)}`,
        })),
      })),
    [t, auditTrails, tableHeaders],
  );

  return { headers, rows, totalItems, isLoading, exportCsv };
}

export default useAuditTrailTable;
