import { Box, RadioGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import Radio from '../../../../components/Radio/Radio';
import { updateUserSelectionType } from '../../../../stores/NewConnectionStore';
import { RootState } from '../../../../stores/Store';

interface NewConnectionUserSelectionTypeStepProps {
  nextStep: (next?: number) => void;
  onClose: () => void;
}
function NewConnectionUserSelectionTypeStep({ nextStep, onClose }: NewConnectionUserSelectionTypeStepProps) {
  const [t] = useTranslation();
  const userSelectionType = useSelector((state: RootState) => state.newConnectionReducer.userSelectionType);
  const dispatch = useDispatch();

  /**
   * Handles change of the radio button
   * @param e
   */
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateUserSelectionType(e.target.value as 'USE_EXISTING' | 'CREATE'));
  };

  /**
   * Navigates to the according next step
   */
  const navigateNextStep = () => {
    if (userSelectionType === 'USE_EXISTING') {
      nextStep(4);
    } else {
      nextStep();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ paddingBottom: '2rem' }}>
        <RadioGroup
          aria-labelledby="create-user-radio"
          name="create-user-radio"
          value={userSelectionType}
          onChange={handleRadioChange}
        >
          <Radio value="USE_EXISTING" label={t('pages.eSimManager.newConnectionDrawer.selectExistingUser')} />
          <Radio value="CREATE" label={t('pages.eSimManager.newConnectionDrawer.createNewUser')} />
        </RadioGroup>
      </Box>
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          <Button
            label={t('common.continue')}
            color="primaryButton"
            disabled={!userSelectionType}
            onClick={navigateNextStep}
          />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default NewConnectionUserSelectionTypeStep;
