import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import { PlanOptionsResponse } from '../../models/CompanyOnboardingModels';
import { AvailableMarketsResponse, AvailableNotificationsResponse, TariffsResponse } from '../../models/MarketsModels';

const MarketsService = {
  api: {
    fetchMarketsNotifications: (ban: string): AxiosPromise<AvailableNotificationsResponse> =>
      Api.post(`/markets/notifications/available/${ban}`, {}),
    fetchMarkets: (): AxiosPromise<AvailableMarketsResponse> => Api.get(`/markets/available`, {}),
    fetchTariffs: (ban: string): AxiosPromise<TariffsResponse> => Api.get(`/markets/tariff/by-ban/${ban}`, {}),
    fetchPlanOptions: (): AxiosPromise<PlanOptionsResponse> => Api.get(`/markets/plan-options`),
  },
};

export default MarketsService;
