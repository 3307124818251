import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';

interface NewConnectionUserAlreadyExistsStepProps {
  nextStep: (step: number) => void;
  onClose: () => void;
  prevStep: () => void;
}
function NewConnectionUserAlreadyExistsStep({ nextStep, onClose, prevStep }: NewConnectionUserAlreadyExistsStepProps) {
  const [t] = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ paddingBottom: '2rem' }}>
        <Typography
          sx={{ paddingBottom: '1.5rem', fontSize: '1.375rem', color: (theme) => theme.palette.text.disabled }}
        >
          {t('pages.eSimManager.newConnectionDrawer.emailAlreadyRegistered')}
        </Typography>
        <Typography sx={{ paddingBottom: '1.5rem' }}>
          {t('pages.eSimManager.newConnectionDrawer.emailAlreadyRegisteredMessage')}
        </Typography>
        <Typography sx={{ paddingBottom: '1.5rem' }}>
          {t('pages.eSimManager.newConnectionDrawer.proceedAnyway')}
        </Typography>
      </Box>
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          <Button label={t('common.goBack')} color="secondaryButton" onClick={prevStep} />
          <Button label={t('common.proceed')} color="primaryButton" onClick={() => nextStep(5)} />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default NewConnectionUserAlreadyExistsStep;
