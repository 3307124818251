import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DevicesReportCompany, DevicesReportFilter } from '../models/DevicesReportModels';

interface DevicesReportStateData {
  filter: DevicesReportFilter;
  companies: DevicesReportCompany[];
}

const initialState: DevicesReportStateData = {
  filter: { status: '' },
  companies: [],
};

export const DevicesReportSlice = createSlice({
  name: 'newConnection',
  initialState,
  reducers: {
    reset: () => initialState,
    resetFilter: (state) => {
      state.filter = { ...initialState.filter, status: state.filter.status };
    },
    setFilter: (state, action: PayloadAction<DevicesReportFilter>) => {
      state.filter = action.payload;
    },
    removeFilter: (state, action: PayloadAction<keyof DevicesReportFilter>) => {
      delete state.filter[action.payload];
    },
    setCompanyList: (state, action: PayloadAction<DevicesReportCompany[]>) => {
      state.companies = action.payload;
    },
  },
});

export const { reset, resetFilter, setFilter, removeFilter, setCompanyList } = DevicesReportSlice.actions;

export default DevicesReportSlice.reducer;
