import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useHttpError from '../../../../hooks/useHttpError/useHttpError';
import useModal from '../../../../hooks/useModal/useModal';
import useSnackBar from '../../../../hooks/useSnackBar/useSnackBar';
import { NotificationSetting } from '../../../../models/CompanyOnboardingModels';
import { AvailableNotificationsResponse, Tariff } from '../../../../models/MarketsModels';
import { Device } from '../../../../models/UserDevicesModels';
import CompanyOnboardingService from '../../../../services/CompanyOnboarding/CompanyOnboardingService';
import MarketsService from '../../../../services/Markets/MarketsService';
import UsersService from '../../../../services/Users/UsersService';
import { setNotificationSettings, updateRequest } from '../../../../stores/NewConnectionStore';
import { RootState } from '../../../../stores/Store';
import { User } from '../../../../models/UsersModels';

function useNewConnection(onClose: () => void, onNewConnectionSuccess: () => void) {
  const [availableNotifications, setAvailableNotifications] = useState<AvailableNotificationsResponse>();
  const [availableTariffs, setAvailableTariffs] = useState<Tariff[]>([]);
  const [availableDevices, setAvailableDevices] = useState<Device[]>([]);
  const {
    request,
    typeOfConnection,
    selectedDropdownUser,
    userSelectionType,
    userAlreadyExists,
    newUser,
    device,
    qrCodeNotificationSettings,
  } = useSelector((state: RootState) => state.newConnectionReducer);
  const [isLoading, setIsLoading] = useState(false);
  const modal = useModal();
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  const { getHttpReason, getHttpReasonFromList } = useHttpError();
  const dispatch = useDispatch();

  /**
   * Requests the available devices
   * @param
   */
  const getAvailableDevices = () => {
    setIsLoading(true);
    UsersService.api
      .fetchFreeUserDevices(request.userId)
      .then(
        (res) => {
          setAvailableDevices(res.data.devices);
        },
        () => {
          snackBar.showSnackBar(t('pages.userDetails.upgradeDeviceDrawer.devicesAvailableError'), 'error');
        },
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * Returns a promise for the user creation
   */
  const createUser = async () =>
    new Promise<string>((resolve, reject) => {
      if (userSelectionType === 'CREATE' && !userAlreadyExists && newUser) {
        UsersService.api.createUser(newUser).then(
          (res) => {
            dispatch(updateRequest({ userId: res.data.id }));
            resolve(res.data.id);
          },
          () => {
            snackBar.showSnackBar(t('pages.eSimManager.newConnectionDrawer.createUserError'), 'error');
            reject();
          },
        );
      } else {
        resolve(request.userId);
      }
    });

  /**
   * Perform the request for a new connection
   */
  const newConnection = () => {
    setIsLoading(true);
    createUser().then((userId: string) => {
      UsersService.api
        .newConnection({ ...request, userId })
        .then(
          () => {
            modal.showModal({
              modalTitle: t('pages.eSimManager.newConnectionDrawer.newConnectionSuccessTitle'),
              modalDescription: t('pages.eSimManager.newConnectionDrawer.newConnectionSuccessMessage'),
              onAccept: (setOpen) => {
                setOpen(false);
                onNewConnectionSuccess();
              },
            });
            onClose();
          },
          (res) => {
            const error = getHttpReasonFromList(
              res.response.data,
              [2068, 2072],
              t('pages.eSimManager.newConnectionDrawer.newConnectionError'),
            );
            snackBar.showSnackBar(`${error}.`, 'error');
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  /**
   * Requests the available notifications types for the market of the ban
   * @param ban
   */
  const getAvailableNotifications = async (ban: string) =>
    new Promise((resolve, reject) => {
      MarketsService.api.fetchMarketsNotifications(ban).then(
        (res) => {
          setAvailableNotifications(res.data);
          resolve(res.data);
        },
        (res) => {
          const error = getHttpReason(
            res.response.data,
            t('pages.userDetails.upgradeDeviceDrawer.notificationsAvailableDefaultError'),
            t('pages.userDetails.upgradeDeviceDrawer.notificationsAvailableError'),
          );
          snackBar.showSnackBar(`${error}.`, 'error');
          reject(res);
        },
      );
    });

  /**
   * Fetches the list of available tariffs for the user's ban
   * @param ban
   */
  const getAvailableTariffs = async (ban: string) =>
    new Promise((resolve, reject) => {
      MarketsService.api.fetchTariffs(ban).then(
        (res) => {
          setAvailableTariffs(res.data.tariffs);
          resolve(res.data.tariffs);
        },
        (res) => {
          const error = getHttpReason(
            res.response.data,
            t('pages.eSimManager.newConnectionDrawer.fetchTariffsDefaultError'),
            t('pages.eSimManager.newConnectionDrawer.fetchTariffsError'),
          );
          snackBar.showSnackBar(`${error}.`, 'error');
          reject(res);
        },
      );
    });

  /**
   * Retrieves only the types of notification that are enabled
   * @param notifications
   */
  const filterEnabledQrCodeNotifications = (notifications: NotificationSetting[]): NotificationSetting[] =>
    notifications.filter((notification) => notification.enabled);

  /**
   * Fetches the list of notification settings of the company
   * @param companyId
   */
  const getNotificationSettings = (companyId: string) => {
    CompanyOnboardingService.api.fetchCompanyNotificationSettings(companyId).then(
      (res) => {
        dispatch(setNotificationSettings(filterEnabledQrCodeNotifications(res.data.qrCodeNotifications)));
      },
      () => {
        snackBar.showSnackBar(
          t('pages.eSimManager.newConnectionDrawer.fetchCompanyNotificationsDefaultError'),
          'error',
        );
      },
    );
  };

  /**
   * On BAN selection handler
   * @param ban
   */
  const onBanSelected = async (ban: string) => {
    await getAvailableNotifications(ban);
    await getAvailableTariffs(ban);
  };

  useEffect(() => {
    if (selectedDropdownUser.data) {
      onBanSelected((selectedDropdownUser.data as User).billingAccountBan).then();
    }
  }, [selectedDropdownUser.data]);

  useEffect(() => {
    if (typeOfConnection === 'DEVICE' && request.userId) {
      getAvailableDevices();
    }
  }, [typeOfConnection, request.userId]);

  useEffect(() => {
    if (device?.companyId && !qrCodeNotificationSettings.length) {
      getNotificationSettings(device.companyId);
    }
  }, [device]);

  return {
    onBanSelected,
    availableNotifications,
    availableDevices,
    availableTariffs,
    isLoading,
    newConnection,
  };
}

export default useNewConnection;
