/**
 * Pagination properties for the pagination component
 */
export interface TablePaginationProps {
  handlePageChange: (pageNumber: number, pageSize: number) => void;
  totalItems: number;
  activePage: number;
  pageSize: number;
}

/**
 * Pagination properties for the useTablePagination hook
 * Removes the handlePageChange field
 */
export type TablePaginationHookProps = Omit<TablePaginationProps, 'handlePageChange'>;

/**
 * Ellipsis format
 * To represent the unseen pages of the pagination
 */
export const ELLIPSIS = '...';
