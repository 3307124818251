import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../components/Dropdown/Dropdown';
import SearchInput from '../../components/SearchInput/SearchInput';
import useAdminsTableFilters from './useAdminsTableFilters';

interface AdminsTableFiltersProps {
  onSearch: (filter: AdminFilter) => void;
  isLoading: boolean;
}

export interface AdminFilter {
  filter: string;
  companyId: string;
}

function AdminsTableFilters({ onSearch, isLoading = false }: AdminsTableFiltersProps) {
  const [t] = useTranslation();
  const [adminFilter, setAdminFilter] = useState<AdminFilter>({ companyId: '', filter: '' });
  const { companyOptions } = useAdminsTableFilters();
  /**
   * Search handler
   * @param filter
   * @param companyId
   */
  const search = (filter: string, companyId: string) => {
    onSearch({ filter, companyId });
    setAdminFilter({ filter, companyId });
  };

  useEffect(() => {
    if (companyOptions && companyOptions.length === 1) {
      search(adminFilter.filter, companyOptions[0].value as string);
    }
  }, [companyOptions]);

  return (
    <Grid container columnSpacing="1.5rem" rowSpacing="1.5rem" sx={{ paddingTop: '1.5rem', paddingBottom: '1rem' }}>
      <Grid item xl={5} md={6} xs={12}>
        <SearchInput
          label={t('pages.adminOnboarding.table.filters.search')}
          onSearch={(q) => {
            search(q, adminFilter.companyId);
          }}
          placeholder={t('pages.adminOnboarding.table.filters.searchPlaceholder')}
          isButtonDisabled={isLoading}
        />
      </Grid>
      <Grid item xl={3} md={6} xs={12}>
        <Dropdown
          id="companyId"
          name="companyId"
          label={t('pages.adminOnboarding.table.filters.company')}
          value={adminFilter.companyId}
          onChange={(c) => {
            search(adminFilter.filter, c.target.value as string);
          }}
          formStyle={{ paddingBottom: '1.5rem' }}
          items={companyOptions}
          placeholder={
            t('common.placeholders.dropdownPlaceholder') + t('pages.adminOnboarding.table.filters.companyPlaceholder')
          }
        />
      </Grid>
    </Grid>
  );
}

export default AdminsTableFilters;
