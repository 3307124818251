import { styled } from '@mui/material/styles';
import Button from '../Button/Button';
import { ButtonProps } from '../Button/ButtonModels';

export const ArrowLeft = styled('div')(() => ({
  '&::before': {
    content: '"⟨"',
  },
}));

export const ArrowRight = styled('div')(() => ({
  '&::before': {
    content: '"⟩"',
  },
}));

export const Ellipsis = styled('div')(() => ({
  '&::before': {
    content: '"..."',
  },
}));

export const TablePaginationItem = styled((props: ButtonProps) => <Button color="alternative1Button" {...props} />)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 2.5rem !important;
  max-height: 2.5rem !important;
  border-radius: 0;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.29375rem;
  padding: 0;
  border: 2px solid ${(props) => props.theme.palette.monochrome.monoChrome9};
  &.arrow {
    font-size: 1.5rem;
    border: none;
    background-color: transparent;
  }
  &.active {
    background-color: ${(props) => props.theme.palette.monochrome.monoChrome9};
    color: #fff;
  }
`;
