import { ApiResponse } from './ServiceModels';

/**
 * Authentication request model
 */
interface AuthResponse extends ApiResponse {
  accessToken: 'string';
  refreshToken: 'string';
}

/**
 * Logout response model
 */
export interface LogoutResponse extends ApiResponse {
  url: string;
}

/**
 * Types of user roles
 * Each user role can have different set of permissions
 */
export type UserRole =
  | 'OPCO_USER'
  | 'VDF_ADMIN'
  | 'VDF_SUPER_ADMIN'
  | 'VDF_FULL_ACCESS_ADMIN'
  | 'ENT_ADMIN'
  | 'ENT_SUPER_ADMIN'
  | 'ENT_ORG_ADMIN'
  | 'ENT_SUPER_ORG_ADMIN';

/**
 * Decoded data model of the JWT resulted of the authentication request
 */
export interface UserData {
  iss: string;
  iat: number;
  exp: number;
  sub: string;
  role: UserRole;
  firstName: string;
  lastName: string;
}

type FeaturePermissionsProps = {
  dashboard: UserRole[];
  auditTrail: UserRole[];
  devicesReport: UserRole[];
  eSimManager: UserRole[];
  uemList: UserRole[];
  uemCreate: UserRole[];
  companiesList: UserRole[];
  companiesCreate: UserRole[];
  companiesEdit: UserRole[];
  adminsList: UserRole[];
  adminsCreate: UserRole[];
  import: UserRole[];
  newConnection: UserRole[];
  userDevicesActions: UserRole[];
  editDevices: UserRole[];
};
/**
 * Object that describes the role permissions for each route
 */
export const FeaturePermissions: FeaturePermissionsProps = {
  dashboard: [
    'OPCO_USER',
    'VDF_ADMIN',
    'VDF_SUPER_ADMIN',
    'ENT_ADMIN',
    'ENT_SUPER_ADMIN',
    'ENT_ORG_ADMIN',
    'ENT_SUPER_ORG_ADMIN',
    'VDF_FULL_ACCESS_ADMIN',
  ],
  auditTrail: [
    'OPCO_USER',
    'VDF_ADMIN',
    'VDF_SUPER_ADMIN',
    'ENT_ADMIN',
    'ENT_SUPER_ADMIN',
    'ENT_SUPER_ORG_ADMIN',
    'VDF_FULL_ACCESS_ADMIN',
  ],
  devicesReport: [
    'OPCO_USER',
    'VDF_ADMIN',
    'VDF_SUPER_ADMIN',
    'ENT_ADMIN',
    'ENT_SUPER_ADMIN',
    'ENT_ORG_ADMIN',
    'ENT_SUPER_ORG_ADMIN',
    'VDF_FULL_ACCESS_ADMIN',
  ],
  eSimManager: [
    'OPCO_USER',
    'VDF_ADMIN',
    'VDF_SUPER_ADMIN',
    'ENT_ADMIN',
    'ENT_SUPER_ADMIN',
    'ENT_ORG_ADMIN',
    'ENT_SUPER_ORG_ADMIN',
    'VDF_FULL_ACCESS_ADMIN',
  ],
  uemList: [
    'OPCO_USER',
    'VDF_ADMIN',
    'VDF_SUPER_ADMIN',
    'ENT_ORG_ADMIN',
    'ENT_SUPER_ORG_ADMIN',
    'VDF_FULL_ACCESS_ADMIN',
  ],
  uemCreate: ['ENT_ORG_ADMIN', 'ENT_SUPER_ORG_ADMIN', 'VDF_FULL_ACCESS_ADMIN'],
  companiesList: [
    'ENT_ADMIN',
    'ENT_SUPER_ADMIN',
    'ENT_ORG_ADMIN',
    'ENT_SUPER_ORG_ADMIN',
    'OPCO_USER',
    'VDF_ADMIN',
    'VDF_SUPER_ADMIN',
    'VDF_FULL_ACCESS_ADMIN',
  ],
  companiesCreate: ['VDF_SUPER_ADMIN', 'VDF_FULL_ACCESS_ADMIN'],
  companiesEdit: ['VDF_SUPER_ADMIN', 'VDF_FULL_ACCESS_ADMIN'],
  adminsList: [
    'OPCO_USER',
    'VDF_ADMIN',
    'VDF_SUPER_ADMIN',
    'ENT_ADMIN',
    'ENT_SUPER_ADMIN',
    'ENT_ORG_ADMIN',
    'ENT_SUPER_ORG_ADMIN',
    'VDF_FULL_ACCESS_ADMIN',
  ],
  adminsCreate: ['VDF_SUPER_ADMIN', 'ENT_SUPER_ADMIN', 'ENT_SUPER_ORG_ADMIN', 'VDF_FULL_ACCESS_ADMIN'],
  import: [
    'VDF_ADMIN',
    'VDF_SUPER_ADMIN',
    'ENT_ADMIN',
    'ENT_SUPER_ADMIN',
    'ENT_SUPER_ORG_ADMIN',
    'VDF_FULL_ACCESS_ADMIN',
  ],
  newConnection: ['ENT_ADMIN', 'ENT_SUPER_ADMIN', 'ENT_SUPER_ORG_ADMIN', 'VDF_FULL_ACCESS_ADMIN'],
  userDevicesActions: ['ENT_ADMIN', 'ENT_SUPER_ADMIN', 'ENT_SUPER_ORG_ADMIN', 'VDF_FULL_ACCESS_ADMIN'],
  editDevices: [
    'VDF_ADMIN',
    'VDF_SUPER_ADMIN',
    'ENT_ADMIN',
    'ENT_SUPER_ADMIN',
    'ENT_SUPER_ORG_ADMIN',
    'VDF_FULL_ACCESS_ADMIN',
  ],
};

export default AuthResponse;
