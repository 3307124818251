import { UserRole } from '../../models/AuthModels';
import useAuth from '../useAuth/useAuth';

function useRolePermissions() {
  const { userData } = useAuth();
  /**
   * Checks if the user has role permissions for a certain kind of action/render
   * @param roles
   */
  const isUserAllowed = (roles: UserRole[]) => !!(userData && roles.includes(userData.role));
  return { isUserAllowed };
}

export default useRolePermissions;
