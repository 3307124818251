import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import DashboardService from '../../../services/Dashboard/DashboardService';

function useDashboardCards() {
  const [usersCounter, setUsersCounter] = useState<number | undefined>();
  const [devicesCounter, setDevicesCounter] = useState<number | undefined>();
  const [eSIMCounter, setESIMCounterCounter] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();
  const [t] = useTranslation();

  React.useEffect(() => {
    /**
     * Fetches dashboard data
     */
    const fetchData = async () => {
      setIsLoading(true);
      const usersCounterResp = await DashboardService.api.getUsersCounter();
      setUsersCounter(usersCounterResp.data.numberOfUsers);
      const devicesCounterResp = await DashboardService.api.getDevicesCounter();
      setDevicesCounter(devicesCounterResp.data.numberOfActiveDevices);
      setESIMCounterCounter(devicesCounterResp.data.numberOfActiveSims);
      setIsLoading(false);
    };
    fetchData().catch(() => {
      snackBar.showSnackBar(t('translation:pages.dashboard.cards.failedFetch'), 'error');
      setIsLoading(false);
    });
  }, []);

  return { usersCounter, devicesCounter, eSIMCounter, isLoading };
}

export default useDashboardCards;
