import { Container } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import TopBanner from '../../components/TopBanner/TopBanner';

type PublicRouteProps = PropsWithChildren;
function PublicRoute({ children }: PublicRouteProps) {
  return (
    <>
      <ScrollRestoration />
      <Navbar showNavItems={false} />
      <TopBanner />
      <Container maxWidth="xl" sx={{ marginTop: '2.5rem', minHeight: '70vh' }}>
        {children}
      </Container>
      <Footer />
    </>
  );
}

export default PublicRoute;
