import { Box, Typography } from '@mui/material';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table/Table';
import { Sort } from '../../../../models/CommonModels';
import { GetDeviceByIdFn, GetDeviceFn, Sim } from '../../../../models/UserDevicesModels';
import useUserDetailsSimsTable from './useUserDetailsSimsTable';

interface UserDetailsSimsTableProps {
  sims: Sim[] | [];
  isLoading: boolean;
  getDeviceById: GetDeviceByIdFn | GetDeviceFn;
  isPerformingAction: boolean;
  resendNotification: (deviceId: string) => void;
  onNewConnectionSuccess: () => void;
  onUpgradeSuccess: () => void;
  showTotalSims?: boolean;
}

function UserDetailsSimsTable({
  sims,
  isLoading,
  getDeviceById,
  isPerformingAction,
  resendNotification,
  onNewConnectionSuccess,
  onUpgradeSuccess,
  showTotalSims = true,
}: UserDetailsSimsTableProps) {
  const [t] = useTranslation();
  const [sort, setSort] = useState<Sort>({ order: 'asc', orderBy: 'eid' });
  const { headers, rows, totalSims } = useUserDetailsSimsTable(
    sims,
    sort,
    resendNotification,
    isPerformingAction,
    getDeviceById,
    onNewConnectionSuccess,
    onUpgradeSuccess,
  );
  /**
   * Handle change of the table sort
   * @param s
   */
  const handleOnSort = (s: Sort) => {
    setSort(s);
  };

  return (
    <Box paddingBottom="3rem">
      {showTotalSims ? (
        <Typography variant="body1" paddingBottom="1rem">
          {t('common.table.totalItems', { count: totalSims })}
        </Typography>
      ) : null}
      <Table
        headers={headers}
        rows={rows}
        isLoading={isLoading}
        defaultOrderBy={sort.orderBy}
        onSort={handleOnSort}
        emptyStateMessage={t('pages.userDetails.tabs.simsTable.noResults')}
      />
    </Box>
  );
}

export default memo(UserDetailsSimsTable);
