import { Box, Typography } from '@mui/material';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table/Table';
import { Sort } from '../../../../models/CommonModels';
import { Device } from '../../../../models/UserDevicesModels';
import useUserDetailsDevicesTable from './useUserDetailsDevicesTable';

interface UserDetailsDevicesTableProps {
  devices: Device[];
  isLoading: boolean;
  isPerformingAction: boolean;
  resendNotification: (deviceId: string) => void;
}
function UserDetailsDevicesTable({
  devices,
  isLoading,
  isPerformingAction,
  resendNotification,
}: UserDetailsDevicesTableProps) {
  const [t] = useTranslation();
  const [sort, setSort] = useState<Sort>({ order: 'asc', orderBy: 'model' });
  const { headers, rows, totalDevices } = useUserDetailsDevicesTable(
    devices,
    sort,
    resendNotification,
    isPerformingAction,
  );
  /**
   * Handle change of the table sort
   * @param s
   */
  const handleOnSort = (s: Sort) => {
    setSort(s);
  };

  return (
    <Box paddingBottom="3rem">
      <Typography variant="body1" paddingBottom="1rem">
        {t('common.table.totalItems', { count: totalDevices })}
      </Typography>
      <Table
        headers={headers}
        rows={rows}
        isLoading={isLoading}
        defaultOrderBy={sort.orderBy}
        onSort={handleOnSort}
        emptyStateMessage={t('pages.userDetails.tabs.devicesTable.noResults')}
      />
    </Box>
  );
}

export default memo(UserDetailsDevicesTable);
