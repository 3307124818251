import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Table from '../../components/Table/Table';
import TablePagination from '../../components/TablePagination/TablePagination';
import { Sort } from '../../models/CommonModels';
import { RootState } from '../../stores/Store';
import AuditTrailFilters from './AuditTrailFilters';
import useAuditTrailTable from './useAuditTrailTable';

function AuditTrailTable() {
  const [t] = useTranslation();
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState<Sort>({ order: 'asc', orderBy: 'creationDate' });
  const filter = useSelector((state: RootState) => state.auditTrailReducer.filter);
  const { headers, rows, totalItems, isLoading, exportCsv } = useAuditTrailTable(activePage, pageSize, filter, sort);

  /**
   * On pagination change
   * Perform a new request
   * @param page
   * @param pSize
   */
  const handlePaginationChange = (page: number, pSize: number) => {
    setActivePage(page);
    setPageSize(pSize);
  };

  /**
   * Handle change of the table sort
   * @param s
   */
  const handleOnSort = (s: Sort) => {
    setSort(s);
  };

  useEffect(() => {
    setActivePage(1);
  }, [filter]);

  return (
    <Box paddingBottom="3rem">
      <AuditTrailFilters isLoading={isLoading} exportCsv={exportCsv} totalItems={totalItems} />
      <Typography variant="body1" paddingBottom="1rem">
        {t('common.table.totalItems', { count: totalItems })}
      </Typography>
      <Table headers={headers} rows={rows} isLoading={isLoading} defaultOrderBy={sort.orderBy} onSort={handleOnSort} />
      <TablePagination
        activePage={activePage}
        pageSize={pageSize}
        totalItems={totalItems}
        handlePageChange={handlePaginationChange}
      />
    </Box>
  );
}

export default AuditTrailTable;
