import fileDownload from 'js-file-download';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Row } from '../../components/Table/TableModels';
import ClickableLink from '../../components/Text/ClickableLink';
import useDate from '../../hooks/useDate/useDate';
import useLogger from '../../hooks/useLogger/useLogger';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { Sort } from '../../models/CommonModels';
import { DevicesReport, DevicesReportFilter, DevicesReportRequest } from '../../models/DevicesReportModels';
import DevicesReportService from '../../services/DevicesReports/DevicesReportService';

function useDevicesReportTable(activePage: number, pageSize: number, filter: DevicesReportFilter, sort: Sort) {
  const [t] = useTranslation();
  const { localeDateTimeFormat } = useDate();
  const [totalItems, setTotalItems] = useState(0);
  const [devicesReport, setDevicesReport] = useState<DevicesReport[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const logger = useLogger();
  const snackBar = useSnackBar();

  const [tableHeaders, setTableHeaders] = useState([
    'endUserFirstName',
    'endUserLastName',
    'endUserEmail',
    'manufacturer',
    'model',
    'type',
    'os',
    'mobileNumber',
    'eid',
    'imei',
    'serialNumber',
    'source',
    'uemId',
    'uemSystem',
    'status',
    'companyName',
    'country',
    'addedDate',
    'lastUpdatedDate',
  ]);

  /**
   * Fetch the list of devices report
   */
  const fetchDevicesReport = () => {
    setIsLoading(true);
    const requestParams: DevicesReportRequest = {
      page: activePage - 1,
      pageSize,
      sort: sort.orderBy,
      sortingType: sort.order,
      ...filter,
    };
    if (!requestParams.status) {
      delete requestParams.status;
    }
    DevicesReportService.api.fetchDevicesReport(requestParams).then(
      (response) => {
        setDevicesReport(response.data.deviceReportList);
        setTotalItems(response.data.totalElements);
        setIsLoading(false);
      },
      () => {
        snackBar.showSnackBar(t('pages.devicesReport.fetchDevicesReportError'), 'error');
        setIsLoading(false);
      },
    );
  };

  /**
   * Exports the current devices report
   */
  const exportCsv = () => {
    const requestParams: DevicesReportRequest = {
      page: activePage - 1,
      pageSize,
      sort: sort.orderBy,
      sortingType: sort.order,
      ...filter,
    };
    if (!filter.status) {
      delete requestParams.status;
    }
    DevicesReportService.api.exportCsv(requestParams).then(
      (res) => {
        fileDownload(res.data, `devices_report_${DateTime.now().toFormat(localeDateTimeFormat)}.csv`);
      },
      () => {
        snackBar.showSnackBar(t('pages.devicesReport.exportCsvError'), 'error');
      },
    );
  };

  React.useEffect(() => {
    DevicesReportService.api.fetchTableColumns().then(
      (response) => {
        setTableHeaders(response.data.columns);
      },
      () => {
        logger.logError('DevicesReport: Failed to get the list of columns');
      },
    );
  }, []);

  React.useEffect(() => {
    fetchDevicesReport();
  }, [activePage, pageSize, filter, sort]);

  /**
   * Render the value of each cell based on type of field
   * @param dr
   * @param item
   */
  const renderCellValue = (dr: DevicesReport, item: keyof DevicesReport) => {
    if (dr[item]) {
      switch (item) {
        case 'lastUpdatedDate':
          return DateTime.fromISO(dr[item]).toFormat(`${localeDateTimeFormat} (z)`);
        case 'addedDate':
          return DateTime.fromISO(dr[item]).toFormat(`${localeDateTimeFormat} (z)`);
        case 'status':
          return t(`common.simStatus.${dr[item]}`);
        case 'type':
          return t(`common.deviceType.${dr[item]}`);
        case 'uemSystem':
          return t(`pages.uemOnboarding.uemTypes.${dr[item]}`);
        default:
          return dr[item];
      }
    }
    return t('common.na');
  };

  const headers: Header[] = useMemo(() => {
    const hds: Header[] = tableHeaders.map((item) => ({
      id: item,
      label: t(`pages.devicesReport.table.headers.${item}`),
      isSortable: true,
      sx: { minWidth: '12rem' },
    }));
    hds.push({
      id: 'viewDetails',
      label: '',
      isSortable: false,
      sx: { minWidth: '8rem' },
    });
    return hds;
  }, [t, tableHeaders]);

  const rows: Row[] = useMemo(
    () =>
      devicesReport?.map((dr) => ({
        id: dr.itemId,
        cells: [
          ...tableHeaders.map((item) => ({
            value: `${renderCellValue(dr, item as keyof DevicesReport)}`,
          })),
          {
            value: (
              <ClickableLink
                sx={{ marginLeft: 'auto' }}
                to={`/esimManager/userDetails/${dr.endUserId}/deviceDetails/${dr.id}?userName=${dr.endUserFirstName} ${dr.endUserLastName}`}
              >
                {t('common.table.viewDetails')}
              </ClickableLink>
            ),
          },
        ],
      })),
    [t, devicesReport, tableHeaders],
  );

  return { headers, rows, totalItems, isLoading, exportCsv };
}

export default useDevicesReportTable;
