import { Box } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import Button from '../../../components/Button/Button';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import Drawer from '../../../components/Drawer/Drawer';
import Dropdown from '../../../components/Dropdown/Dropdown';
import TextInput from '../../../components/TextInput/TextInput';
import { EmailRegex } from '../../../configs/RegexConfig';
import { DevicesReportFilter } from '../../../models/DevicesReportModels';
import { setFilter } from '../../../stores/DevicesReportStore';
import { RootState } from '../../../stores/Store';
import useDevicesReportFiltersDrawer from './useDevicesReportFiltersDrawer';

interface AuditTrailFiltersDrawerProps {
  open: boolean;
  onClose: () => void;
}
function DevicesReportFiltersDrawer({ open, onClose }: AuditTrailFiltersDrawerProps) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const filter = useSelector((state: RootState) => state.devicesReportReducer.filter);

  /**
   * Handle the close action
   */
  const handleClose = () => {
    onClose();
  };

  /**
   * Apply filters
   */
  const applyFilters = (vals: DevicesReportFilter) => {
    dispatch(setFilter(vals));
    handleClose();
  };

  const formik = useFormik({
    initialValues: filter,
    enableReinitialize: false,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: yup.object({
      endUserEmail: yup.string().matches(EmailRegex, t('common.inputValidations.email')),
    }),
    onSubmit: () => {
      applyFilters(formik.values);
    },
  });

  /**
   * Resets the form to the initial vals
   */
  const resetForm = () => {
    formik.resetForm({
      values: {
        status: filter.status,
      },
    });
  };

  const {
    deviceManufacturerOptions,
    deviceTypeOptions,
    deviceModelOptions,
    deviceSourceOptions,
    countryOptions,
    companyOptions,
    uemTypesOptions,
    isAllowedToSelectCompany,
    isAllowedToSelectCountries,
    isCompanyInCountry,
  } = useDevicesReportFiltersDrawer(formik.values.manufacturer);

  return (
    <Drawer
      disablePortal
      open={open}
      onClose={handleClose}
      onBackButton={handleClose}
      anchor="right"
      title={t('common.filterResults')}
      progress={0}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingTop: '2rem' }}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ paddingBottom: '2rem' }}>
            {/* End user first name */}
            <TextInput
              autoComplete="off"
              key="endUserFirstName"
              id="endUserFirstName"
              name="endUserFirstName"
              type="text"
              label={t(`pages.devicesReport.table.headers.endUserFirstName`)}
              value={formik.values.endUserFirstName}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.endUserFirstName && Boolean(formik.errors.endUserFirstName)}
              feedback={
                formik.touched.endUserFirstName && Boolean(formik.errors.endUserFirstName)
                  ? formik.errors.endUserFirstName
                  : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.devicesReport.table.headers.endUserFirstName`).toLowerCase()
              }
            />
            {/* End user last name */}
            <TextInput
              autoComplete="off"
              key="endUserLastName"
              id="endUserLastName"
              name="endUserLastName"
              type="text"
              label={t(`pages.devicesReport.table.headers.endUserLastName`)}
              value={formik.values.endUserLastName}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.endUserLastName && Boolean(formik.errors.endUserLastName)}
              feedback={
                formik.touched.endUserLastName && Boolean(formik.errors.endUserLastName)
                  ? formik.errors.endUserLastName
                  : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.devicesReport.table.headers.endUserLastName`).toLowerCase()
              }
            />
            {/* End user email */}
            <TextInput
              autoComplete="off"
              key="endUserEmail"
              id="endUserEmail"
              name="endUserEmail"
              type="email"
              label={t(`pages.devicesReport.table.headers.endUserEmail`)}
              value={formik.values.endUserEmail}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.endUserEmail && Boolean(formik.errors.endUserEmail)}
              feedback={
                formik.touched.endUserEmail && Boolean(formik.errors.endUserEmail) ? formik.errors.endUserEmail : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.devicesReport.table.headers.endUserEmail`).toLowerCase()
              }
            />
            {/* Device manufacturer */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="manufacturer"
              name="manufacturer"
              label={t('pages.devicesReport.table.headers.manufacturer')}
              value={formik.values.manufacturer}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('model', undefined);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.manufacturer && Boolean(formik.errors.manufacturer)}
              feedback={
                formik.touched.manufacturer && Boolean(formik.errors.manufacturer) ? formik.errors.manufacturer : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              items={deviceManufacturerOptions}
              placeholder={
                t('common.placeholders.dropdownPlaceholder') +
                t('pages.devicesReport.table.headers.manufacturer').toLowerCase()
              }
            />
            {/* Device model */}
            {deviceModelOptions.length ? (
              <Dropdown
                isPlaceholderDisabled={false}
                id="model"
                name="model"
                label={t('pages.devicesReport.table.headers.model')}
                value={formik.values.model}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                error={formik.touched.model && Boolean(formik.errors.model)}
                feedback={formik.touched.model && Boolean(formik.errors.model) ? formik.errors.model : ''}
                formStyle={{ paddingBottom: '1.5rem' }}
                items={deviceModelOptions}
                placeholder={
                  t('common.placeholders.dropdownPlaceholder') +
                  t('pages.devicesReport.table.headers.model').toLowerCase()
                }
              />
            ) : null}
            {/* Device type */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="type"
              name="type"
              label={t('pages.devicesReport.table.headers.type')}
              value={formik.values.type}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              error={formik.touched.type && Boolean(formik.errors.type)}
              feedback={formik.touched.type && Boolean(formik.errors.type) ? formik.errors.type : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={deviceTypeOptions}
              placeholder={
                t('common.placeholders.dropdownPlaceholder') + t('pages.devicesReport.table.headers.type').toLowerCase()
              }
            />
            {/* Device source */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="source"
              name="source"
              label={t('pages.devicesReport.table.headers.source')}
              value={formik.values.source}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              error={formik.touched.source && Boolean(formik.errors.source)}
              feedback={formik.touched.source && Boolean(formik.errors.source) ? formik.errors.source : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={deviceSourceOptions}
              placeholder={
                t('common.placeholders.dropdownPlaceholder') +
                t('pages.devicesReport.table.headers.source').toLowerCase()
              }
            />
            {/* UEM system */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="uemSystem"
              name="uemSystem"
              label={t('pages.devicesReport.table.headers.uemSystem')}
              value={formik.values.uemSystem}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              error={formik.touched.uemSystem && Boolean(formik.errors.uemSystem)}
              feedback={formik.touched.uemSystem && Boolean(formik.errors.uemSystem) ? formik.errors.uemSystem : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={uemTypesOptions}
              placeholder={
                t('common.placeholders.dropdownPlaceholder') + t('pages.devicesReport.table.headers.uemSystem')
              }
            />
            {/* OS */}
            <TextInput
              autoComplete="off"
              key="os"
              id="os"
              name="os"
              type="text"
              label={t(`pages.devicesReport.table.headers.os`)}
              value={formik.values.os}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.os && Boolean(formik.errors.os)}
              feedback={formik.touched.os && Boolean(formik.errors.os) ? formik.errors.os : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.devicesReport.table.headers.os`)}
            />
            {/* Mobile number */}
            <TextInput
              autoComplete="off"
              key="mobileNumber"
              id="mobileNumber"
              name="mobileNumber"
              type="text"
              label={t(`pages.devicesReport.table.headers.mobileNumber`)}
              value={formik.values.mobileNumber}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
              feedback={
                formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber) ? formik.errors.mobileNumber : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.devicesReport.table.headers.mobileNumber`).toLowerCase()
              }
            />
            {/* eid */}
            <TextInput
              autoComplete="off"
              key="eid"
              id="eid"
              name="eid"
              type="text"
              label={t(`pages.devicesReport.table.headers.eid`)}
              value={formik.values.eid}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.eid && Boolean(formik.errors.eid)}
              feedback={formik.touched.eid && Boolean(formik.errors.eid) ? formik.errors.eid : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.devicesReport.table.headers.eid`)}
            />
            {/* IMEI */}
            <TextInput
              autoComplete="off"
              key="imei"
              id="imei"
              name="imei"
              type="text"
              label={t(`pages.devicesReport.table.headers.imei`)}
              value={formik.values.imei}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.imei && Boolean(formik.errors.imei)}
              feedback={formik.touched.imei && Boolean(formik.errors.imei) ? formik.errors.imei : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.devicesReport.table.headers.imei`)}
            />
            {/* Device serial number */}
            <TextInput
              autoComplete="off"
              key="serialNumber"
              id="serialNumber"
              name="serialNumber"
              type="text"
              label={t(`pages.devicesReport.table.headers.serialNumber`)}
              value={formik.values.serialNumber}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
              feedback={
                formik.touched.serialNumber && Boolean(formik.errors.serialNumber) ? formik.errors.serialNumber : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.devicesReport.table.headers.serialNumber`).toLowerCase()
              }
            />

            {/* UEM ID */}
            <TextInput
              autoComplete="off"
              key="uemId"
              id="uemId"
              name="uemId"
              type="text"
              label={t(`pages.devicesReport.table.headers.uemId`)}
              value={formik.values.uemId}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.uemId && Boolean(formik.errors.uemId)}
              feedback={formik.touched.uemId && Boolean(formik.errors.uemId) ? formik.errors.uemId : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') + t(`pages.devicesReport.table.headers.uemId`).toLowerCase()
              }
            />
            {/* Country */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="country"
              name="country"
              label={t('pages.devicesReport.table.headers.country')}
              value={formik.values.country}
              onChange={(e) => {
                formik.handleChange(e);
                if (
                  !!e.target.value &&
                  !isCompanyInCountry(e.target.value as string, formik.values.companyCode as string)
                ) {
                  formik.setFieldValue('companyCode', undefined);
                }
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              feedback={formik.touched.country && Boolean(formik.errors.country) ? formik.errors.country : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={countryOptions}
              disabled={!isAllowedToSelectCountries}
              placeholder={
                isAllowedToSelectCountries
                  ? t('common.placeholders.dropdownPlaceholder') +
                    t('pages.devicesReport.table.headers.country').toLowerCase()
                  : `${countryOptions[0]?.label}`
              }
            />
            {/* Company */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="companyCode"
              name="companyCode"
              label={t('pages.devicesReport.table.headers.companyCode')}
              value={formik.values.companyCode}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.companyCode && Boolean(formik.errors.companyCode)}
              feedback={
                formik.touched.companyCode && Boolean(formik.errors.companyCode) ? formik.errors.companyCode : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              items={companyOptions}
              disabled={!isAllowedToSelectCompany}
              placeholder={
                isAllowedToSelectCompany
                  ? t('common.placeholders.dropdownPlaceholder') +
                    t('pages.devicesReport.table.headers.companyCode').toLowerCase()
                  : `${companyOptions[0]?.label}`
              }
            />
          </Box>
          <Box sx={{ marginTop: 'auto', marginBottom: '2rem' }}>
            <ButtonGroup>
              <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
              <Button label={t('common.resetFilters')} color="secondaryButton" onClick={resetForm} />
              <Button
                label={t('common.applyFilters')}
                color="primaryButton"
                disabled={!formik.isValid}
                onClick={formik.submitForm}
              />
            </ButtonGroup>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
}

export default DevicesReportFiltersDrawer;
