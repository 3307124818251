import { UserRole } from '../../models/AuthModels';
import useAuth from '../useAuth/useAuth';
import UseLogger from '../useLogger/useLogger';

function useAuthenticatedRouteGuard(allowedRoles: UserRole[]) {
  const { userData } = useAuth();
  let allowRender = false;

  if (!userData) {
    allowRender = false;
  }

  if (!userData?.role) {
    allowRender = false;
  }

  if (userData && allowedRoles.includes(userData.role)) {
    allowRender = true;
  }
  UseLogger().log(`AuthenticatedRouteGuard: Is allowed to render: ${allowRender}, ${JSON.stringify(userData)}`);
  return { allowRender };
}

export default useAuthenticatedRouteGuard;
