import { useLocation, useNavigate } from 'react-router-dom';

function useGoBack() {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Navigates back or to a given route partial
   * @param href
   */
  const goBack = (href = '/auth') => {
    const canGoBack = location.key !== 'default';
    if (canGoBack) navigate(-1);
    else navigate(href);
  };

  return { goBack };
}

export default useGoBack;
