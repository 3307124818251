import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuditTrailFilter } from '../models/AuditTrailModels';
import { AuditCompany } from '../models/CompanyOnboardingModels';

interface AuditTrailStateData {
  filter: AuditTrailFilter;
  companies: AuditCompany[];
}

const initialState: AuditTrailStateData = {
  filter: { eventType: '' },
  companies: [],
};

export const AuditTrailSlice = createSlice({
  name: 'auditTrail',
  initialState,
  reducers: {
    reset: () => initialState,
    resetFilter: (state) => {
      state.filter = { ...initialState.filter, eventType: state.filter.eventType };
    },
    setFilter: (state, action: PayloadAction<AuditTrailFilter>) => {
      state.filter = action.payload;
    },
    removeFilter: (state, action: PayloadAction<keyof AuditTrailFilter>) => {
      delete state.filter[action.payload];
    },
    setCompanyList: (state, action: PayloadAction<AuditCompany[]>) => {
      state.companies = action.payload;
    },
  },
});

export const { reset, resetFilter, setFilter, removeFilter, setCompanyList } = AuditTrailSlice.actions;

export default AuditTrailSlice.reducer;
