import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import Button from '../../components/Button/Button';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import useGoBack from '../../hooks/useGoBack/useGoBack';

function NotAllowedRoute() {
  const [t] = useTranslation();
  const { goBack } = useGoBack();
  const theme = useTheme();
  const [showPage, setShowPage] = useState(false);
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 5);

  useTimer({
    expiryTimestamp,
    onExpire: () => setShowPage(true),
  });

  return (
    <Container maxWidth="md" sx={{ display: 'flex', paddingTop: '4rem' }}>
      {showPage ? (
        <Grid container>
          <Grid
            item
            xl={6}
            xs={12}
            sx={{
              display: 'flex',
              borderRight: {
                md: `1px solid ${theme.palette.text.disabled}`,
              },
              padding: '2rem',
              flexShrink: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: '4rem',
                textTransform: 'none',
                lineHeight: 'normal',
                fontFamily: 'Vodafone RgBd',
                color: theme.palette.primary.main,
              }}
            >
              {t('pages.errors.notAllowedRoute.title')}
            </Typography>
          </Grid>
          <Grid
            item
            xl={6}
            md={6}
            xs={12}
            sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem', flexShrink: 1 }}
          >
            <Typography sx={{ fontWeight: 'normal', fontSize: '2rem' }}>
              {t('pages.errors.notAllowedRoute.message1')}
            </Typography>
            <Typography sx={{ fontFamily: 'Vodafone Lt' }}>{t('pages.errors.notAllowedRoute.message2')}</Typography>
            <Typography sx={{ fontFamily: 'Vodafone Lt' }}>{t('pages.errors.notAllowedRoute.message3')}</Typography>
            <Button color="primary" label={t('common.goBack')} onClick={() => goBack()} />
          </Grid>
        </Grid>
      ) : (
        <Grid item sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LoadingSpinner style={{ minHeight: '148px', maxWidth: '148px' }} />
            <Typography sx={{ fontSize: '1.75rem', lineHeight: '2rem', fontFamily: 'Vodafone Lt' }}>
              {t('common.loading')}
            </Typography>
          </Box>
        </Grid>
      )}
    </Container>
  );
}

export default NotAllowedRoute;
