import { Box, Typography } from '@mui/material';
import i18next from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '../../hooks/useModal/useModal';
import MenuItemWrapper from '../Dropdown/DropdownStyle';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import ClickableIcon from '../Icons/ClickableIcon';
import MenuWrapper from '../Menu/MenuStyle';
import Flag from './LanguageSelectorStyle';

function LanguageSelector() {
  const { language, languages } = i18next;
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const modal = useModal();

  /**
   * Handle menu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Changes the language of the application
   * @param lang
   */
  const changeAppLanguage = (lang: string) => {
    // TODO: perform the change language
    /* changeLanguage(lang); */
    handleClose();
    modal.showModal({
      modalTitle: t('navbar.changeLanguageTitle'),
      modalDescription: t('navbar.changeLanguageMessage'),
      onAccept: (setOpen) => {
        setOpen(false);
        handleClose();
      },
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
      <Flag sx={{ background: `url(./locales/${language}/flag.png)`, backgroundSize: 'contain' }} />
      <Typography sx={{ '&:hover': { cursor: 'default' } }}>{t(`common.languages.${language}`)}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '24px',
          height: '24px',
          '& svg': { transform: open ? 'rotate(180deg) scale(1.5)' : 'rotate(0deg) scale(1.5)' },
        }}
      >
        <ClickableIcon
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <ChevronDownIcon />
        </ClickableIcon>
      </Box>
      <MenuWrapper
        id="menu-lang-selector"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiPaper-root': {
            marginTop: '0.5rem',
            minWidth: '12rem !important',
          },
        }}
      >
        {languages.map((lang) => (
          <MenuItemWrapper key={lang} onClick={() => changeAppLanguage(lang)}>
            <Flag
              sx={{ background: `url(./locales/${lang}/flag.png)`, marginRight: '0.25rem', backgroundSize: 'contain' }}
            />{' '}
            {t(`common.languages.${lang}`)}
          </MenuItemWrapper>
        ))}
      </MenuWrapper>
    </Box>
  );
}

export default LanguageSelector;
