import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '../../../../components/Drawer/Drawer';
import { reset } from '../../../../stores/NewConnectionStore';
import { RootState } from '../../../../stores/Store';
import NewConnectionCreateUserStep from './NewConnectionCreateUserStep';
import NewConnectionDevicesStep from './NewConnectionDevicesStep';
import NewConnectionNotificationsStep from './NewConnectionNotificationsStep';
import NewConnectionSelectTypeOfConnectionStep from './NewConnectionSelectTypeOfConnectionStep';
import NewConnectionSummaryStep from './NewConnectionSummaryStep';
import NewConnectionUserAlreadyExistsStep from './NewConnectionUserAlreadyExistsStep';
import NewConnectionUserSelectionTypeStep from './NewConnectionUserSelectionTypeStep';
import NewConnectionSelectUserStep from './NewConnectionSelectUserStep';
import useNewConnection from './useNewConnection';

interface NewConnectionDrawerProps {
  open: boolean;
  onClose: () => void;
  onNewConnectionSuccess: () => void;
  initialStep?: number;
}
function NewConnectionDrawer({ open, onClose, onNewConnectionSuccess, initialStep = 1 }: NewConnectionDrawerProps) {
  const dispatch = useDispatch();
  const [step, setStep] = useState(initialStep);
  const NUMBER_OF_STEPS = 8;
  const [progress, setProgress] = useState(100 / NUMBER_OF_STEPS);
  const userSelectionType = useSelector((state: RootState) => state.newConnectionReducer.userSelectionType);

  /**
   * Handle the close action
   */
  const handleClose = () => {
    dispatch(reset());
    setStep(1);
    onClose();
  };

  const [t] = useTranslation();
  const { availableDevices, isLoading, availableNotifications, availableTariffs, newConnection, onBanSelected } =
    useNewConnection(handleClose, onNewConnectionSuccess);

  React.useEffect(() => {
    setProgress((step / NUMBER_OF_STEPS) * 100);
  }, [step]);

  /**
   * Next step increment
   */
  const nextStep = (next?: number) => {
    if (next && Number(next)) setStep(next);
    else setStep((prevState) => prevState + 1);
  };

  /**
   * Handle the back button of the drawer to close or to go back a step
   */
  const handleBackButton = () => {
    if (step === initialStep) {
      handleClose();
    } else if (step === 4) {
      setStep(initialStep);
    } else if (step === 5) {
      if (userSelectionType === 'USE_EXISTING') {
        setStep(4);
      } else setStep(2);
    } else {
      setStep((prevState) => prevState - 1);
    }
  };

  /**
   * Render each step of the upgrade device journey
   */
  const renderSteps = () => {
    switch (step) {
      case 1:
        return <NewConnectionUserSelectionTypeStep nextStep={nextStep} onClose={handleClose} />;
      case 2:
        return <NewConnectionCreateUserStep nextStep={nextStep} onClose={handleClose} onBanSelected={onBanSelected} />;
      case 3:
        return (
          <NewConnectionUserAlreadyExistsStep nextStep={nextStep} onClose={handleClose} prevStep={handleBackButton} />
        );
      case 4:
        return <NewConnectionSelectUserStep nextStep={nextStep} onClose={handleClose} />;
      case 5:
        return <NewConnectionSelectTypeOfConnectionStep nextStep={nextStep} onClose={handleClose} />;
      case 6:
        return (
          <NewConnectionDevicesStep
            availableDevices={availableDevices}
            isLoading={isLoading}
            nextStep={nextStep}
            onClose={handleClose}
          />
        );
      case 7:
        return (
          <NewConnectionNotificationsStep
            availableNotifications={availableNotifications}
            nextStep={nextStep}
            onClose={handleClose}
          />
        );
      case 8:
        return (
          <NewConnectionSummaryStep
            availableTariffs={availableTariffs}
            isLoading={isLoading}
            nextStep={newConnection}
            onClose={handleClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Drawer
      disablePortal
      open={open}
      onClose={handleClose}
      onBackButton={handleBackButton}
      anchor="right"
      title={t('pages.eSimManager.newConnectionDrawer.title')}
      progress={progress}
    >
      <Box sx={{ paddingTop: '2rem', height: '100%' }}>{renderSteps()}</Box>
    </Drawer>
  );
}

export default NewConnectionDrawer;
