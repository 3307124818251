import { Tab as T, TabProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const Tab = styled(T)<TabProps>(({ theme }) => ({
  textTransform: 'none',
  maxWidth: 'none',
  flex: '1 0 auto',
  color: 'inherit',
  textAlign: 'left',
  padding: '0.5rem',
  borderBottom: `1px solid${theme.palette.monochrome.monoChrome3}`,
  '&:hover': {
    fontWeight: 'bold',
  },
  '&.Mui-selected': {
    fontWeight: 'bold',
    borderBottom: `3px solid${theme.palette.primary.main}`,
    color: 'inherit',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default Tab;
