import * as React from 'react';
import { SVGProps, memo } from 'react';

function SuccessTickIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none" {...props}>
      <rect width={24} height={24} x={0.5} fill="#428600" fillOpacity={0.1} rx={12} />
      <path
        stroke="#428600"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m16.54 9.008-5.523 5.523-2.454-2.454"
      />
    </svg>
  );
}
export default memo(SuccessTickIcon);
