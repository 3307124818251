import { Container } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Navigate, ScrollRestoration } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import TopBanner from '../../components/TopBanner/TopBanner';
import SessionExpiryProvider from '../../contexts/SessionExpiryContext/SessionExpiryContext';
import useAuthenticatedRouteGuard from '../../hooks/useAuthenticatedRouteGuard/useAuthenticatedRouteGuard';
import { UserRole } from '../../models/AuthModels';

interface AuthenticatedRouteProps extends PropsWithChildren {
  allowedRoles: UserRole[];
}
function AuthenticatedRoute({ allowedRoles, children }: AuthenticatedRouteProps) {
  const { allowRender } = useAuthenticatedRouteGuard(allowedRoles);
  return (
    <SessionExpiryProvider>
      <ScrollRestoration />
      <Navbar />
      <TopBanner />
      <Container maxWidth="xl" sx={{ marginTop: '2.5rem', minHeight: '70vh' }}>
        {allowRender ? <>{children} </> : <Navigate to="/notAllowed" />}
      </Container>
      <Footer />
    </SessionExpiryProvider>
  );
}

export default AuthenticatedRoute;
