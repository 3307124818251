import { Box, RadioGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import Radio from '../../../../components/Radio/Radio';
import { updateTypeOfConnection } from '../../../../stores/NewConnectionStore';
import { RootState } from '../../../../stores/Store';

interface NewConnectionSelectTypeOfConnectionStepProps {
  nextStep: () => void;
  onClose: () => void;
}
function NewConnectionSelectTypeOfConnectionStep({ nextStep, onClose }: NewConnectionSelectTypeOfConnectionStepProps) {
  const [t] = useTranslation();
  const { typeOfConnection, userSelectionType, userAlreadyExists } = useSelector(
    (state: RootState) => state.newConnectionReducer,
  );
  const dispatch = useDispatch();

  /**
   * Handles change of the radio button
   * @param e
   */
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTypeOfConnection(e.target.value as 'ESIM' | 'DEVICE'));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ paddingBottom: '2rem' }}>
        <RadioGroup
          aria-labelledby="notifications-radio"
          name="connection-radio"
          value={typeOfConnection}
          onChange={handleRadioChange}
        >
          <Radio
            value="DEVICE"
            disabled={userSelectionType === 'CREATE' && !userAlreadyExists}
            label={t('pages.eSimManager.newConnectionDrawer.selectDeviceFromList')}
          />
          <Radio value="ESIM" label={t('pages.eSimManager.newConnectionDrawer.manuallyInput')} />
        </RadioGroup>
      </Box>
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          <Button label={t('common.continue')} color="primaryButton" disabled={!typeOfConnection} onClick={nextStep} />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default NewConnectionSelectTypeOfConnectionStep;
