import { Container, Grid, Typography, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import { SessionExpiredLogoutTimeout } from '../../configs/PortalConfig';
import useAuth from '../../hooks/useAuth/useAuth';
import UseLogger from '../../hooks/useLogger/useLogger';
import { LogoutResponse } from '../../models/AuthModels';

function SessionExpired() {
  const [t] = useTranslation();
  const theme = useTheme();
  const { logout } = useAuth();

  useEffect(() => {
    UseLogger().logInfo(`SessionExpiredPage: Going to execute logout in ${SessionExpiredLogoutTimeout / 1000} seconds`);
    setTimeout(() => {
      logout().then(
        (res) => {
          UseLogger().logInfo(
            `SessionExpiredPage: Logout success after session renew failed! Navigating to: ${
              (res as AxiosResponse<LogoutResponse>).data.url
            }`,
          );
          window.location.href = (res as AxiosResponse<LogoutResponse>).data.url;
        },
        () => {
          UseLogger().logInfo(`SessionExpiredPage: Logout failed after session renew failed!`);
        },
      );
    }, SessionExpiredLogoutTimeout);
  }, []);

  return (
    <Container maxWidth="md" sx={{ display: 'flex', paddingTop: '4rem' }}>
      <Grid container>
        <Grid
          item
          xl={6}
          xs={12}
          sx={{
            display: 'flex',
            borderRight: `1px solid ${theme.palette.text.disabled}`,
            padding: '2rem',
            flexShrink: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '4rem',
              textTransform: 'none',
              lineHeight: 'normal',
              fontFamily: 'Vodafone RgBd',
              color: theme.palette.primary.main,
            }}
          >
            {t('pages.sessionExpired.title')}
          </Typography>
        </Grid>
        <Grid
          item
          xl={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem', flexShrink: 1 }}
        >
          <Typography sx={{ fontWeight: 'normal', fontSize: '2rem' }}>
            {t('pages.sessionExpired.description1')}
          </Typography>
          <Typography sx={{ fontFamily: 'Vodafone Lt' }}>{t('pages.sessionExpired.description2')}</Typography>
          <Button
            color="primary"
            label={t('pages.sessionExpired.button')}
            onClick={() => {
              window.location.href = `${import.meta.env.VITE_SAML_URL}`;
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SessionExpired;
