import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem } from '../../components/Dropdown/DropdownModels';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { UemType } from '../../models/UemOnboardingModels';
import UemOnboardingService from '../../services/UemOnboarding/UemOnboardingService';

function useUemsTableFilters() {
  const [uemTypesOptions, setUemTypesOptions] = useState<DropdownItem[]>([]);
  const [uemTypes, setUemTypes] = useState<UemType[]>([]);
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  useEffect(() => {
    UemOnboardingService.api.fetchUemTypes().then(
      (response) => {
        setUemTypes(response.data.uemTypeList);
        const uemTypesDropdownOptions = [
          ...response.data.uemTypeList.map((obj) => ({
            key: obj.type,
            value: obj.type,
            label: t(`pages.uemOnboarding.uemTypes.${obj.type}`),
          })),
        ];
        setUemTypesOptions(uemTypesDropdownOptions);
      },
      () => {
        snackBar.showSnackBar(t('pages.uemOnboarding.getUemTypesError'), 'error');
      },
    );
  }, []);

  return { uemTypesOptions, uemTypes };
}

export default useUemsTableFilters;
