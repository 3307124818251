/**
 * First and last name regex
 */
export const NameRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

/**
 * Email address regex
 */
export const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * IMEI length validation
 */
export const ImeiLength = 15;

/**
 * EID length validation
 */
export const EidLength = 32;

/**
 * Numbers letters spaces and dots regex
 */
export const NumbersLettersSpaceDotRegex = /^[a-zA-Z0-9. ]+$/;

/**
 * Numbers and letters regex
 */
export const NumberLettersRegex = /^[a-zA-Z0-9]+$/;

/**
 * Numbers letters regex and spaces
 */
export const NumberLettersSpaceRegex = /^[a-zA-Z0-9 ]+$/;
