import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem } from '../../components/Dropdown/DropdownModels';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import UemOnboardingService from '../../services/UemOnboarding/UemOnboardingService';

function UseAdminsTableFilters() {
  const [companyOptions, setCompanyOptions] = useState<DropdownItem[]>([]);
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  useEffect(() => {
    UemOnboardingService.api.fetchCompanies().then(
      (response) => {
        let companiesDropdownOptions = [
          {
            key: '',
            value: '',
            label: t('translation:pages.adminOnboarding.table.filters.allCompanies'),
          },
          ...response.data.companies.map((obj) => ({
            key: obj.id,
            value: obj.id,
            label: `${obj.name} ${obj.country}`,
          })),
        ];
        if (response.data.companies.length === 1) {
          companiesDropdownOptions = companiesDropdownOptions.splice(1, 1);
        }

        setCompanyOptions(companiesDropdownOptions);
      },
      () => {
        snackBar.showSnackBar(t('pages.adminOnboarding.fetchCompaniesError'), 'error');
      },
    );
  }, []);

  return { companyOptions };
}

export default UseAdminsTableFilters;
