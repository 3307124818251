import { AxiosResponse, isAxiosError } from 'axios';
import React, { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import { ModalDescription, ModalTitle } from '../../components/Modal/ModalStyle';
import useAuth from '../../hooks/useAuth/useAuth';
import UseLogger from '../../hooks/useLogger/useLogger';
import { LogoutResponse } from '../../models/AuthModels';

interface SessionExpiryContextData {
  initTimer: (timestamp: Date) => void;
}

export const SessionExpiryContext = createContext({} as SessionExpiryContextData);

function SessionExpiryProvider({ children }: PropsWithChildren) {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { authenticate, renewCIAMSession, logout, userData } = useAuth();

  /**
   * On expired session handler
   */
  const onSessionExpired = () => {
    UseLogger().logInfo(`SessionExpiryContext: Session is expired. Navigating to expired password page!`);
    setIsModalOpen(false);
    navigate('/sessionExpired');
  };

  const { totalSeconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: onSessionExpired,
  });

  const description = React.useMemo(
    () => t('expirySession.modal.description', { seconds: totalSeconds }),
    [totalSeconds],
  );

  /**
   * Initiates the session timestamp
   * @param timestamp
   */
  const initTimer = (timestamp: Date) => {
    restart(timestamp);
  };

  /**
   * Navigates to the CIAM homepage, so the user can login
   * again or be automatically redirected with a new session
   */
  const navigateToCIAMLogin = () => {
    window.location.href = `${import.meta.env.VITE_SAML_URL}`;
  };

  /**
   * Handler to renew the session
   */
  const renewSession = () => {
    setIsLoading(true);
    renewCIAMSession().then(
      () => {
        authenticate(true).then(
          () => {
            UseLogger().logInfo(`SessionExpiryContext: Session was successfully renewed!`);
            setIsLoading(false);
            setIsModalOpen(false);
          },
          () => {
            UseLogger().logInfo(`SessionExpiryContext: Session could not be renewed, navigating to login page!`);
            navigateToCIAMLogin();
          },
        );
      },
      (err) => {
        UseLogger().logInfo(`SessionExpiryContext: Session renew failed ${JSON.stringify(err)}`);
        if (isAxiosError(err) && err.response?.status === 400) {
          logout().then(
            (res) => {
              UseLogger().logInfo(
                `SessionExpiryContext: Logout success after session renew failed! Navigating to: ${
                  (res as AxiosResponse<LogoutResponse>).data.url
                }`,
              );
              window.location.href = (res as AxiosResponse<LogoutResponse>).data.url;
            },
            () => {
              UseLogger().logInfo(`SessionExpiryContext: Logout failed after session renew failed!`);
            },
          );
        } else {
          navigateToCIAMLogin();
        }
      },
    );
  };

  useEffect(() => {
    if (minutes > 0 && minutes < 5 && !isModalOpen) {
      UseLogger().logInfo(`SessionExpiryContext: Opening modal with ${totalSeconds} seconds`);
      setIsModalOpen(true);
    }
  }, [minutes]);

  useEffect(() => {
    if (userData) {
      UseLogger().logInfo(
        `SessionExpiryContext: Initializing timer: ${new Date(
          userData.exp * 1000,
        )}; ${totalSeconds} seconds ${minutes} minutes`,
      );
      initTimer(new Date(userData.exp * 1000));
    }
  }, [userData]);

  const value = useMemo(
    () => ({
      initTimer,
    }),
    [],
  );

  return (
    <SessionExpiryContext.Provider value={value}>
      <Modal isOpen={isModalOpen} onClose={() => {}}>
        <ModalTitle>{t('expirySession.modal.title')}</ModalTitle>
        <ModalDescription>{description}</ModalDescription>
        <Button disabled={isLoading} label={t('expirySession.modal.renewSession')} onClick={renewSession} />
      </Modal>
      {children}
    </SessionExpiryContext.Provider>
  );
}
export default SessionExpiryProvider;
