import { Button as MaterialButton } from '@mui/material';
import { ButtonProps as MaterialButtonProps } from '@mui/material/Button/Button';
import { styled } from '@mui/material/styles';

const ButtonWrapper = styled(MaterialButton)<MaterialButtonProps>(({ theme }) => ({
  fontSize: '1.25rem',
  padding: '0.56rem 2rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.625rem',
  borderRadius: '6px',
  textTransform: 'none',
  height: '3.5rem',
  boxShadow: 'none',
  minWidth: '152px',
  '&:hover,:active': {
    boxShadow: 'none',
  },
  '&.MuiButton-containedPrimaryButton': {
    backgroundColor: theme.palette.primaryButton.main,
    '&:hover': {
      backgroundColor: theme.palette.primaryButton.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.primaryButton.pressed,
    },
    '&:disabled': {
      opacity: 0.38,
      color: theme.palette.primaryButton.contrastText,
    },
  },
  '&.MuiButton-containedSecondaryButton': {
    backgroundColor: theme.palette.secondaryButton.main,
    '&:hover': {
      backgroundColor: theme.palette.secondaryButton.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.secondaryButton.pressed,
    },
    '&:disabled': {
      opacity: 0.38,
      color: theme.palette.secondaryButton.contrastText,
    },
  },
  '&.MuiButton-outlinedAlternative1Button': {
    border: `2px solid ${theme.palette.alternative1Button.main}`,
    color: theme.palette.monochrome.monoChrome6,
    '&:hover': {
      backgroundColor: theme.palette.alternative1Button.hover,
      color: theme.palette.alternative1Button.contrastText,
      border: `2px solid ${theme.palette.alternative1Button.hover}`,
    },
    '&:active': {
      backgroundColor: theme.palette.alternative1Button.pressed,
      color: theme.palette.alternative1Button.contrastText,
      border: `2px solid ${theme.palette.alternative1Button.pressed}`,
    },
    '&:disabled': {
      opacity: 0.38,
      color: theme.palette.monochrome.monoChrome6,
    },
  },
  '&.MuiButton-outlinedAlternative2Button': {
    border: `2px solid ${theme.palette.alternative2Button.main}`,
    color: theme.palette.monochrome.monoChrome6,
    '&:hover': {
      backgroundColor: theme.palette.alternative2Button.hover,
      color: theme.palette.alternative2Button.contrastText,
      border: `2px solid ${theme.palette.alternative2Button.hover}`,
    },
    '&:active': {
      backgroundColor: theme.palette.alternative2Button.pressed,
      color: theme.palette.alternative2Button.contrastText,
      border: `2px solid ${theme.palette.alternative2Button.pressed}`,
    },
    '&:disabled': {
      opacity: 0.38,
      color: theme.palette.monochrome.monoChrome6,
    },
  },
}));

export default ButtonWrapper;
