import { Box, BoxProps, styled } from '@mui/material';

export const FilterTagContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  padding: '0.25rem 0.5rem 0.25rem 1rem',
  alignItems: 'center',
  gap: '0.5rem',
  backgroundColor: theme.palette.monochrome.monoChrome6,
  borderRadius: '1rem',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '1.125rem',
  color: theme.palette.secondary.contrastText,
}));

export const FilterTagsList = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));
