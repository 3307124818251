import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHttpError from '../../../../hooks/useHttpError/useHttpError';
import useSnackBar from '../../../../hooks/useSnackBar/useSnackBar';
import { Device, DeviceUpdateRequest, Sim } from '../../../../models/UserDevicesModels';
import UsersService from '../../../../services/Users/UsersService';

function UseDeviceDetails(id: string | undefined) {
  const [device, setDevice] = useState<Device | null>(null);
  const [sims, setSims] = useState<Sim[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  const { getHttpReasonFromList } = useHttpError();

  /**
   * Fetches the device details by id
   */
  const fetchDeviceDetails = () => {
    setIsLoading(true);
    UsersService.api.fetchDeviceById(id as string).then(
      (response) => {
        setIsLoading(false);
        setDevice(response.data.device);
        setSims(response.data?.sims ? response.data.sims : []);
      },
      () => {
        snackBar.showSnackBar(t('pages.deviceDetails.getDeviceError'), 'error');
      },
    );
  };

  /**
   * Updates the device on the remote server
   * @param d
   * @param setFormOpen
   */
  const updateDeviceDetails = (d: DeviceUpdateRequest, setFormOpen: (val: boolean) => void) => {
    setIsLoading(true);
    UsersService.api.updateDevice(d).then(
      (response) => {
        setIsLoading(false);
        setDevice(response.data.device);
        setSims(response.data?.sims ? response.data.sims : []);
        setFormOpen(false);
        snackBar.showSnackBar(t('pages.deviceDetails.updateDeviceSuccess'), 'success');
      },
      (res) => {
        setIsLoading(false);
        const error = getHttpReasonFromList(
          res.response.data,
          [3042, 3067, 3107, 3121, 3122, 3123, 3124, 3125, 3126, 3127, 3128],
          t('pages.deviceDetails.updateDeviceError'),
        );
        snackBar.showSnackBar(error, 'error');
      },
    );
  };

  React.useEffect(() => {
    if (id) {
      fetchDeviceDetails();
    } else {
      snackBar.showSnackBar(t('pages.deviceDetails.deviceIdNotProvided'), 'error');
    }
  }, [id]);

  return { device, sims, fetchDeviceDetails, updateDeviceDetails, isLoading };
}

export default UseDeviceDetails;
