import * as React from 'react';
import { SVGProps, memo } from 'react';

function ErrorExclamationIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#E60000"
        fillOpacity={0.1}
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      />
      <path
        fill="#E60000"
        fillRule="evenodd"
        d="M12 6.975c.266 0 .485.198.52.454l.005.071v6.236a.525.525 0 0 1-1.045.071l-.005-.071V7.5c0-.29.235-.525.525-.525Zm0 8.775a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default memo(ErrorExclamationIcon);

export function ErrorExclamationIcon2x(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={46} height={47} fill="none" {...props}>
      <path
        fill="#E60000"
        fillOpacity={0.1}
        d="M23 46.5c12.703 0 23-10.297 23-23S35.703.5 23 .5 0 10.797 0 23.5s10.297 23 23 23Z"
      />
      <path
        fill="#E60000"
        fillRule="evenodd"
        d="M23 13.869c.51 0 .93.378.997.87l.01.136v11.952a1.006 1.006 0 0 1-2.004.136l-.01-.136V14.875c0-.556.451-1.006 1.007-1.006Zm0 16.819a1.438 1.438 0 1 0 0 2.875 1.438 1.438 0 0 0 0-2.876Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
