import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { createContext, PropsWithChildren } from 'react';
import ErrorExclamationIcon from '../../components/Icons/ErrorExclamationIcon';
import SuccessTickIcon from '../../components/Icons/SuccessTickIcon';

type SnackBarContextActions = {
  showSnackBar: (text: string, typeColor: AlertColor) => void;
};

export const SnackBarContext = createContext({} as SnackBarContextActions);

function SnackBarProvider({ children }: PropsWithChildren) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [typeColor, setTypeColor] = React.useState<AlertColor>('success');

  /**
   * Show snackbar handler
   * @param text
   * @param color
   */
  const showSnackBar = (text: string, color: AlertColor) => {
    setMessage(text);
    setTypeColor(color);
    setOpen(true);
  };

  /**
   * Handle close button
   */
  const handleClose = () => {
    setOpen(false);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Snackbar
        open={open}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        sx={{
          boxShadow: '0px 7.5px 15px 0px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Alert
          onClose={handleClose}
          closeText="close"
          severity={typeColor}
          iconMapping={{
            error: <ErrorExclamationIcon />,
            success: <SuccessTickIcon />,
          }}
          sx={{
            padding: '0.625rem 1rem',
            alignItems: 'center',
            gap: '1rem',
            backgroundColor: '#fff',
            fontSize: '1.125rem',
            lineHeight: 'normal',
            fontStyle: 'normal',
            '& .MuiAlert-icon': {
              flexShrink: 0,
            },
            '& > div': {
              padding: '0px',
              margin: '0px',
            },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
}

export default SnackBarProvider;
