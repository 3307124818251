import * as React from 'react';
import { SVGProps, memo } from 'react';

function NotificationErrorIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="#BD0000"
        d="M14.422 11.363 9.756 2.721a2 2 0 0 0-3.51 0L1.579 11.36a2 2 0 0 0 1.754 2.96h9.334a2 2 0 0 0 1.755-2.957ZM7.667 5.322a.333.333 0 0 1 .666 0v4.708a.333.333 0 1 1-.666 0V5.322ZM8 12.072a.417.417 0 1 1 .417-.417.417.417 0 0 1-.417.414v.003Z"
      />
    </svg>
  );
}
export default memo(NotificationErrorIcon);
