import { DateTime } from 'luxon';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem } from '../../components/Dropdown/DropdownModels';
import FilterTag from '../../components/FilterTag/FilterTag';
import useDate from '../../hooks/useDate/useDate';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { AuditTrailFilter } from '../../models/AuditTrailModels';
import AuditTrailService from '../../services/AuditTrail/AuditTrailService';
import { removeFilter } from '../../stores/AuditTrailStore';
import { RootState } from '../../stores/Store';

function useAuditTrailFilters() {
  const [t] = useTranslation();
  const [auditTrailTypesOptions, setAuditTrailTypesOptions] = useState<DropdownItem[]>([
    {
      key: 'ALL',
      value: '',
      label: t(`pages.auditTrail.eventTypes.ALL`),
    },
  ]);
  const [auditTrailTypes, setAuditTrailTypes] = useState<string[]>([]);
  const { filter, companies } = useSelector((state: RootState) => state.auditTrailReducer);
  const snackBar = useSnackBar();
  const { localeDateFormat } = useDate();
  const dispatch = useDispatch();

  useEffect(() => {
    AuditTrailService.api.fetchAuditTrailEventTypes().then(
      (response) => {
        const types = response.data.eventTypes.sort();
        setAuditTrailTypes(types);
        const uemTypesDropdownOptions = [
          ...types.map((obj) => ({
            key: obj,
            value: obj,
            label: t(`pages.auditTrail.eventTypes.${obj}`),
          })),
        ];
        setAuditTrailTypesOptions((prev) => [...prev, ...uemTypesDropdownOptions]);
      },
      () => {
        snackBar.showSnackBar(t('pages.auditTrail.getAuditTrailEventTypesError'), 'error');
      },
    );
  }, []);

  /**
   * Removes the filter from the store
   * @param filterKey
   */
  const onRemoveFilter = (filterKey: keyof AuditTrailFilter) => {
    dispatch(removeFilter(filterKey));
  };

  /**
   * Applies the proper transformations to a given type of filter tag
   * @param filterKey
   */
  const filterTagAdapter = (filterKey: keyof AuditTrailFilter) => {
    if (filter[filterKey] === '') return null;
    switch (filterKey) {
      case 'eventType':
        return null;
      case 'startDate':
      case 'endDate':
        return (
          <FilterTag key={filterKey} onRemove={() => onRemoveFilter(filterKey)}>
            {DateTime.fromISO(filter[filterKey] as string).toFormat(localeDateFormat)}
          </FilterTag>
        );
      case 'status':
        return (
          <FilterTag key={filterKey} onRemove={() => onRemoveFilter(filterKey)}>
            {t(`common.status.${filter[filterKey]}`)}
          </FilterTag>
        );
      case 'companyCode':
        return (
          <FilterTag key={filterKey} onRemove={() => onRemoveFilter(filterKey)}>
            {companies.find((c) => c.code === filter[filterKey])?.name}
          </FilterTag>
        );
      case 'uemSystem':
        return (
          <FilterTag key={filterKey} onRemove={() => onRemoveFilter(filterKey)}>
            {t(`pages.uemOnboarding.uemTypes.${filter[filterKey]}`)}
          </FilterTag>
        );
      case 'deviceType':
        return (
          <FilterTag key={filterKey} onRemove={() => onRemoveFilter(filterKey)}>
            {t(`common.deviceType.${filter[filterKey]}`)}
          </FilterTag>
        );
      default:
        return (
          <FilterTag key={filterKey} onRemove={() => onRemoveFilter(filterKey)}>
            {filter[filterKey]}
          </FilterTag>
        );
    }
  };

  const renderFilterTags = useCallback(() => {
    const existingFilterKeys = Object.keys(filter);
    const filterTags: ReactNode[] = [];
    existingFilterKeys.forEach((fk) => {
      const f = filterTagAdapter(fk as keyof AuditTrailFilter);
      if (f) {
        filterTags.push(f);
      }
    });

    return filterTags;
  }, [filter]);

  return { auditTrailTypesOptions, auditTrailTypes, renderFilterTags };
}

export default useAuditTrailFilters;
