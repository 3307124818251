import { Box, FormControl, Select } from '@mui/material';
import * as React from 'react';
import { memo } from 'react';
import NotificationErrorIcon from '../Icons/NotificationErrorIcon';
import { FeedbackWrapper, InputBaseWrapper, InputLabelWrapper } from '../TextInput/TextInputStyle';
import { DropdownProps } from './DropdownModels';
import MenuItemWrapper from './DropdownStyle';

function Dropdown({
  value = '',
  items,
  placeholder,
  label,
  subText,
  feedback,
  formStyle,
  isPlaceholderDisabled = true,
  ...rest
}: DropdownProps) {
  return (
    <FormControl sx={{ width: '100%', ...formStyle }}>
      {label ? (
        <InputLabelWrapper shrink disabled={rest.disabled}>
          {label}
        </InputLabelWrapper>
      ) : null}
      <Select value={value} {...rest} input={<InputBaseWrapper />} displayEmpty>
        {placeholder ? (
          <MenuItemWrapper disabled={isPlaceholderDisabled} value="">
            {placeholder}
          </MenuItemWrapper>
        ) : null}
        {items.map((item) => (
          <MenuItemWrapper value={item.value} key={item.key}>
            {item.label}
          </MenuItemWrapper>
        ))}
      </Select>
      {feedback ? (
        <FeedbackWrapper error={rest.error} component="span">
          {rest.error ? (
            <Box sx={{ paddingRight: '0.25rem' }}>
              <NotificationErrorIcon />
            </Box>
          ) : null}
          {feedback}
        </FeedbackWrapper>
      ) : null}
    </FormControl>
  );
}

export default memo(Dropdown);
