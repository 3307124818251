import { Typography, TypographyProps } from '@mui/material';
import { PropsWithChildren } from 'react';

interface PageTitleProps extends PropsWithChildren, TypographyProps {}
function PageTitle({ children, ...rest }: PageTitleProps) {
  return (
    <Typography fontSize="2.5rem" lineHeight="normal" padding="1rem 0rem" {...rest}>
      {children}
    </Typography>
  );
}

export default PageTitle;
