import { Box, Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UserDetailsContext from '../../../../contexts/UserDetailsContext/UserDetailsContext';

export const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderBottom: `1px solid${theme.palette.disabled.main}`,
  padding: '0.5rem',
  minHeight: '4rem',
  alignItems: 'center',
  fontSize: theme.typography.body1.fontSize,
  lineHeight: 'normal',
  '& .label': {
    color: theme.palette.text.disabled,
  },
}));

function UserDetailsRows() {
  const [t] = useTranslation();
  const user = useContext(UserDetailsContext);
  return (
    <Grid container sx={{ paddingTop: '1.5rem' }}>
      <Grid item xl={6} md={12} xs={12}>
        <Row>
          <Grid container rowSpacing="0.5rem">
            <Grid item xl={3} md={4} xs={12} className="label">
              {t('pages.userDetails.firstName')}
            </Grid>
            <Grid item xl={9} md={8} xs={12}>
              {user ? user?.firstName : <Skeleton width="50%" />}
            </Grid>
          </Grid>
        </Row>
        <Row>
          <Grid container rowSpacing="0.5rem">
            <Grid item xl={3} md={4} xs={12} className="label">
              {t('pages.userDetails.lastName')}
            </Grid>
            <Grid item xl={9} md={8} xs={12}>
              {user ? user?.lastName : <Skeleton width="50%" />}
            </Grid>
          </Grid>
        </Row>
        <Row>
          <Grid container rowSpacing="0.5rem">
            <Grid item xl={3} md={4} xs={12} className="label">
              {t('pages.userDetails.emailAddress')}
            </Grid>
            <Grid item xl={9} md={8} xs={12}>
              {user ? user?.email : <Skeleton width="50%" />}
            </Grid>
          </Grid>
        </Row>
        <Row>
          <Grid container rowSpacing="0.5rem">
            <Grid item xl={3} md={4} xs={12} className="label">
              {t('pages.userDetails.role')}
            </Grid>
            <Grid item xl={9} md={8} xs={12}>
              {user ? <span>{user.companyRole ? user.companyRole : 'n.a.'}</span> : <Skeleton width="50%" />}
            </Grid>
          </Grid>
        </Row>
      </Grid>
    </Grid>
  );
}

export default memo(UserDetailsRows);
