import { styled } from '@mui/material/styles';

export const SearchInputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  Button: {
    minWidth: '3.5rem',
    padding: '0.37rem',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    alignSelf: 'end',
    '&:disabled': {
      borderTop: `1px solid ${theme.palette.monochrome.monoChrome3}`,
      borderRight: `1px solid ${theme.palette.monochrome.monoChrome3}`,
      borderBottom: `1px solid ${theme.palette.monochrome.monoChrome3}`,
    },
  },
}));

export const CloseIconContainer = styled('div')(() => ({
  position: 'absolute',
  right: '4rem',
  bottom: '1rem',
}));
