import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import { ButtonProps } from '../Button/ButtonModels';

export const FooterContainer = styled(Box)<BoxProps>(({ theme }) => ({
  padding: '2.5rem 3rem',
  minHeight: '30vh',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  background: theme.palette.monochrome.monoChrome12,
}));

export const FooterDrawer = styled(Box)<BoxProps>(({ theme }) => ({
  borderTop: `3px solid ${theme.palette.primary.main}`,
  padding: '2rem 0rem',
  marginTop: '3rem',
  display: 'flex',
  gap: '3rem',
}));

export const LinksContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

export const FooterLink1 = styled(Link)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
    textDecorationColor: theme.palette.state.linkHover,
    color: theme.palette.monochrome.monoChrome13,
  },
  textDecorationColor: theme.palette.text.primary,
  color: theme.palette.monochrome.monoChrome13,
  fontSize: '1rem',
  lineHeight: 'normal',
  textDecoration: 'none',
}));

export const FooterLink2 = styled(Link)(({ theme }) => ({
  fontStyle: 'normal',
  textDecorationColor: theme.palette.text.primary,
  color: theme.palette.monochrome.monoChrome13,
  fontSize: '1.125rem',
  lineHeight: '1.625rem',
  textDecoration: 'none',
  '&:hover': {
    cursor: 'pointer',
    textDecorationColor: theme.palette.state.linkHover,
    color: theme.palette.secondary.contrastText,
  },
}));

export const FooterText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.monochrome.monoChrome13,
  fontSize: '1rem',
  lineHeight: 'normal',
}));

export const FooterButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.monochrome.monoChrome13,
  fontSize: '1rem',
  lineHeight: 'normal',
  padding: '0.75rem 1.25rem',
  backgroundColor: `${theme.palette.text.primary} !important`,
}));
