function useLogger() {
  const isLoggerEnabled = import.meta.env.VITE_SHOW_LOGS === 'true';

  /**
   * Console log
   * @param message
   */
  const log = (message: unknown) => {
    if (isLoggerEnabled) {
      // eslint-disable-next-line no-console
      console.log(message);
    }
  };

  /**
   * Console warn
   * @param message
   */
  const logWarn = (message: unknown) => {
    if (isLoggerEnabled) {
      // eslint-disable-next-line no-console
      console.warn(message);
    }
  };

  /**
   * Console error
   * @param message
   */
  const logError = (message: unknown) => {
    if (isLoggerEnabled) {
      // eslint-disable-next-line no-console
      console.error(message);
    }
  };

  /**
   * Console info
   * @param message
   */
  const logInfo = (message: unknown) => {
    if (isLoggerEnabled) {
      // eslint-disable-next-line no-console
      console.info(message);
    }
  };

  return { log, logWarn, logError, logInfo };
}

export default useLogger;
