import * as React from 'react';
import { memo, SVGProps } from 'react';

function SearchIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        stroke={props?.stroke ? props?.stroke : '#333'}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.449 14.449 21.5 21.5m-12-5a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"
      />
    </svg>
  );
}
export default memo(SearchIcon);
