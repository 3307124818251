import {
  FormControlLabel,
  Checkbox as MaterialCheckbox,
  CheckboxProps as MaterialCheckboxProps,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

interface CheckboxProps extends MaterialCheckboxProps {
  label: string | ReactNode;
}
function Checkbox({ label, ...rest }: CheckboxProps) {
  const theme = useTheme();
  return (
    <FormControlLabel
      control={<MaterialCheckbox disableRipple color="primaryCheckbox" {...rest} />}
      label={label}
      sx={{
        paddingBottom: '0.5rem',
        '& .MuiButtonBase-root': {
          flexShrink: 0,
          padding: '4px 9px 4px 9px',
        },
        '& .MuiFormControlLabel-label': {
          fontSize: '1.125rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '1.6875rem',
          color: theme.palette.monochrome.monoChrome6,
        },
        ...rest.sx,
      }}
    />
  );
}

export default Checkbox;
