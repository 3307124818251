import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Row } from '../../../../components/Table/TableModels';
import ClickableLink from '../../../../components/Text/ClickableLink';
import UserDetailsContext from '../../../../contexts/UserDetailsContext/UserDetailsContext';
import { Sort } from '../../../../models/CommonModels';
import { Device } from '../../../../models/UserDevicesModels';

function useUserDetailsDevicesTable(
  devices: Device[],
  sort: Sort,
  resendNotification: (deviceId: string) => void,
  isPerformingAction: boolean,
) {
  const [t] = useTranslation();
  const user = useContext(UserDetailsContext);

  /**
   * Sorts the array of devices
   */
  const sortBy = useCallback(
    (devs: Device[]): Device[] => {
      if (devs.length) {
        if (!Object.keys(devs[0]).includes(sort.orderBy)) {
          throw new Error(`Invalid field name: ${sort.orderBy}`);
        }
        return devs.sort((a, b) => {
          let comparison: number;
          try {
            comparison = a[sort.orderBy as keyof Device].localeCompare(b[sort.orderBy as keyof Device]);
          } catch (e) {
            comparison = 1;
          }
          return sort.order === 'desc' ? -comparison : comparison;
        });
      }
      return [];
    },
    [sort.order, sort.orderBy],
  );

  const headers: Header[] = useMemo(
    () => [
      {
        id: 'manufacturer',
        label: t('pages.userDetails.tabs.devicesTable.headers.manufacturer'),
        isSortable: true,
      },
      {
        id: 'model',
        label: t('pages.userDetails.tabs.devicesTable.headers.device'),
        isSortable: true,
      },
      {
        id: 'eid',
        label: t('pages.userDetails.tabs.devicesTable.headers.simId'),
        isSortable: true,
      },
      {
        id: 'phoneNumber',
        label: t('pages.userDetails.tabs.devicesTable.headers.mobileNumber'),
      },
      {
        id: 'simType',
        label: t('pages.userDetails.tabs.devicesTable.headers.simType'),
      },
      {
        id: 'deviceType',
        label: t('pages.userDetails.tabs.devicesTable.headers.deviceType'),
        isSortable: true,
      },
      {
        id: 'details',
        label: '',
      },
    ],
    [t],
  );

  const rows: Row[] = useMemo(
    () =>
      sortBy(devices).map((d) => ({
        id: d.id,
        cells: [
          {
            value: d.manufacturer,
          },
          {
            value: d.model,
          },
          {
            value: d.simId,
          },
          {
            value: d.phoneNumber,
          },
          {
            value: d.simType ? t(`common.simType.${d.simType}`) : t('common.na'),
          },
          {
            value: d.deviceType ? t(`common.deviceType.${d.deviceType}`) : t('common.na'),
          },
          {
            value: (
              <ClickableLink
                to={`/esimManager/userDetails/${user?.id}/deviceDetails/${d.id}?userName=${user?.firstName} ${user?.lastName}`}
              >
                {t('common.table.viewDetails')}
              </ClickableLink>
            ),
          },
        ],
      })),
    [devices, sortBy, t, isPerformingAction, user],
  );

  return { headers, rows, totalDevices: devices.length };
}

export default useUserDetailsDevicesTable;
