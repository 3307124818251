import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ClickableIcon from '../../../components/Icons/ClickableIcon';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import useModal from '../../../hooks/useModal/useModal';

interface RemoveAdminIconProps {
  id: string;
  deleteAdmin: (id: string) => void;
}
function RemoveAdminIcon({ id, deleteAdmin }: RemoveAdminIconProps) {
  const modal = useModal();
  const [t] = useTranslation();
  /**
   * Displays the confirmation modal
   */
  const showConfirmationModal = () => {
    modal.showModal({
      modalTitle: t('pages.adminOnboarding.deleteAdmin'),
      modalDescription: (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <span>{t('pages.adminOnboarding.deleteAdminMessage1')}</span>
          <span>{t('pages.adminOnboarding.deleteAdminMessage2')}</span>
        </Box>
      ),
      confirmationModal: true,
      confirmationButtonLabel: t('common.delete'),
      onAccept: (setOpen) => {
        setOpen(false);
        deleteAdmin(id);
      },
    });
  };

  return (
    <ClickableIcon onClick={showConfirmationModal} sx={{ display: 'flex', flexShrink: 0 }}>
      <DeleteIcon />
    </ClickableIcon>
  );
}

export default RemoveAdminIcon;
