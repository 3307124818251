import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PageTitle from '../../../components/Text/PageTitle';
import AdminOnboardingForm from './components/AdminOnboardingForm';

function AdminOnboarding() {
  const [t] = useTranslation();
  const { id } = useParams();
  return (
    <>
      {id ? (
        <PageTitle>{t('pages.adminOnboarding.titleEdit')}</PageTitle>
      ) : (
        <PageTitle>{t('pages.adminOnboarding.title')}</PageTitle>
      )}
      <AdminOnboardingForm />
    </>
  );
}

export default AdminOnboarding;
