import * as React from 'react';
import { SVGProps, memo } from 'react';

function DeleteIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#333"
        fillRule="evenodd"
        d="M8.625 4.5A2.375 2.375 0 0 1 11 2.125h2A2.375 2.375 0 0 1 15.375 4.5v.625H19.5a.375.375 0 0 1 0 .75h-.625V19.5a2.375 2.375 0 0 1-2.375 2.375h-9A2.375 2.375 0 0 1 5.125 19.5V5.875H4.5a.375.375 0 0 1 0-.75h4.125V4.5Zm-2.75 1.375V19.5c0 .898.727 1.625 1.625 1.625h9c.897 0 1.625-.727 1.625-1.625V5.875H5.875Zm8.75-1.375v.625h-5.25V4.5c0-.897.727-1.625 1.625-1.625h2c.898 0 1.625.728 1.625 1.625Zm-4.624 4.125c.207 0 .375.168.375.375v9a.375.375 0 0 1-.75 0V9c0-.207.168-.375.375-.375Zm4 0c.207 0 .375.168.375.375v9a.375.375 0 0 1-.75 0V9c0-.207.168-.375.375-.375Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default memo(DeleteIcon);
