import { Tabs as T } from '@mui/material';
import React, { memo } from 'react';
import Tab from './TabsStyles';

interface TabsProps {
  tabs: string[];
  handleTabChange: (tab: number) => void;
}
function Tabs({ tabs, handleTabChange }: TabsProps) {
  const [tabNumber, setTabNumber] = React.useState(0);

  /**
   * Handle tab click
   * @param event
   * @param newTabNumber
   */
  const handleChange = (event: React.SyntheticEvent, newTabNumber: number) => {
    setTabNumber(newTabNumber);
    handleTabChange(newTabNumber);
  };

  return (
    <T value={tabNumber} onChange={handleChange}>
      {tabs.map((tab) => (
        <Tab label={tab} key={tab} disableRipple />
      ))}
    </T>
  );
}

export default memo(Tabs);
