import * as React from 'react';
import { SVGProps, memo } from 'react';

function UploadIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
        <path d="M14.794 15.5s2.805.107 3.898-.375a4.78 4.78 0 0 0 2.785-3.875c.164-1.636-.582-3.434-1.992-4.304-.701-.431-1.52-.606-2.339-.677a9.862 9.862 0 0 0-1.399.062 5.46 5.46 0 0 0-4.74-3.81A5.328 5.328 0 0 0 6.543 4.41 5.5 5.5 0 0 0 5.429 6.59c-.054.213-.16.805-.16.805a4.197 4.197 0 0 0-1.484.842C2.471 9.413 2.151 11.42 2.886 13c.668 1.435 2.101 2.5 3.696 2.5h2.625M12 9.531v11.896" />
        <path d="m8.75 13.031 3.25-3.5 3.25 3.5" />
      </g>
    </svg>
  );
}
export default memo(UploadIcon);
