import { Container, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import useGoBack from '../../hooks/useGoBack/useGoBack';

function NotFoundRoute() {
  const [t] = useTranslation();
  const { goBack } = useGoBack();
  const theme = useTheme();
  return (
    <Container maxWidth="md" sx={{ display: 'flex', paddingTop: '4rem' }}>
      <Grid container>
        <Grid
          item
          xl={6}
          xs={12}
          sx={{
            display: 'flex',
            borderRight: {
              md: `1px solid ${theme.palette.text.disabled}`,
            },
            padding: '2rem',
            flexShrink: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '4rem',
              textTransform: 'none',
              lineHeight: 'normal',
              fontFamily: 'Vodafone RgBd',
              color: theme.palette.primary.main,
            }}
          >
            {t('pages.errors.404Route.title')}
          </Typography>
        </Grid>
        <Grid
          item
          xl={6}
          xs={12}
          sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem', flexShrink: 1 }}
        >
          <Typography sx={{ fontWeight: 'normal', fontSize: '2rem' }}>{t('pages.errors.404Route.message1')}</Typography>
          <Typography sx={{ fontFamily: 'Vodafone Lt' }}>{t('pages.errors.404Route.message2')}</Typography>
          <Button color="primary" label={t('common.goBack')} onClick={() => goBack()} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default NotFoundRoute;
