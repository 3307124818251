import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../Dropdown/Dropdown';
import { ELLIPSIS, TablePaginationProps } from './TablePaginationModels';
import { ArrowLeft, ArrowRight, Ellipsis, TablePaginationItem } from './TablePaginationStyle';
import useTablePagination from './useTablePagination';

function TablePagination({ handlePageChange, totalItems, activePage, pageSize }: TablePaginationProps) {
  const [t] = useTranslation();
  const paginationItems = useTablePagination({ totalItems, pageSize, activePage });
  const lastPage = paginationItems[paginationItems.length - 1];
  const pageSizeOptions = useMemo(() => [5, 10, 25, 50, 100].map((n) => ({ key: n, label: n, value: n })), []);

  /**
   * On page size change handler
   * @param val
   */
  const onPageSizeChange = (val: string | number) => {
    handlePageChange(1, Number(val));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        paddingTop: '1rem',
        gap: '1rem',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ flexShrink: 0, paddingRight: '1rem' }} variant="body1">
          {t('common.table.rowsPerPage')}
        </Typography>
        <Dropdown
          value={pageSize}
          onChange={(e) => onPageSizeChange(e.target.value as string | number)}
          items={pageSizeOptions}
          sx={{ minWidth: '7.5rem', '& .MuiSelect-select': { maxHeight: '2.5rem' } }}
        />
      </Box>
      {activePage === 0 || paginationItems.length < 2 ? null : (
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <TablePaginationItem
            className="arrow"
            label={<ArrowLeft />}
            onClick={() => handlePageChange(activePage - 1, pageSize)}
            disabled={activePage === 1}
          />
          {paginationItems.map((pageNumber, idx) => {
            if (pageNumber === ELLIPSIS) {
              return <TablePaginationItem disabled key={`page-${idx}`} label={<Ellipsis />} />;
            }
            return (
              <TablePaginationItem
                key={`page-${idx}`}
                className={`${pageNumber === activePage ? 'active' : ''}`}
                onClick={() => handlePageChange(Number(pageNumber), pageSize)}
                label={`${pageNumber}`}
              />
            );
          })}
          <TablePaginationItem
            className="arrow"
            key={`page-${lastPage}`}
            label={<ArrowRight />}
            onClick={() => handlePageChange(activePage + 1, pageSize)}
            disabled={activePage === lastPage}
          />
        </Box>
      )}
    </Box>
  );
}

export default TablePagination;
