import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PageTitle from '../../../components/Text/PageTitle';
import UemOnboardingForm from './components/UemOnboardingForm';

function UemOnboarding() {
  const [t] = useTranslation();
  const { id } = useParams();
  return (
    <>
      {id ? (
        <PageTitle>{t('pages.uemOnboarding.titleEdit')}</PageTitle>
      ) : (
        <PageTitle>{t('pages.uemOnboarding.title')}</PageTitle>
      )}
      <UemOnboardingForm />
    </>
  );
}

export default UemOnboarding;
