import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PageTitle from '../../../components/Text/PageTitle';
import useRolePermissions from '../../../hooks/useRolePermissions/useRolePermissions';
import { FeaturePermissions } from '../../../models/AuthModels';
import CompanyOnboardingForm from './components/CompanyOnboardingForm';

function CompanyOnboarding() {
  const [t] = useTranslation();
  const { id } = useParams();
  const { isUserAllowed } = useRolePermissions();
  const editMode = isUserAllowed(FeaturePermissions.companiesEdit);
  return (
    <>
      {id ? (
        <div>
          {editMode ? (
            <PageTitle>{t('translation:pages.companyOnboarding.titleEdit')}</PageTitle>
          ) : (
            <PageTitle>{t('translation:pages.companyOnboarding.titleView')}</PageTitle>
          )}
        </div>
      ) : (
        <PageTitle>{t('pages.companyOnboarding.title')}</PageTitle>
      )}
      <CompanyOnboardingForm editMode={editMode} />
    </>
  );
}

export default CompanyOnboarding;
