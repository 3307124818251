import { Box } from '@mui/material';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table/Table';
import useCompaniesOnboardingBansTable from './useCompaniesOnboardingBansTable';

interface CompanyOnboardingBansTableProps {
  formikObj: FormikProps<any>;
  editMode: boolean;
}
function CompanyOnboardingBansTable({ formikObj, editMode }: CompanyOnboardingBansTableProps) {
  const { headers, rows } = useCompaniesOnboardingBansTable(formikObj, editMode);
  const [t] = useTranslation();

  return (
    <Box paddingBottom="3rem">
      <Table headers={headers} rows={rows} emptyStateMessage={t('translation:pages.companyOnboarding.emptyBans')} />
    </Box>
  );
}

export default CompanyOnboardingBansTable;
