import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import {
  AdminCreateResponse,
  AdminOnboardingRequest,
  AdminResponse,
  AdminsListRequest,
  AdminsListResponse,
  AdminUpdateRequest,
  AvailableRolesResponse,
} from '../../models/AdminOnboardingModels';

const AdminOnboardingService = {
  api: {
    createAdmin: (params: AdminOnboardingRequest): AxiosPromise<AdminCreateResponse> =>
      Api.post(`/administrators/create`, params),
    fetchAllRoles: (): AxiosPromise<AvailableRolesResponse> => Api.get(`/administrators/roles`, {}),
    fetchAdminRoles: (id: string): AxiosPromise<AvailableRolesResponse> => Api.get(`/administrators/${id}/roles`, {}),
    fetchAdmins: (params: AdminsListRequest): AxiosPromise<AdminsListResponse> =>
      Api.post(`/administrators/list`, params),
    fetchAdmin: (id: string): AxiosPromise<AdminResponse> => Api.get(`/administrators/${id}`),
    deleteAdmin: (id: string): AxiosPromise<AdminResponse> => Api.delete(`/administrators/${id}`),
    editAdmin: (params: AdminUpdateRequest): AxiosPromise<AdminCreateResponse> =>
      Api.post('administrators/update', params),
  },
};

export default AdminOnboardingService;
