import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CalendarIcon from '../../components/Icons/CalendarIcon';
import SecurityIcon from '../../components/Icons/SecurityIcon';
import ClickableLinkWithIcon from '../../components/Text/ClickableLinkWithIcon';
import PageTitle from '../../components/Text/PageTitle';
import useAuth from '../../hooks/useAuth/useAuth';
import useRolePermissions from '../../hooks/useRolePermissions/useRolePermissions';
import { FeaturePermissions } from '../../models/AuthModels';
import DashboardCards from './components/DashboardCards';

function Dashboard() {
  const [t] = useTranslation();
  const { userData } = useAuth();
  const date = new Date();
  const formattedDate = date.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: '2-digit' });
  const navigate = useNavigate();
  const { isUserAllowed } = useRolePermissions();

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PageTitle>{t('pages.dashboard.title')}</PageTitle>
          <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
            <CalendarIcon />
            <Typography sx={{ paddingLeft: '0.5rem', fontSize: '1.375rem', fontHeight: 'normal' }}>
              {t('pages.dashboard.today')}, {formattedDate}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', paddingTop: '0.5rem' }}>
          <Typography sx={{ fontSize: '1.5rem', lineHeight: 'normal' }}>
            {t('pages.dashboard.welcome')} {userData?.firstName}
          </Typography>
          {isUserAllowed(FeaturePermissions.auditTrail) ? (
            <Box sx={{ marginLeft: 'auto' }}>
              <ClickableLinkWithIcon
                onClick={() => {
                  navigate('/dashboard/auditTrail');
                }}
                sx={{ fontSize: '1.25rem', lineHeight: '1.75rem' }}
                rightIcon={<SecurityIcon />}
              >
                {t('pages.dashboard.auditTrail')}
              </ClickableLinkWithIcon>
            </Box>
          ) : null}
        </Box>
      </Box>
      <DashboardCards />
    </>
  );
}

export default Dashboard;
