import { Grid } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../../components/SearchInput/SearchInput';

interface ESimManagerTableFiltersProps {
  onSearch: (query: string) => void;
  isLoading: boolean;
}

function ESimManagerTableFilters({ onSearch, isLoading = false }: ESimManagerTableFiltersProps) {
  const [t] = useTranslation();
  return (
    <Grid container columnSpacing="1.5rem" sx={{ paddingTop: '1.5rem', paddingBottom: '1rem' }}>
      <Grid item xl={5} md={4} xs={12}>
        <SearchInput
          label={t('pages.eSimManager.table.filters.search')}
          onSearch={onSearch}
          placeholder={t('pages.eSimManager.table.filters.searchPlaceholder')}
          isButtonDisabled={isLoading}
        />
      </Grid>
    </Grid>
  );
}

export default ESimManagerTableFilters;
