import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Row } from '../../components/Table/TableModels';
import ClickableLink from '../../components/Text/ClickableLink';
import useHttpError from '../../hooks/useHttpError/useHttpError';
import useModal from '../../hooks/useModal/useModal';
import useRolePermissions from '../../hooks/useRolePermissions/useRolePermissions';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { FeaturePermissions } from '../../models/AuthModels';
import { Sort } from '../../models/CommonModels';
import { UemListEntry, UemListRequest } from '../../models/UemOnboardingModels';
import UemOnboardingService from '../../services/UemOnboarding/UemOnboardingService';
import { UemFilter } from './UemsTableFilters';

function useUemsTable(activePage: number, pageSize: number, filter: UemFilter, sort: Sort) {
  const [t] = useTranslation();
  const { isUserAllowed } = useRolePermissions();
  const [totalUems, setTotalUems] = useState(0);
  const [columns, setColumns] = useState<string[]>([]);
  const [uems, setUems] = useState<UemListEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();
  const { getHttpReason } = useHttpError();
  const modal = useModal();

  /**
   * Defines the dynamic columns of the table
   * @param list
   */
  const defineUemColumns = (list: UemListEntry[]) => {
    if (list.length) {
      const propertiesExcluded = ['type', 'message', 'code', 'companyId', 'id'];
      const uemProperties = Object.getOwnPropertyNames(list[0]);
      setColumns(uemProperties.filter((prop) => !propertiesExcluded.includes(prop)));
    }
  };

  /**
   * Fetch companies request
   */
  const fetchUems = () => {
    if (!filter.type) return;
    setIsLoading(true);
    const requestParams: UemListRequest = {
      page: activePage - 1,
      pageSize,
      sort: sort.orderBy,
      sortingType: sort.order,
      ...filter,
    };
    UemOnboardingService.api.fetchUems(requestParams).then(
      (response) => {
        defineUemColumns(response.data.uemCredentialsList);
        setUems(response.data.uemCredentialsList);
        setTotalUems(response.data.totalElements);
        setIsLoading(false);
      },
      () => {
        snackBar.showSnackBar(t('pages.uemOnboarding.fetchUemsError'), 'error');
        setIsLoading(false);
      },
    );
  };

  /**
   * Delete UEM
   * @param id
   * @param setOpen
   */
  const deleteUem = (id: string, setOpen: (value: boolean | ((prevState: boolean) => boolean)) => void) => {
    UemOnboardingService.api.deleteUem(id).then(
      () => {
        setOpen(false);
        snackBar.showSnackBar(t('pages.uemOnboarding.deleteUemsSuccess'), 'success');
        fetchUems();
      },
      (res) => {
        setOpen(false);
        modal.showModal({
          modalTitle: t('pages.uemOnboarding.deleteUemsErrorTitle'),
          modalDescription: (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <span>{t('pages.uemOnboarding.deleteUemsError')}</span>
              <span>{getHttpReason(res.response.data, '')}</span>
            </Box>
          ),
          onAccept: (setErrorModalOpen) => {
            setErrorModalOpen(false);
          },
        });
        setIsLoading(false);
      },
    );
  };

  React.useEffect(() => {
    fetchUems();
  }, [activePage, pageSize, filter, sort]);

  /**
   * Gets the dynamic columns cells for each row
   * @param uem
   */
  const getDynamicCells = (uem: UemListEntry) =>
    columns.map((c) => ({
      value:
        c === 'authentication'
          ? t(`pages.uemOnboarding.authenticationTypes.${uem[c as keyof UemListEntry]}` as string)
          : (uem[c as keyof UemListEntry] as string),
    }));

  const headers: Header[] = useMemo(() => {
    const dynamicHeaders = columns.map((c) => ({
      id: c,
      label: t(`translation:pages.uemOnboarding.table.headers.${c}`),
      isSortable: true,
    }));

    return [
      {
        id: 'type',
        label: t('translation:pages.uemOnboarding.table.headers.type'),
        isSortable: true,
      },
      ...dynamicHeaders,
      {
        id: 'editInfo',
        label: '',
      },
      /* {
        id: 'deleteUems',
        label: '',
      }, */
    ];
  }, [t, columns]);

  const rows: Row[] = useMemo(
    () =>
      uems?.map((uem) => ({
        id: uem.id,
        cells: [
          {
            value: t(`pages.uemOnboarding.uemTypes.${uem.type}`),
          },
          ...getDynamicCells(uem),
          {
            value: (
              <div>
                {isUserAllowed(FeaturePermissions.uemCreate) ? (
                  <ClickableLink to={`/uemOnboarding/edit/${uem.id}`}>{t('common.table.editInfo')}</ClickableLink>
                ) : null}
              </div>
            ),
          },
          /* {
            value: <RemoveUemIcon id={uem.id} deleteUem={deleteUem} />,
          }, */
        ],
      })),
    [t, uems],
  );

  return { headers, rows, totalUems, isLoading };
}

export default useUemsTable;
