import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../Icons/CloseIcon';
import ClickableLink from '../Text/ClickableLink';

const CloseIconContainer = styled('div')(() => ({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translate(-50%, -50%) scale(1.3333)',
}));

function TopBanner() {
  const theme = useTheme();
  const [t] = useTranslation();
  const BANNER_ID = 'SHOW_PRIVACY_POLICY_BANNER';
  const [isVisible, setIsVisible] = useState(localStorage.getItem(BANNER_ID) !== 'false');

  /**
   * Close button handler
   */
  const closeBanner = () => {
    localStorage.setItem(BANNER_ID, String(false));
    setIsVisible(false);
  };

  return (
    <div>
      {isVisible ? (
        <Box
          sx={{
            position: 'relative',
            padding: '1rem 3rem',
            backgroundColor: theme.palette.monochrome.monoChrome7,
            textAlign: 'center',
          }}
        >
          <Typography sx={{ lineHeight: '1.625rem', color: theme.palette.monochrome.monoChrome1 }}>
            {t('topBanner.message1')}
            <ClickableLink
              sx={{
                color: theme.palette.monochrome.monoChrome1,
                textDecorationColor: theme.palette.monochrome.monoChrome1,
              }}
              target="_blank"
              to="/privacypolicy"
            >
              {t('topBanner.here')}
            </ClickableLink>
            .
          </Typography>
          <Typography sx={{ lineHeight: '1.625rem', color: theme.palette.monochrome.monoChrome1 }}>
            {t('topBanner.message2')}
            <ClickableLink
              sx={{
                color: theme.palette.monochrome.monoChrome1,
                textDecorationColor: theme.palette.monochrome.monoChrome1,
              }}
              to="https://www.allaboutcookies.org/"
            >
              https://www.allaboutcookies.org/
            </ClickableLink>
          </Typography>
          <CloseIconContainer>
            <CloseIcon cursor="pointer" onClick={closeBanner} stroke={theme.palette.monochrome.monoChrome1} />
          </CloseIconContainer>
        </Box>
      ) : null}
    </div>
  );
}

export default memo(TopBanner);
