import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Row } from '../../components/Table/TableModels';
import ClickableLink from '../../components/Text/ClickableLink';
import useHttpError from '../../hooks/useHttpError/useHttpError';
import useModal from '../../hooks/useModal/useModal';
import useRolePermissions from '../../hooks/useRolePermissions/useRolePermissions';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { FeaturePermissions } from '../../models/AuthModels';
import { Sort } from '../../models/CommonModels';
import { CompaniesListRequest, Company } from '../../models/CompanyOnboardingModels';
import CompanyOnboardingService from '../../services/CompanyOnboarding/CompanyOnboardingService';
import { CompanyFilter } from './CompaniesTableFilters';
import RemoveCompanyIcon from './components/RemoveCompanyIcon';

function useCompaniesTable(activePage: number, pageSize: number, filter: CompanyFilter, sort: Sort) {
  const [t] = useTranslation();
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();
  const { getHttpReason } = useHttpError();
  const modal = useModal();
  const { isUserAllowed } = useRolePermissions();

  /**
   * Fetch companies request
   */
  const fetchCompanies = () => {
    setIsLoading(true);
    const requestParams: CompaniesListRequest = {
      page: activePage - 1,
      pageSize,
      sort: sort.orderBy,
      sortingType: sort.order,
      ...filter,
    };
    CompanyOnboardingService.api.fetchCompanies(requestParams).then(
      (response) => {
        setCompanies(response.data.companies);
        setTotalCompanies(response.data.totalElements);
        setIsLoading(false);
      },
      () => {
        snackBar.showSnackBar(t('pages.companyOnboarding.fetchCompaniesError'), 'error');
        setIsLoading(false);
      },
    );
  };

  /**
   * Delete company
   * @param id
   * @param setOpen
   * @param setModalIsLoading
   */
  const deleteCompany = (
    id: string,
    setOpen: (value: boolean | ((prevState: boolean) => boolean)) => void,
    setModalIsLoading: (isLoading: boolean) => void,
  ) => {
    setModalIsLoading(true);
    CompanyOnboardingService.api.deleteCompany(id).then(
      () => {
        setModalIsLoading(false);
        setOpen(false);
        snackBar.showSnackBar(t('pages.companyOnboarding.deleteCompanySuccess'), 'success');
        fetchCompanies();
      },
      (res) => {
        setModalIsLoading(false);
        setOpen(false);
        modal.showModal({
          modalTitle: t('pages.companyOnboarding.deleteCompanyErrorTitle'),
          modalDescription: (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <span>{t('pages.companyOnboarding.deleteCompanyError')}</span>
              <span>{getHttpReason(res.response.data, '')}</span>
            </Box>
          ),
          onAccept: (setErrorModalOpen) => {
            setErrorModalOpen(false);
          },
        });
        setIsLoading(false);
      },
    );
  };

  React.useEffect(() => {
    fetchCompanies();
  }, [activePage, pageSize, filter, sort]);

  const headers: Header[] = useMemo(
    () => [
      {
        id: 'name',
        label: t('translation:pages.companyOnboarding.table.headers.name'),
        isSortable: true,
      },

      {
        id: 'companyCode',
        label: t('pages.companyOnboarding.table.headers.companyCode'),
        isSortable: true,
      },
      {
        id: 'country',
        label: t('pages.companyOnboarding.table.headers.country'),
        isSortable: true,
      },
      {
        id: 'market',
        label: t('pages.companyOnboarding.table.headers.market'),
        isSortable: true,
      },
      {
        id: 'editInfo',
        label: '',
      },
      {
        id: 'deleteCompany',
        label: '',
      },
    ],
    [t],
  );

  const rows: Row[] = useMemo(
    () =>
      companies?.map((company) => ({
        id: company.id,
        cells: [
          {
            value: company.name,
          },
          {
            value: company.companyCode,
          },
          {
            value: company.country,
          },
          {
            value: company.market,
          },
          {
            value: (
              <div>
                {isUserAllowed(FeaturePermissions.companiesList) ? (
                  <ClickableLink to={`/companies/edit/${company.id}`}>{t('common.table.viewDetails')}</ClickableLink>
                ) : null}
              </div>
            ),
          },
          {
            value: (
              <Box sx={{ display: 'flex', minWidth: '25px', justifyContent: 'end' }}>
                {isUserAllowed(FeaturePermissions.companiesCreate) ? (
                  <RemoveCompanyIcon id={company.id} deleteCompany={deleteCompany} companyName={company.name} />
                ) : null}
              </Box>
            ),
          },
        ],
      })),
    [t, companies],
  );

  return { headers, rows, totalCompanies, isLoading };
}

export default useCompaniesTable;
