import * as React from 'react';
import { useState } from 'react';
import Button from '../Button/Button';
import ClickableIcon from '../Icons/ClickableIcon';
import CloseIcon from '../Icons/CloseIcon';
import SearchIcon from '../Icons/SearchIcon';
import TextInput from '../TextInput/TextInput';
import { TextInputProps } from '../TextInput/TextInputModels';
import { CloseIconContainer, SearchInputContainer } from './SearchInputStyles';

interface SearchInputProps extends Omit<TextInputProps, 'leftIcon' | 'onInputChange'> {
  onSearch: (query: string) => void;
  rightIcon?: JSX.Element;
  isButtonDisabled?: boolean;
}

function SearchInput({
  value = '',
  label,
  placeholder,
  onSearch,
  rightIcon = <SearchIcon stroke="#fff" />,
  isButtonDisabled = false,
}: SearchInputProps) {
  const [val, setVal] = useState<string>(`${value}`);

  /**
   * Detects the enter key
   * @param event
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(val);
    }
  };

  /**
   * On close button click handler
   * Cleans the search input and performs a search
   */
  const onClose = () => {
    setVal('');
    onSearch('');
  };

  return (
    <SearchInputContainer>
      <TextInput
        value={val}
        label={label}
        onKeyDown={(e) => handleKeyDown(e)}
        onInputChange={(e) => setVal(e.target.value)}
        placeholder={placeholder}
        sx={{ '& .MuiInputBase-input': { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
      />
      {val ? (
        <CloseIconContainer>
          <ClickableIcon onClick={onClose}>
            <CloseIcon />{' '}
          </ClickableIcon>
        </CloseIconContainer>
      ) : null}
      <Button label={rightIcon} onClick={() => onSearch(val)} disabled={isButtonDisabled} />
    </SearchInputContainer>
  );
}
export default SearchInput;
