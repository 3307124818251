import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSnackBar from '../../../../hooks/useSnackBar/useSnackBar';
import { Device, GetDeviceByIdFn, Sim } from '../../../../models/UserDevicesModels';
import UsersService from '../../../../services/Users/UsersService';

function useUserDetailsTabs() {
  const { id } = useParams();
  const [devices, setDevices] = useState<Device[]>([]);
  const [sims, setSims] = useState<Sim[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const snackBar = useSnackBar();
  const [t] = useTranslation();

  /**
   * Fetches the devices and sims of the current user
   */
  const fetchUserDevices = () => {
    if (id) {
      setIsLoading(true);
      UsersService.api
        .fetchUserDevices(id, { status: 'ALL', deviceOnly: false })
        .then(
          (res) => {
            setDevices(res.data.devices);
            setSims(res.data.sims);
          },
          () => {
            snackBar.showSnackBar(t('pages.userDetails.fetchUserDevicesError'), 'error');
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * Finds a device by id
   * @param deviceId
   */
  const getDeviceByDeviceId: GetDeviceByIdFn = (deviceId: string) => devices.find((d) => deviceId === d.id);

  React.useEffect(() => {
    fetchUserDevices();
  }, [id]);

  /**
   * Resends a notification when a device has a status error
   * @param deviceId
   */
  const resendNotification = async (deviceId: string) => {
    setIsPerformingAction(true);
    UsersService.api.resendNotification({ simId: deviceId }).then(
      () => {
        fetchUserDevices();
        snackBar.showSnackBar(t('pages.userDetails.resendNotificationSuccess'), 'success');
        setIsPerformingAction(false);
      },
      () => {
        snackBar.showSnackBar(t('pages.userDetails.resendNotificationError'), 'error');
        setIsPerformingAction(false);
      },
    );
  };

  return { devices, sims, isLoading, isPerformingAction, resendNotification, getDeviceByDeviceId, fetchUserDevices };
}

export default useUserDetailsTabs;
