import * as React from 'react';
import { SVGProps, memo } from 'react';

function SecurityIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none" {...props}>
      <path
        fill="#BD0000"
        d="m13.09 21.54-.212-.454-.012.006.224.447Zm-.5 0 .223-.448-.012-.006-.211.453Zm-.1-18.9.261.425.008-.004.008-.006-.277-.416Zm.9 0 .283-.413-.006-.004-.277.416Zm5.5 1.9-.025.499h.025v-.5Zm3.5 0h.5v-.5h-.5v.5ZM9.643 10.585a.5.5 0 0 0-.707.707l.707-.707Zm2.347 3.053-.354.354a.5.5 0 0 0 .707 0l-.353-.354Zm5.553-4.846a.5.5 0 1 0-.707-.707l.707.707Zm4.347-3.854c0 5.932-1.909 9.794-3.971 12.254-2.07 2.469-4.318 3.556-5.04 3.893l.422.906c.778-.363 3.179-1.525 5.384-4.157 2.213-2.639 4.205-6.728 4.205-12.896h-1Zm-9.024 16.153a.454.454 0 0 1-.058.026c-.001 0 .011-.004.032-.004.02 0 .033.004.031.004a.454.454 0 0 1-.058-.026l-.447.895c.123.06.282.127.474.127.191 0 .35-.066.473-.128l-.447-.894Zm-.065-.006c-.72-.336-2.944-1.423-4.989-3.89-2.038-2.46-3.922-6.323-3.922-12.257h-1c0 6.167 1.965 10.255 4.152 12.894 2.18 2.632 4.556 3.796 5.336 4.16l.423-.907ZM3.89 4.94c0-.153.036-.141-.002-.106-.024.022-.012-.01.154-.021.16-.01.376.003.68.03.292.025.651.062 1.052.084 1.637.092 4.02-.045 6.977-1.86l-.523-.853C9.486 3.897 7.318 4.011 5.83 3.928c-.38-.022-.713-.056-1.02-.083a5.737 5.737 0 0 0-.833-.031c-.241.016-.54.071-.771.288-.246.229-.316.54-.316.837h1Zm8.877-1.884c.085-.056.14-.066.173-.066.033 0 .088.01.172.066l.555-.832a1.304 1.304 0 0 0-.727-.234c-.268 0-.512.09-.728.234l.555.832Zm.34-.004c1.669 1.148 3.657 1.883 5.758 1.988l.05-1c-1.9-.094-3.712-.76-5.242-1.812l-.567.824ZM18.89 5.04h3.5v-1h-3.5v1Zm3-.5v.4h1v-.4h-1ZM8.936 11.293l2.7 2.7.707-.707-2.7-2.7-.707.707Zm3.407 2.7 5.2-5.2-.707-.707-5.2 5.2.707.707Z"
      />
    </svg>
  );
}
export default memo(SecurityIcon);
