import { MenuItem, MenuItemProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const MenuItemWrapper = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  fontSize: '1.125rem',
  padding: '1rem',
  fontStyle: 'normal',
  color: theme.palette.monochrome.monoChrome6,
  fontWeight: 400,
  minHeight: '3.125rem !important',
  lineHeight: '1.125rem',
  backgroundColor: `${theme.palette.background.default} !important`,
  borderTop: `1px solid ${theme.palette.monochrome.monoChrome3}`,
  '&:first-of-type': {
    borderTop: 'none',
  },
  '&:hover': {
    backgroundColor: `${theme.palette.monochrome.monoChrome5} !important`,
  },
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.monochrome.monoChrome3}!important`,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.monochrome.monoChrome3,
  },
  '&.Mui-disabled.Mui-selected': {
    backgroundColor: `${theme.palette.background.default}!important`,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.background.default,
  },
}));

export default MenuItemWrapper;
