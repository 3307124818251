import { Box, Collapse, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import { FooterButton, FooterContainer, FooterDrawer, FooterLink2, FooterText, LinksContainer } from './FooterStyle';

function Footer() {
  const [t] = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const theme = useTheme();

  return (
    <FooterContainer>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {/* <FooterLink1 target="_blank" to="/termsandconditions">
          {t('footer.termsAndConditions')}
        </FooterLink1> */}
        <FooterText>{t('footer.vodafoneGroup', { year: new Date().getFullYear() })}</FooterText>
        <FooterText>{t('footer.address1')}</FooterText>
        <FooterText>{t('footer.address2')}</FooterText>
        <Box>
          <Link target="_blank" to={import.meta.env.VITE_PRIVACY_POLICY_URL}>
            <FooterButton color="secondaryButton" label={t('footer.privacyNotice')} />
          </Link>
        </Box>
        <Collapse in={showMore}>
          <FooterDrawer>
            <LinksContainer>
              <FooterLink2
                target="_blank"
                to="https://www.vodafone.com/business/why-vodafone/about-us/global-enterprise"
              >
                {t('footer.vodafoneGlobalEnterprise')}
              </FooterLink2>
              <FooterLink2 target="_blank" to="https://www.vodafone.com/business/carrier-services">
                {t('footer.vodafoneCarrierServices')}
              </FooterLink2>
              <FooterLink2 target="_blank" to="http://www.vodafone.com/business/cloud-and-hosting">
                {t('footer.vodafoneCloudAndHostingServices')}
              </FooterLink2>
            </LinksContainer>
            <LinksContainer>
              <FooterLink2 target="_blank" to="http://www.vodafone.com/business/iot">
                {t('footer.internetOfThings')}
              </FooterLink2>
              <FooterLink2 target="_blank" to={import.meta.env.VITE_PRIVACY_POLICY_URL}>
                {t('footer.privacyPolicyAndCookies')}
              </FooterLink2>
              <FooterLink2
                target="_blank"
                to="https://ciamsso.ciam.vodafone.com/authenticationendpoint/password-policy.jsp?sp=BYOX-Redflex"
              >
                {t('footer.vodafonePasswordPolicy')}
              </FooterLink2>
            </LinksContainer>
          </FooterDrawer>
        </Collapse>
        <FooterButton
          sx={{ marginTop: '1rem' }}
          color="secondaryButton"
          label={
            <>
              <span>{t('footer.helper')}</span>
              <Box
                sx={{
                  paddingLeft: '0.5rem',
                  marginTop: '2px',
                  '& svg': { transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)' },
                }}
              >
                <ChevronDownIcon stroke={theme.palette.monochrome.monoChrome13} />
              </Box>
            </>
          }
          onClick={() => setShowMore((prev) => !prev)}
        />
      </Box>
    </FooterContainer>
  );
}

export default Footer;
