import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import PageTitle from '../../components/Text/PageTitle';
import { reset } from '../../stores/DevicesReportStore';
import DevicesReportTable from './DevicesReportTable';

function DevicesReport() {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(reset());
  }, []);

  return (
    <Box>
      <Breadcrumbs
        activeLinks={[{ label: t('navbar.dashboard'), url: `/dashboard` }]}
        disabledLinks={[t('pages.devicesReport.title')]}
      />
      <PageTitle sx={{ paddingTop: '0' }}>{t('pages.devicesReport.title')}</PageTitle>
      <DevicesReportTable />
    </Box>
  );
}

export default DevicesReport;
