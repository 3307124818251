import * as React from 'react';
import { SVGProps, memo } from 'react';

function HelpIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.485 8.786S9.826 6.96 12 6.97c2.174.01 2.909 2.678 1.762 3.844-.512.524-.95.955-1.262 1.257-.32.317-.5.748-.5 1.198v.966"
      />
      <circle cx={12} cy={12} r={9.5} stroke="#333" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx={12} cy={17} r={0.25} stroke="#333" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx={12} cy={17} r={0.125} stroke="#333" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx={12} cy={17} r={0.75} fill="#333" />
    </svg>
  );
}
export default memo(HelpIcon);
