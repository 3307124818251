import * as React from 'react';
import { SVGProps, memo } from 'react';

function PlusIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M16.5 6.167a.5.5 0 0 0-1 0V15.5H6.165a.5.5 0 1 0 0 1h9.333v9.333a.5.5 0 1 0 1 0V16.5h9.334a.5.5 0 0 0 0-1h-9.334V6.167Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default memo(PlusIcon);
