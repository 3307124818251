import * as React from 'react';
import { SVGProps, memo } from 'react';

function ChevronRightIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={25} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke={props?.stroke ? props.stroke : '#E60000'}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.333 1.167 12.668 12.5 1.334 23.833"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.333 24.5V.5h13.333v24z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default memo(ChevronRightIcon);

export function ChevronRightSmIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={10} height={18} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path stroke="#E60000" strokeLinecap="round" strokeLinejoin="round" d="M.75.5 9.25 9l-8.5 8.5" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 18V0h10v18z" />
        </clipPath>
      </defs>
    </svg>
  );
}
