import { styled, Typography } from '@mui/material';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.375rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  color: theme.palette.text.disabled,
  paddingBottom: '1.5rem',
}));

export default SectionTitle;
