import { configureStore } from '@reduxjs/toolkit';
import UpgradeDeviceReducer from './UpgradeDeviceStore';
import NewConnectionReducer from './NewConnectionStore';
import DevicesReportReducer from './DevicesReportStore';
import AuditTrailReducer from './AuditTrailStore';

export const Store = configureStore({
  reducer: {
    devicesReportReducer: DevicesReportReducer,
    upgradeDeviceReducer: UpgradeDeviceReducer,
    newConnectionReducer: NewConnectionReducer,
    auditTrailReducer: AuditTrailReducer,
  },
});
export type RootState = ReturnType<typeof Store.getState>;
