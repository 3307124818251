import { Box, Drawer, DrawerProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ProgressbarProps {
  progress: number;
}

export const Progressbar = styled(Box)<ProgressbarProps>(({ theme, progress }) => ({
  height: '0.1875rem',
  backgroundColor: theme.palette.primary.main,
  width: `${progress}%`,
  flexShrink: 0,
}));

const DrawerWrapper = styled(Drawer)<DrawerProps>(() => ({
  '& .MuiBackdrop-root': {
    opacity: '0.3 !important',
  },
  '& .MuiPaper-root': {
    boxShadow: '0px 7.5px 15px 0px rgba(0, 0, 0, 0.15)',
    width: '43.125rem',
    paddingBottom: '4rem',
  },
}));

export default DrawerWrapper;
