import { BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StatusContainerProps extends BoxProps {
  colors: { bg: string; font: string };
}
const StatusContainer = styled('div')<StatusContainerProps>(({ colors }) => ({
  padding: '0.25rem 0.75rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2rem',
  background: colors.bg,
  fontSize: '1rem',
  fontStyle: 'normal',
  lineHeight: 'normal',
  color: colors.font,
  minWidth: '5rem',
}));

export default StatusContainer;
