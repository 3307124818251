import { Modal as MaterialModal } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ModalContainer } from './ModalStyle';

interface ModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
}
function Modal({ isOpen = false, onClose, children }: ModalProps) {
  return (
    <div>
      <MaterialModal open={isOpen} onClose={onClose}>
        <ModalContainer>{children}</ModalContainer>
      </MaterialModal>
    </div>
  );
}

export default Modal;
