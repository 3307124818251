import * as React from 'react';
import { SVGProps, memo } from 'react';

function DownloadIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill={props?.fill ? props?.fill : '#333'}
        fillRule="evenodd"
        d="M10 5.104c.173 0 .313.14.313.313v9.204l2.166-2.334a.312.312 0 1 1 .458.426l-2.708 2.916a.314.314 0 0 1-.46-.002l-2.706-2.914a.312.312 0 1 1 .458-.426l2.167 2.334V5.417c0-.173.14-.313.312-.313Zm-7.917 8.333c.173 0 .313.14.313.313v2.5c0 .748.606 1.354 1.354 1.354h12.5c.748 0 1.354-.606 1.354-1.354v-2.5a.312.312 0 1 1 .625 0v2.5a1.98 1.98 0 0 1-1.98 1.98H3.75a1.98 1.98 0 0 1-1.98-1.98v-2.5c0-.173.14-.313.313-.313Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default memo(DownloadIcon);
