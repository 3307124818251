import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Row } from '../../../components/Table/TableModels';
import ClickableLink from '../../../components/Text/ClickableLink';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { Sort } from '../../../models/CommonModels';
import { User, UsersListRequest } from '../../../models/UsersModels';
import UsersService from '../../../services/Users/UsersService';

function useESimManagerTable(activePage: number, pageSize: number, query: string, sort: Sort) {
  const [t] = useTranslation();
  const [totalUsers, setTotalUsers] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const snackBar = useSnackBar();

  React.useEffect(() => {
    setIsLoading(true);
    const requestParams: UsersListRequest = {
      page: activePage - 1,
      pageSize,
      sort: sort.orderBy,
      sortingType: sort.order,
      filter: query,
    };
    UsersService.api.fetchUsers(requestParams).then(
      (response) => {
        setUsers(response.data.users);
        setTotalUsers(response.data.totalElements);
        setIsLoading(false);
      },
      () => {
        snackBar.showSnackBar(t('pages.eSimManager.fetchUserError'), 'error');
        setIsLoading(false);
      },
    );
  }, [activePage, pageSize, query, sort]);

  const headers: Header[] = useMemo(
    () => [
      {
        id: 'lastName',
        label: t('pages.eSimManager.table.headers.lastName'),
        isSortable: true,
      },

      {
        id: 'firstName',
        label: t('pages.eSimManager.table.headers.firstName'),
        isSortable: true,
      },
      {
        id: 'email',
        label: t('pages.eSimManager.table.headers.emailAddress'),
        isSortable: true,
      },
      {
        id: 'phoneNumber',
        label: t('pages.eSimManager.table.headers.phoneNumber'),
      },
      {
        id: 'devices',
        label: t('pages.eSimManager.table.headers.devices'),
      },
      {
        id: 'eSims',
        label: t('pages.eSimManager.table.headers.eSIM'),
      },
      {
        id: 'viewDetails',
        label: '',
      },
      /*  {
        id: 'options',
        label: '',
      }, */
    ],
    [t],
  );

  const rows: Row[] = useMemo(
    () =>
      users?.map((u) => ({
        id: u.id,
        cells: [
          {
            value: u.lastName,
          },
          {
            value: u.firstName,
          },
          {
            value: u.email,
          },
          {
            value: u.phoneNumber,
          },
          {
            value: u.devices,
          },
          {
            value: u.sims,
          },
          {
            value: (
              <ClickableLink to={`/esimManager/userDetails/${u.id}`}>{t('common.table.viewDetails')}</ClickableLink>
            ),
          },
          /* {
            value: <VerticalDotsIcon style={{ marginLeft: 'auto' }} />,
          }, */
        ],
      })),
    [t, users],
  );

  return { headers, rows, totalUsers, isLoading };
}

export default useESimManagerTable;
