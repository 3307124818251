import * as React from 'react';
import { SVGProps, memo } from 'react';

function VerticalDotsIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#333"
        fillRule="evenodd"
        d="M12 5.071A1.286 1.286 0 1 1 12 2.5a1.286 1.286 0 0 1 0 2.571Zm0 7.715a1.286 1.286 0 1 1 0-2.572 1.286 1.286 0 0 1 0 2.572Zm-1.285 6.428a1.286 1.286 0 1 0 2.571 0 1.286 1.286 0 0 0-2.571 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default memo(VerticalDotsIcon);
