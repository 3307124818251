import { Box, FormControl } from '@mui/material';
import * as React from 'react';
import NotificationErrorIcon from '../Icons/NotificationErrorIcon';
import { TextInputProps } from './TextInputModels';
import {
  FeedbackWrapper,
  InputAdornmentWrapper,
  InputBaseWrapper,
  InputLabelWrapper,
  SubTextWrapper,
} from './TextInputStyle';

function TextInput({
  value = '',
  onInputChange,
  placeholder,
  label,
  subText,
  feedback,
  leftIcon,
  formStyle,
  ...rest
}: TextInputProps) {
  return (
    <FormControl sx={{ width: '100%', ...formStyle }}>
      {label ? (
        <InputLabelWrapper shrink disabled={rest.disabled}>
          {label}
        </InputLabelWrapper>
      ) : null}
      {subText ? <SubTextWrapper shrink>{subText}</SubTextWrapper> : null}
      <InputBaseWrapper
        onChange={onInputChange}
        value={value}
        placeholder={placeholder}
        hasLeftIcon={!!leftIcon}
        startAdornment={leftIcon ? <InputAdornmentWrapper position="start">{leftIcon}</InputAdornmentWrapper> : null}
        {...rest}
      />
      {feedback ? (
        <FeedbackWrapper error={rest.error} component="span">
          {rest.error ? (
            <Box sx={{ paddingRight: '0.25rem' }}>
              <NotificationErrorIcon />
            </Box>
          ) : null}
          {feedback}
        </FeedbackWrapper>
      ) : null}
    </FormControl>
  );
}

export default TextInput;
