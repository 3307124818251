import { Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { AnyObject, TestContext } from 'yup';
import Button from '../../../components/Button/Button';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import DateField from '../../../components/DateField/DateField';
import Drawer from '../../../components/Drawer/Drawer';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { DropdownItem } from '../../../components/Dropdown/DropdownModels';
import TextInput from '../../../components/TextInput/TextInput';
import { EmailRegex } from '../../../configs/RegexConfig';
import { AuditTrailFilter } from '../../../models/AuditTrailModels';
import { Status } from '../../../models/CommonModels';
import { setFilter } from '../../../stores/AuditTrailStore';
import { RootState } from '../../../stores/Store';
import useAuditTrailFiltersDrawer from './useAuditTrailFiltersDrawer';

interface AuditTrailFiltersDrawerProps {
  open: boolean;
  onClose: () => void;
}
function AuditTrailFiltersDrawer({ open, onClose }: AuditTrailFiltersDrawerProps) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const filter = useSelector((state: RootState) => state.auditTrailReducer.filter);
  const [dateTriggerClear, setDateTriggerClear] = useState(false);

  const statusOptions: DropdownItem[] = Object.keys(Status)
    .sort()
    .map((st) => ({
      value: st,
      key: st,
      label: t(`common.status.${st}`),
    }));

  /**
   * Handle the close action
   */
  const handleClose = () => {
    onClose();
  };

  /**
   * Apply filters
   */
  const applyFilters = (vals: AuditTrailFilter) => {
    dispatch(setFilter(vals));
    handleClose();
  };

  /**
   * Verifies if the date is in a valid format and after the current year +
   * @param val
   */
  const validateDateFormat = (val: string | undefined) => {
    if (val === null) return false;
    if (val) {
      const dateObj = DateTime.fromISO(val);
      if (!dateObj.isValid) {
        return false;
      }
    }
    return true;
  };

  /**
   * Validates the date range
   * @param date
   * @param ctx
   * @param otherDate
   */
  const validateDateRange = (
    date: string | undefined,
    ctx: TestContext<AnyObject>,
    otherDate: 'startDate' | 'endDate',
  ) => {
    if (date) {
      const dateObj = DateTime.fromISO(date);
      const todayDateObj = DateTime.now();
      if (otherDate === 'endDate' && dateObj > todayDateObj) {
        return ctx.createError({ message: t('common.inputValidations.startDateAfterToday') });
      }
      if (ctx.parent[otherDate]) {
        const otherDateObj = DateTime.fromISO(ctx.parent[otherDate]);
        if (otherDate === 'startDate' && dateObj < otherDateObj) {
          return ctx.createError({ message: t('common.inputValidations.endDateBeforeStartDate') });
        }
      }
    }
    return true;
  };

  const formik = useFormik({
    initialValues: filter,
    enableReinitialize: false,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: yup.object({
      endUserEmail: yup.string().matches(EmailRegex, t('common.inputValidations.email')),
      startDate: yup
        .string()
        .test('date', t('common.inputValidations.date'), validateDateFormat)
        .test('dateRange', t('common.inputValidations.dateRange'), (value, context) =>
          validateDateRange(value, context, 'endDate'),
        )
        .nullable(),
      endDate: yup
        .string()
        .test('date', t('common.inputValidations.date'), validateDateFormat)
        .test('dateRange', t('common.inputValidations.dateRange'), (value, context) =>
          validateDateRange(value, context, 'startDate'),
        )
        .nullable(),
    }),
    onSubmit: () => {
      applyFilters(formik.values);
    },
  });

  /**
   * Resets the form to the initial vals
   */
  const resetForm = () => {
    formik.resetForm({
      values: {
        eventType: filter.eventType,
      },
    });
    setDateTriggerClear((trigger) => !trigger);
  };

  const {
    companyOptions,
    countryOptions,
    banOptions,
    eidSourceOptions,
    deviceTypeOptions,
    uemTypesOptions,
    isAllowedToSelectCompany,
    isAllowedToSelectCountries,
  } = useAuditTrailFiltersDrawer(formik.values.companyCode);

  return (
    <Drawer
      disablePortal
      open={open}
      onClose={handleClose}
      onBackButton={handleClose}
      anchor="right"
      title={t('common.filterResults')}
      progress={0}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', paddingTop: '2rem' }}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ paddingBottom: '2rem' }}>
            <Box sx={{ paddingBottom: '1.5rem' }}>
              <Grid container spacing="1.5rem">
                <Grid item xs={6}>
                  <DateField
                    onChange={(newDate) => {
                      formik.setFieldValue('startDate', newDate?.toISO() as string);
                    }}
                    triggerClear={dateTriggerClear}
                    error={Boolean(formik.errors.startDate)}
                    label={t('pages.auditTrail.table.headers.startDate')}
                    feedback={formik.errors.startDate ? formik.errors.startDate : ''}
                    value={formik.values.startDate ? DateTime.fromISO(formik.values.startDate) : null}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateField
                    onChange={(newDate) => {
                      const auxDate = DateTime.fromISO(newDate?.toISODate() as string).endOf('day');
                      formik.setFieldValue('endDate', auxDate?.toISO() as string);
                    }}
                    triggerClear={dateTriggerClear}
                    error={Boolean(formik.errors.endDate)}
                    label={t('pages.auditTrail.table.headers.endDate')}
                    feedback={formik.errors.endDate ? formik.errors.endDate : ''}
                    value={formik.values.endDate ? DateTime.fromISO(formik.values.endDate) : null}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* Status */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="status"
              name="status"
              label={t('pages.auditTrail.table.headers.status')}
              value={formik.values.status}
              onChange={(e) => formik.handleChange(e)}
              items={statusOptions}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.dropdownPlaceholder') + t('pages.auditTrail.table.headers.status').toLowerCase()
              }
            />
            {/* Admin first name */}
            <TextInput
              autoComplete="off"
              key="adminFirstName"
              id="adminFirstName"
              name="adminFirstName"
              type="text"
              label={t(`pages.auditTrail.table.headers.adminFirstName`)}
              value={formik.values.adminFirstName}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.adminFirstName && Boolean(formik.errors.adminFirstName)}
              feedback={
                formik.touched.adminFirstName && Boolean(formik.errors.adminFirstName)
                  ? formik.errors.adminFirstName
                  : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.auditTrail.table.headers.adminFirstName`).toLowerCase()
              }
            />
            {/* Admin last name */}
            <TextInput
              autoComplete="off"
              key="adminLastName"
              id="adminLastName"
              name="adminLastName"
              type="text"
              label={t(`pages.auditTrail.table.headers.adminLastName`)}
              value={formik.values.adminLastName}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.adminLastName && Boolean(formik.errors.adminLastName)}
              feedback={
                formik.touched.adminLastName && Boolean(formik.errors.adminLastName) ? formik.errors.adminLastName : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.auditTrail.table.headers.adminLastName`).toLowerCase()
              }
            />
            {/* Company */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="companyCode"
              name="companyCode"
              label={t('pages.auditTrail.table.headers.companyCode')}
              value={formik.values.companyCode}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('ban', undefined);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.companyCode && Boolean(formik.errors.companyCode)}
              feedback={
                formik.touched.companyCode && Boolean(formik.errors.companyCode) ? formik.errors.companyCode : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              items={companyOptions}
              disabled={!isAllowedToSelectCompany}
              placeholder={
                isAllowedToSelectCompany
                  ? t('common.placeholders.dropdownPlaceholder') +
                    t('pages.auditTrail.table.headers.companyCode').toLowerCase()
                  : `${companyOptions[0]?.label}`
              }
            />
            {/* Country */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="country"
              name="country"
              label={t('pages.auditTrail.table.headers.country')}
              value={formik.values.country}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              feedback={formik.touched.country && Boolean(formik.errors.country) ? formik.errors.country : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={countryOptions}
              disabled={!isAllowedToSelectCountries}
              placeholder={
                isAllowedToSelectCountries
                  ? t('common.placeholders.dropdownPlaceholder') +
                    t('pages.auditTrail.table.headers.country').toLowerCase()
                  : `${countryOptions[0]?.label}`
              }
            />

            {/* Ban */}
            {banOptions?.length ? (
              <Dropdown
                isPlaceholderDisabled={false}
                id="ban"
                name="ban"
                label={t('pages.auditTrail.table.headers.ban')}
                value={formik.values.ban}
                onChange={(e) => formik.handleChange(e)}
                onBlur={formik.handleBlur}
                error={formik.touched.ban && Boolean(formik.errors.ban)}
                feedback={formik.touched.ban && Boolean(formik.errors.ban) ? formik.errors.ban : ''}
                formStyle={{ paddingBottom: '1.5rem' }}
                items={banOptions}
                placeholder={
                  t('common.placeholders.dropdownPlaceholder') + t('pages.auditTrail.table.headers.ban').toLowerCase()
                }
              />
            ) : null}
            {/* Eid source */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="eidSource"
              name="eidSource"
              label={t('pages.auditTrail.table.headers.eidSource')}
              value={formik.values.eidSource}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              error={formik.touched.eidSource && Boolean(formik.errors.eidSource)}
              feedback={formik.touched.eidSource && Boolean(formik.errors.eidSource) ? formik.errors.eidSource : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={eidSourceOptions}
              placeholder={
                t('common.placeholders.dropdownPlaceholder') +
                t('pages.auditTrail.table.headers.eidSource').toLowerCase()
              }
            />
            {/* End user first name */}
            <TextInput
              autoComplete="off"
              key="endUserFirstName"
              id="endUserFirstName"
              name="endUserFirstName"
              type="text"
              label={t(`pages.auditTrail.table.headers.endUserFirstName`)}
              value={formik.values.endUserFirstName}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.endUserFirstName && Boolean(formik.errors.endUserFirstName)}
              feedback={
                formik.touched.endUserFirstName && Boolean(formik.errors.endUserFirstName)
                  ? formik.errors.endUserFirstName
                  : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.auditTrail.table.headers.endUserFirstName`).toLowerCase()
              }
            />
            {/* End user last name */}
            <TextInput
              autoComplete="off"
              key="endUserLastName"
              id="endUserLastName"
              name="endUserLastName"
              type="text"
              label={t(`pages.auditTrail.table.headers.endUserLastName`)}
              value={formik.values.endUserLastName}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.endUserLastName && Boolean(formik.errors.endUserLastName)}
              feedback={
                formik.touched.endUserLastName && Boolean(formik.errors.endUserLastName)
                  ? formik.errors.endUserLastName
                  : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.auditTrail.table.headers.endUserLastName`).toLowerCase()
              }
            />
            {/* End user email */}
            <TextInput
              autoComplete="off"
              key="endUserEmail"
              id="endUserEmail"
              name="endUserEmail"
              type="text"
              label={t(`pages.auditTrail.table.headers.endUserEmail`)}
              value={formik.values.endUserEmail}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.endUserEmail && Boolean(formik.errors.endUserEmail)}
              feedback={
                formik.touched.endUserEmail && Boolean(formik.errors.endUserEmail) ? formik.errors.endUserEmail : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.auditTrail.table.headers.endUserEmail`).toLowerCase()
              }
            />
            {/* Device type */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="deviceType"
              name="deviceType"
              label={t('pages.auditTrail.table.headers.deviceType')}
              value={formik.values.deviceType}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              error={formik.touched.deviceType && Boolean(formik.errors.deviceType)}
              feedback={formik.touched.deviceType && Boolean(formik.errors.deviceType) ? formik.errors.deviceType : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={deviceTypeOptions}
              placeholder={
                t('common.placeholders.dropdownPlaceholder') +
                t('pages.auditTrail.table.headers.deviceType').toLowerCase()
              }
            />
            {/* Device manufacturer */}
            <TextInput
              autoComplete="off"
              key="deviceManufacturer"
              id="deviceManufacturer"
              name="deviceManufacturer"
              type="text"
              label={t(`pages.auditTrail.table.headers.deviceManufacturer`)}
              value={formik.values.deviceManufacturer}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.deviceManufacturer && Boolean(formik.errors.deviceManufacturer)}
              feedback={
                formik.touched.deviceManufacturer && Boolean(formik.errors.deviceManufacturer)
                  ? formik.errors.deviceManufacturer
                  : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.auditTrail.table.headers.deviceManufacturer`).toLowerCase()
              }
            />
            {/* Device model */}
            <TextInput
              autoComplete="off"
              key="deviceModel"
              id="deviceModel"
              name="deviceModel"
              type="text"
              label={t(`pages.auditTrail.table.headers.deviceModel`)}
              value={formik.values.deviceModel}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.deviceModel && Boolean(formik.errors.deviceModel)}
              feedback={
                formik.touched.deviceModel && Boolean(formik.errors.deviceModel) ? formik.errors.deviceModel : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.auditTrail.table.headers.deviceModel`).toLowerCase()
              }
            />
            {/* Device serial number */}
            <TextInput
              autoComplete="off"
              key="serialNumber"
              id="serialNumber"
              name="serialNumber"
              type="text"
              label={t(`pages.auditTrail.table.headers.serialNumber`)}
              value={formik.values.serialNumber}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
              feedback={
                formik.touched.serialNumber && Boolean(formik.errors.serialNumber) ? formik.errors.serialNumber : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.auditTrail.table.headers.serialNumber`).toLowerCase()
              }
            />
            {/* eid */}
            <TextInput
              autoComplete="off"
              key="eid"
              id="eid"
              name="eid"
              type="text"
              label={t(`pages.auditTrail.table.headers.eid`)}
              value={formik.values.eid}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.eid && Boolean(formik.errors.eid)}
              feedback={formik.touched.eid && Boolean(formik.errors.eid) ? formik.errors.eid : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') + t(`pages.auditTrail.table.headers.eid`).toLowerCase()
              }
            />
            {/* UEM device ID */}
            <TextInput
              autoComplete="off"
              key="uemDeviceId"
              id="uemDeviceId"
              name="uemDeviceId"
              type="text"
              label={t(`pages.auditTrail.table.headers.uemDeviceId`)}
              value={formik.values.uemDeviceId}
              onInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.uemDeviceId && Boolean(formik.errors.uemDeviceId)}
              feedback={
                formik.touched.uemDeviceId && Boolean(formik.errors.uemDeviceId) ? formik.errors.uemDeviceId : ''
              }
              formStyle={{ paddingBottom: '1.5rem' }}
              placeholder={
                t('common.placeholders.inputPlaceholder') +
                t(`pages.auditTrail.table.headers.uemDeviceId`).toLowerCase()
              }
            />
            {/* UEM system */}
            <Dropdown
              isPlaceholderDisabled={false}
              id="uemSystem"
              name="uemSystem"
              label={t('pages.auditTrail.table.headers.uemSystem')}
              value={formik.values.uemSystem}
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              error={formik.touched.uemSystem && Boolean(formik.errors.uemSystem)}
              feedback={formik.touched.uemSystem && Boolean(formik.errors.uemSystem) ? formik.errors.uemSystem : ''}
              formStyle={{ paddingBottom: '1.5rem' }}
              items={uemTypesOptions}
              placeholder={t('common.placeholders.dropdownPlaceholder') + t('pages.auditTrail.table.headers.uemSystem')}
            />
          </Box>
          <Box sx={{ marginTop: 'auto', marginBottom: '2rem' }}>
            <ButtonGroup>
              <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
              <Button label={t('common.resetFilters')} color="secondaryButton" onClick={resetForm} />
              <Button
                label={t('common.applyFilters')}
                color="primaryButton"
                disabled={!formik.isValid}
                onClick={formik.submitForm}
              />
            </ButtonGroup>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
}

export default AuditTrailFiltersDrawer;
