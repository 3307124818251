import { Box, DrawerProps as DProps, Typography, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import ArrowLeft from '../Icons/ArrowLeft';
import DrawerWrapper, { Progressbar } from './DrawerStyle';

interface DrawerProps extends DProps, PropsWithChildren {
  onClose: () => void;
  onBackButton: () => void;
  title: string;
  progress?: number;
}
function Drawer({ children, onBackButton, onClose, title, progress, ...rest }: DrawerProps) {
  const theme = useTheme();
  return (
    <DrawerWrapper {...rest} onClose={onClose}>
      {progress && progress >= 0 ? <Progressbar progress={progress} /> : null}
      <Box sx={{ padding: '4rem 4rem 0rem 4rem', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ArrowLeft stroke={theme.palette.primary.main} style={{ cursor: 'pointer' }} onClick={onBackButton} />
          <Typography
            sx={{
              marginLeft: '1rem',
              color: theme.palette.text.disabled,
              fontSize: '2.5rem',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
            }}
          >
            {title}
          </Typography>
        </Box>
        {children}
      </Box>
    </DrawerWrapper>
  );
}

export default Drawer;
