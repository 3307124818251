import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Drawer from '../../../../../components/Drawer/Drawer';
import { reset } from '../../../../../stores/UpgradeDeviceStore';
import UpgradeDeviceDevicesStep from './UpgradeDeviceDevicesStep';
import UpgradeDeviceNotificationsStep from './UpgradeDeviceNotificationsStep';
import UpgradeDeviceSelectTypeStep from './UpgradeDeviceSelectTypeStep';
import UpgradeDeviceSummaryStep from './UpgradeDeviceSummaryStep';
import useUpgradeDevice from './useUpgradeDevice';

interface UpgradeDeviceDrawerProps {
  open: boolean;
  onClose: () => void;
  onUpgradeSuccess: () => void;
}
function UpgradeDeviceDrawer({ open, onClose, onUpgradeSuccess }: UpgradeDeviceDrawerProps) {
  const dispatch = useDispatch();

  /**
   * Handle the close action
   */
  const handleClose = () => {
    dispatch(reset());
    onClose();
  };

  const [t] = useTranslation();
  const { upgradeDevice, availableNotifications, availableDevices, isLoading } = useUpgradeDevice(
    handleClose,
    onUpgradeSuccess,
  );
  const [progress, setProgress] = useState(25);
  const [step, setStep] = useState(1);
  const NUMBER_OF_STEPS = 5;

  React.useEffect(() => {
    setProgress((step / NUMBER_OF_STEPS) * 100);
  }, [step]);

  /**
   * Next step increment
   */
  const nextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  /**
   * Handle the back button of the drawer to close or to go back a step
   */
  const handleBackButton = () => {
    if (step === 1) {
      handleClose();
    } else {
      setStep((prevState) => prevState - 1);
    }
  };

  /**
   * Render each step of the upgrade device journey
   */
  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <UpgradeDeviceNotificationsStep
            availableNotifications={availableNotifications}
            onClose={handleClose}
            nextStep={nextStep}
          />
        );
      case 2:
        return <UpgradeDeviceSelectTypeStep nextStep={nextStep} onClose={handleClose} />;
      case 3:
        return (
          <UpgradeDeviceDevicesStep
            onClose={handleClose}
            nextStep={nextStep}
            availableDevices={availableDevices}
            isLoading={isLoading}
          />
        );
      case 4:
        return <UpgradeDeviceSummaryStep onClose={handleClose} nextStep={upgradeDevice} isLoading={isLoading} />;
      default:
        return null;
    }
  };

  return (
    <Drawer
      disablePortal
      open={open}
      onClose={handleClose}
      onBackButton={handleBackButton}
      anchor="right"
      title={t('pages.userDetails.upgradeDeviceDrawer.upgradeDevice')}
      progress={progress}
    >
      <Box sx={{ paddingTop: '2rem', height: '100%' }}>{renderSteps()}</Box>
    </Drawer>
  );
}

export default UpgradeDeviceDrawer;
