import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClickableLink from '../../components/Text/ClickableLink';
import PageTitle from '../../components/Text/PageTitle';

function PrivacyPolicy() {
  const [t] = useTranslation();
  return (
    <Box>
      <PageTitle>{t('translation:pages.privacyPolicy.title')}</PageTitle>
      <Grid container sx={{ paddingTop: '1.5rem', paddingBottom: '2.5rem' }}>
        <Grid item xl={5} md={12} xs={12}>
          <Typography>
            {t('translation:pages.privacyPolicy.message')}
            <ClickableLink to="https://www.vodafone.com/business/privacy-policy">
              https://www.vodafone.com/business/privacy-policy
            </ClickableLink>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PrivacyPolicy;
