import { Box, Typography } from '@mui/material';
import { memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { AuthContext } from '../../contexts/AuthContext/AuthContext';

function Auth() {
  const [t] = useTranslation();
  const { authenticate } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    authenticate().then(() => {
      navigate('/');
    });
  }, []);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '3rem' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <LoadingSpinner style={{ minHeight: '148px', maxWidth: '148px' }} />
        <Typography sx={{ fontSize: '1.75rem', lineHeight: '2rem', fontFamily: 'Vodafone Lt' }}>
          {t('common.loading')}
        </Typography>
      </Box>
    </Box>
  );
}

export default memo(Auth);
