import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import {
  AuditTrailColumns,
  AuditTrailEventTypesResponse,
  AuditTrailFilter,
  AuditTrailResponse,
} from '../../models/AuditTrailModels';

const AuditTrailService = {
  api: {
    fetchAuditTrailEventTypes: (): AxiosPromise<AuditTrailEventTypesResponse> => Api.get(`/audit/event-types`),
    fetchAuditTrail: (filter: AuditTrailFilter): AxiosPromise<AuditTrailResponse> => Api.post(`/audit/list`, filter),
    fetchTableColumns: (): AxiosPromise<AuditTrailColumns> => Api.get(`/audit/columns`, {}),
    exportCsv: (filter: AuditTrailFilter): AxiosPromise<string> =>
      Api.post(`/audit/export`, filter, {
        responseType: 'blob',
      }),
  },
};

export default AuditTrailService;
